import React, { useState, useRef, useEffect } from 'react';
import Header from './Header';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import { Container,Row,Col } from 'react-bootstrap';
import Button from "../../../components/Button";
import { useNavigate } from 'react-router-dom';
import './style.css'
import linkedin from '../../../assets/images/linkedin.png'
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const Welcome = () =>{
    const navigate = useNavigate()

    const handleLinkedin = async (option) => {
      sendEventToCustomerIO(eventNames.onboarding, {
        eventType: eventTypes.buttonClicked,
        location: "Onboarding Completed",
        buttonName: 'Connect with us on LinkedIn'
      })

      // window.location.href = 'https://www.linkedin.com/company/creditcurve/posts/?feedView=all'

      window.open('https://www.linkedin.com/company/creditcurve/posts/?feedView=all', '_blank');

    };

    useEffect(() => {
      sendEventToCustomerIO(eventNames.onboarding, {
        eventType: eventTypes.pageLoaded,
        location: "Onboarding Completed",
      })
    }, []);



    return(<>

    <Header title='Completed' description='Congratulations on taking the first step towards maximizing your business potential! Our team is eager to connect with you and guide you through the next steps'/>
   

    <Container>
      <div style={{backgroundColor:'white',height:'auto',width:'80%',marginLeft:'auto',marginRight:'auto',border :'1px solid #EAECF0',borderRadius:'12px',marginBottom:'15px',marginTop:'15px',padding:'20px'}} >
       {/* Body */}

       <div className='thankyou'></div>

          {/* Body */}
      </div>
      </Container>


        
     {/* Footer */}

     <Container>
          <div style={{backgroundColor:'white',borderRadius:'12px',border :'1px solid #EAECF0', padding :'10px',width:'80%',marginLeft:'auto',marginRight:'auto',paddingTop:'15px' }}>
          
          <Row>
            <Col>
            <Paragraph style={{fontWeight:'500',margin: '10px'}}>© CreditCurve 2024</Paragraph>
            </Col>
            <Col>
            <Button variant='default' showIconLeft iconLeft={linkedin} label=' Connect with us on LinkedIn' onClick={handleLinkedin}  style={{float:'right',}} />
            </Col>
         
         
          </Row>


          
        
          </div>
    </Container>

     {/* Footer */}

   
    </>)
}

export default Welcome;