import React from 'react';
import PropTypes from 'prop-types';
import './Paragraph.scss';
const Paragraph = ({style={}, children, className, href="#",onClick }) => {
  return (
    <p style={style} onClick={onClick} className='anchor' >
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Paragraph;
