import axios from 'axios'; // Import Axios
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import '../../../assets/css/App.scss';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import keyIcon from '../../../assets/icons/key.svg';
import backgroundImage from '../../../assets/images/bg2.png';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Label from '../../../components/Typorgraphy/Label';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Title from '../../../components/Typorgraphy/Title';
const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make API request to reset password
            const response = await axios.post('http://localhost/auth/user/forgot-password', {
                email: email
            });
            // Handle response (redirect or show message)
            console.log('Password reset instructions sent:', response.data);
            // Navigate to the verify email page or handle success
            navigate('/verify-email', { state: { email: email } });
        } catch (error) {
            // Handle error (show error message or retry logic)
            console.error('Error sending password reset instructions:', error);
        }
    };

    return (
        <div style={styles.background}>
            <div style={styles.container}>
                <div style={styles.centered}>
                    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', background: 'transparent', marginTop: '150px' }}>
                        <form onSubmit={handleSubmit} style={{ width: '350px' }}>
                            <div className="text-center mb-3">
                                <img src={keyIcon} alt="Logo" className='icon-border' />
                            </div>
                            <Title style={{ textAlign: 'center' }}>Forgot Password ?</Title>
                            <Paragraph style={{ textAlign: 'center' }}>No Worries, we'll send you reset instructions.</Paragraph>
                            <div style={{ marginBottom: '20px', marginTop: '35px' }}>
                                <Label>Email</Label>
                                <TextField isRequired name="email" type='email' placeholder="Enter Your Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <Button type='submit' style={{ width: '100%', marginBottom: '30px' }} label={"Reset password"} ></Button>
                            <div className="d-flex align-items-center" style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                                <Label style={{ cursor: 'pointer', fontWeight: '600' }} onClick={() => navigate('/login')}>
                                    <img src={arrowLeft} alt="arrow" style={{ marginRight: '5px' }} />
                                    Back to log in
                                </Label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center top',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    centered: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
    },
};

export default ForgotPasswordPage;
