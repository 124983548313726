import axios from 'axios';
import Cookies from 'js-cookie';


const path = '/api';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },

});

export const getTeam = async () => {
    try {
        const response = await authApi.get(URL + '/teams', {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getTeamDetails = async () => {
    try {
        const response = await authApi.get(URL + '/teams/details', {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createTeam = async (teamData) => {
    try {
        const response = await authApi.post(URL + '/teams', teamData);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createRole = async (roleData) => {
    try {
        const response = await authApi.post(URL + '/roles', roleData);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const addParticipant = async (participantData) => {
    try {
        const response = await authApi.post(URL + '/teams/participant', participantData);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const deleteParticipant = async (participantId) => {
    try {
        const response = await authApi.delete(URL + `/teams/participant/${participantId}`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getRoles = async () => {
    try {
        const response = await authApi.get(URL + '/roles', {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const deleteRole = async (roleId) => {
    try {
        const response = await authApi.delete(URL + `/roles/${roleId}`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const updateRoleById = async (roleId, roleData) => {
    try {
        const response = await authApi.patch(URL + `/roles/${roleId}`, roleData);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const updateParticipantById = async (participantId, participantData) => {
    try {
        const response = await authApi.patch(URL + `/teams/participant/${participantId}`, participantData);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

// export const register = async (userData) => {
//   try {
//     const response = await authApi.post(URL+'/register', userData);
//     return response.data;
//   } catch (error) {
//     throw error?.response?.data || error.message;
//   }
// };

export default authApi;