import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../assets/css/App.scss';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import emailIcon from '../../../assets/icons/email.svg';
import backgroundImage from '../../../assets/images/bg2.png';
import AnchorText from '../../../components/Typorgraphy/AnchorText';
import Label from '../../../components/Typorgraphy/Label';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Title from '../../../components/Typorgraphy/Title';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [resending, setResending] = useState(false); // State to track if resend is in progress
  const [resendError, setResendError] = useState(null); // State to track resend error

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    } else {
      setEmail('');
    }
  }, [location.state]);

  const handleResendClick = () => {
    if (resending) return; 
    const resendEmail = location.state && location.state.email ? location.state.email : '';
    setResending(true);
    setResendError(null); // Reset any previous error
    fetch('http://localhost/auth/user/resend-otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: resendEmail
      }),
    })
      .then(response => {
        if (response.ok) {
          console.log('Email resend successful');
        } else {
          console.error('Failed to resend email');
          setResendError('Failed to resend email. Please try again.'); // Update error state
        }
      })
      .catch(error => {
        console.error('Error sending API request:', error);
        setResendError('Network error. Please check your connection.'); // Update error state
      })
      .finally(() => {
        setResending(false);
      });
  };

  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.centered}>
          <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', background: 'transparent', marginTop: '150px' }}>
            <form style={{ width: '350px' }}>
              <div className="text-center mb-3">
                <img src={emailIcon} alt="Logo" className='icon-border' />
              </div>
              <Title style={{ textAlign: 'center' }}>Check Your Email</Title>
              <Paragraph style={{ textAlign: 'center' }}>We sent a password reset link to <Label style={{ textAlign: 'center', marginTop: '10px' }}>{email}</Label></Paragraph>

              <div className="d-flex align-items-center justify-content-center">
                <Paragraph style={{ marginRight: '7px' }}>Didn't receive the email?</Paragraph>
                <AnchorText onClick={handleResendClick} style={{ cursor: 'pointer' }} disabled={resending}>
                  {resending ? 'Resending...' : 'Click to resend'}
                </AnchorText>
                {resendError && <span style={{ color: 'red', marginLeft: '10px' }}>{resendError}</span>}
              </div>

              <div className="d-flex align-items-center justify-content-center" style={{ marginTop: '20px' }}>
                <Label style={{ cursor: 'pointer', fontWeight: '600' }} onClick={() => navigate('/login')}>
                  <img src={arrowLeft} alt="arrow" style={{ marginRight: '5px' }} />
                  Back to login
                </Label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  centered: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export default ForgotPasswordPage;
