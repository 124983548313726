import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RegisterForm from './RegisterForm';
import sliderImage from '../../../assets/images/slider1.webp';
const RegisterPage = () => {
  return (
    <Container>
      <Row className='align-items-center'>
      <Col  lg={6} className="d-flex justify-content-center align-items-center">
     
          <RegisterForm/>
      </Col>
      
      
        <Col lg={6} className="d-flex justify-content-center align-items-center  d-none d-lg-block" style={{borderRadius:'20px'}}>
         <div className="image-container" >
         <img src={sliderImage} alt="Login" style={{maxWidth:'90%',borderRadius:'20px'}} />

         <div className="image-overlay">
         <h3>Eliminate the guesswork out of due diligence, while driving more deal flow.</h3>
          </div>
        </div>
        </Col>
      </Row>
    
    </Container>
  );
}

export default RegisterPage;