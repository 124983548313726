import axios from 'axios';
import Cookies from 'js-cookie';


const path = '/api';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },

});

export const getLithicToken = async () => {
    try {
        const response = await authApi.get(URL + '/integrations/getLithicToken', {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createLithicIntegration = async (token) => {
    try {
        const response = await authApi.post(URL + '/integrations/lithic', { lithicAuthToken: token });
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

// export const register = async (userData) => {
//   try {
//     const response = await authApi.post(URL+'/register', userData);
//     return response.data;
//   } catch (error) {
//     throw error?.response?.data || error.message;
//   }
// };

export default authApi;