
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Title from '../../../components/Typorgraphy/Title';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Button from '../../../components/Button';
import { Card } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import './style.css'
import buyerIcon from '../../../assets/icons/buyerIcon.svg';
import sellerIcon from '../../../assets/icons/sellerIcon.svg';
import LoaderSpinner from '../../../components/Spinner';
import sliderImage from '../../../assets/images/slider1.webp';
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const AccountCard = ({ type, selected, onSelect }) => {
    const isSelected = selected === type;

    async function accountTypeSelect() {
      sendEventToCustomerIO(eventNames.onboarding, {
        eventType: eventTypes.buttonClicked,
        location: "Onboarding Select Account Type",
        buttonName: type
      })
      onSelect(type)
    }
  
    return (
      <Card className={`account-card ${isSelected ? 'selected' : ''}`} onClick={accountTypeSelect}>
        <Card.Body>
          <div className="card-header">
            <div className="icon-container">
                <img src={type === 'buyer' ? buyerIcon : sellerIcon} alt="icon" />

            </div>
            <h5 className="card-title">{type === 'buyer' ? 'Private Credit Fund' : type === 'seller' ? 'FinTech or Loan Originator' : ''}</h5>
            <input type="checkbox" checked={isSelected} readOnly className="checkmark" />
          </div>
          <Card.Text style={{marginTop:'10px',fontWeight:'500'}}>
            {type === 'buyer'
              ? 'If you are looking to deploy debt capital to the market.'
              : 'You are looking to sell a portfolio and raise debt.'}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  };


const AccountType = ({selectAccountType}) => {
    const [accountType, setAccountType] = useState('');

  return (
    <>

    <Container>
      <Row className='align-items-center'>
      <Col  lg={6} className="d-flex justify-content-center align-items-center">
     
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

      <div  style={{ width: '350px' }}>
       
       <Title style={{textAlign:'left'}}>Select Account Type</Title>
       <Paragraph style={{textAlign:'left'}}></Paragraph>


       <div>

        <div className="account-selection">
      <AccountCard type="buyer" selected={accountType} onSelect={setAccountType} />
      <AccountCard type="seller" selected={accountType} onSelect={setAccountType} />
    </div>


       </div>
       
       <Button type='submit' onClick={()=> selectAccountType(accountType)} style={{width:'100%',marginBottom:'20px'}} label={"Continue"} ></Button>
      
      
     </div>
        </div>
      </Col>
      
      
        <Col lg={6} className="d-flex justify-content-center align-items-center  d-none d-lg-block" style={{borderRadius:'20px'}}>
         <div className="image-container" >
         <img src={sliderImage} alt="Login" style={{maxWidth:'90%',borderRadius:'20px'}} />

         <div className="image-overlay">
            <h3>Eliminate the guesswork out of due diligence, while driving more deal flow.</h3>
           
          </div>
        </div>
        </Col>
      </Row>
    
    </Container>
    </>
  );
}

export default AccountType;