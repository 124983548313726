import React, { useEffect, useState, useRef } from "react";
import Title from '../../../components//Typorgraphy/Title'
import TextField from "../../../components/TextField";
import Label from "../../../components/Typorgraphy/Label";
import Button from "../../../components/Button";
import { Col, Row,Tab,Tabs,Nav,Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Papa from 'papaparse'; // Import PapaParse library
import Swal from 'sweetalert2'
import { toast } from "sonner";
import { getCSVMatch, deleteCSVMatchById,getModifiers,createModifier, getCSVMatchById } from '../../../api/csv';
import DataTable from "../../../components/DataTable";
import downloadIcon from '../../../assets/icons/delete.svg';
import progressBar from '../../../assets/icons/progress.svg'
import FileIcon from '../../../assets/icons/FileIcon.svg'
import deleteIcon from '../../../assets/icons/delete.svg'
import editIcon from '../../../assets/icons/edit.svg'

import * as XLSX from 'xlsx';
import LoadingProgressBar from "../../../components/Spinner/LoadingProgressBar";

const FormatPreferences = () => {
    const [show, setShow] = useState(false);
    const [loading,setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [selectedField, setSelectedField] = useState('');



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedFormat,setSelectedFormat] = useState(null)
    const [formatName, setFormatName] = useState('');
    const [modifierFormData,setModifierFormData] = useState({
        modifierName:'',
        format:'',
        typeSelected:'',
        multiSelectValues:[''],
        positiveValue:'True',
        negativeValue:'False'
    })
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const fileInputRef = useRef(null);


    const handleFormChange  = (e) => {
        const { name, value } = e.target;
        setModifierFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));

        console.log("Updated States Modifier", {modifierFormData})
    };

    const handleAddValue = () => {
        setModifierFormData((prevState) => ({
            ...prevState,
            multiSelectValues: [...prevState.multiSelectValues, '']
        }));
    };

    const handleValueChange = (index, value) => {
        const newValues = [...modifierFormData.multiSelectValues];
        newValues[index] = value;
        setModifierFormData((prevState) => ({
            ...prevState,
            multiSelectValues: newValues
        }));
    };

    const handleDeleteValue = (index) => {
        const newValues = modifierFormData.multiSelectValues.filter((_, i) => i !== index);
        setModifierFormData((prevState) => ({
            ...prevState,
            multiSelectValues: newValues
        }));
    };

    const [formats, setFormats] = useState([])
    const [modifiers, setModifiers] = useState([])

    let totalSize = 0, fileNames ="";

    let fileArray = []

    if (file){
        for (let f of file){
            totalSize+= f.size;
             
            if (fileNames === "") {
                fileNames+= f.name
            } else {
                fileNames = `${fileNames}, ${f.name}`
            }
        }
        fileArray =  Object.values(file);
    }

    const formatFileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleFileChange = async (e) => {
        const selectedFiles = Array.from(e.target.files);
        const csvFiles = [];
        
        for (const file of selectedFiles) {
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
                // Read the Excel file
                const data = await file.arrayBuffer();
                const workbook = XLSX.read(data, { type: 'array' });
                
                workbook.SheetNames.forEach(sheetName => {
                    const worksheet = workbook.Sheets[sheetName];
                    const csv = XLSX.utils.sheet_to_csv(worksheet);
                    const blob = new Blob([csv], { type: 'text/csv' });
                    const csvFile = new File([blob], `${file.name.replace(/\.[^/.]+$/, "")}-${sheetName}.csv`);
                    csvFiles.push(csvFile);
                });
            } else if (file.type === 'text/csv') {
                csvFiles.push(file);
            }
        }
    
        // Set the state with the combined CSV files
        setFile(csvFiles);
    };

    const dataForDataHealth = formats?.map((item, index) => {
        return {
            name: item.formatName,
            action :<>
                <img alt='edit' src={editIcon} onClick={(e)=> updateFormat(e,item.id)} className='downloadButton' />
                <img alt='delete' src={downloadIcon} onClick={(e)=> deleteFormat(e,item.id)} className='downloadButton' />
            </>
        }
    })

    const dataForGRCModifiers = modifiers?.map((item, index) => {
        return {
            modifierName: item.modifierName,
            format: item.format,
            field:item.field,
            type: item.type,
            value: item.value,
            action :<img alt='download' src={downloadIcon} className='downloadButton' />
        }
    })

    


    const columsFormatList = [
        { name: 'Format Name', selector: row => row.name, sortable: false },
        { name: 'Action', selector: row => row.action, sortable: false },
    ];

    const columnsGRCModifiers = [
        { name: 'Modifer Name', selector: row => row.modifierName, sortable: true },
        { name: 'Format', selector: row => row.format, sortable: true },
        { name: 'Field', selector: row => row.field, sortable: true },
        { name: 'Type', selector: row => row.type, sortable: true },
        { name: 'Value(s)/Limitations', selector: row => row.value, sortable: true },
        { name: 'Action', selector: row => row.action, sortable: false },
    ];

    const customWidthsDataHealth = {
        'Format Name': '80%',
        'Action': '20%',
    };

    const customWidthGRCModifier = {
        'Modifer Name': '15%',
        'Format': '15%',
        'Field': '20%',
        'Type': '10%',
        'Value(s)/Limitations': '30%',
        'Action': '10%',

    };

    const saveFormat = (e) => {
        e.preventDefault();

        // // Hit Api endpoint for Saving Format 
        // const data = { formatName: formatName, csvFile: file }
        // console.log(data)
        console.log(123, file)
        convertFileToJsonAndSave(file)


        //after saving reset hooks 
        setFile(null)
        setFormatName('')
    }

    const deleteFormat = async (e, formatId) => {
        e.preventDefault()
        console.log("Deleting Format ID : ", formatId)
        await deleteCSVMatchById(formatId)
        fetchCSVFormats();
    }

    const updateFormat = async (e, formatId) => {
        e.preventDefault()
        console.log("Updating Format ID : ", formatId)

        const response = await getCSVMatchById(formatId)

        console.log("Response after fetching format by ID", {response})

        const csvDataArray = [];

        // const result = response[0]?.matchFields?.reduce((acc, item) => {
        //     acc[item.csvColumn] = item.firstRowData;
        //     return acc;
        // }, {});
        const result = {};
        const duplicates = [];
        if (response[0]?.matchFields) {
            response[0].matchFields.forEach(item => {
                if (result[item.csvColumn] === undefined) {
                    result[item.csvColumn] = item.firstRowData;
                } else {
                    const temp = {}
                    temp[item.csvColumn] = item.firstRowData;
                    duplicates.push([temp]);
                }
            });
        }
        
        csvDataArray.push([result])

        for (let i = 0; i < duplicates.length; i++) {
            const duplicate = duplicates[i];
            csvDataArray.push(duplicate);
        }

        console.log({result}, csvDataArray)

        navigate('/dashboard/settings?tab=1', {
            state: {
                csvData: csvDataArray,
                formatName: response.name,
                source: "format",
                edit: true,
                previousFormat: response
            }
        });


    }

    async function fetchCSVFormats() {
        setLoading(true)
        const response = await getCSVMatch();

        const result = response.map(res => ({
            id: res.skyflow_id,
            formatName: res.name,
            matchFields: res.matchFields,
            dropDownMatchFields: res.dropDownMatchFields,
        }))

      

        console.log("Response after fetching formats", {response})

        const responseModifiers = await getModifiers();
        const resultModifiers = responseModifiers.map(res => ({
            id: res.skyflow_id,
            modifierName: res.modifierName,
            format: res.modifierFormatName,
            field:res.grcColumn,
            type:res.modifierType === 'dropdown' ? 'Dropdown' : 'Boolean',
            value: res.modifierValues?.join(', ')


        }))
        console.log("Modifiers Response ", {responseModifiers})


        console.log("here talha: ", {result})
        setFormats(result)
        if(result.length > 0){ 
            setSelectedFormat(result[0].id)
            // setFields(result[0].matchFields)
         }
        setModifiers(resultModifiers)
        setLoading(false)
    }

    async function createGCRModifier(){


        // "modifierName": "testModifier",
        // "grcColumn": "test_modifier",
        // "modifierFormatID": "53cba50f-186e-4499-87c7-f2266a4f247f",
        // "modifierFormatName": "parquet",
        // "modifierType": "dropdown",
        // "modifierValues": [
        //     "value1",
        //     "value2"
        //  ]

        const payload = {
            grcColumn: selectedField,
            modifierName : modifierFormData.modifierName,
            modifierFormatID : selectedFormat,
            modifierFormatName : formats.find(format => format.id === selectedFormat)?.formatName,
            modifierType:modifierFormData.typeSelected,
            modifierValues:modifierFormData.typeSelected ==='boolean' ? [modifierFormData.positiveValue,modifierFormData.negativeValue] : modifierFormData.multiSelectValues
        }
        console.log("Modifiers Payload Check ",{payload})

        const body = JSON.stringify(payload)
        setLoading(true)
        const response = await createModifier(payload);
        console.log("Response after creating modifier", {response})
        setLoading(false)
        fetchCSVFormats();

        setModifierFormData({
            modifierName:'',
            format:'',
            typeSelected:'',
            multiSelectValues:[''],
            positiveValue:'True',
            negativeValue:'False'
        })
        setShow(false)

    }

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleCancelFile = (name) => {
        const filteredFiles = Array.from(file).filter(file => file.name !== name);
        if (!filteredFiles.length){
            setFile(null)
            return
        } 
        setFile(filteredFiles); 

    };

    useEffect(() => {
        fetchCSVFormats();
    }, []);

    function trimArrayToMax40(array) {
        if (array.length > 40) {
            return array.slice(0, 40);
        } else {
            return array;
        }
    }
    const convertFileToJsonAndSave = async (files) => {

        const csvDataArray = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const csvData = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const content = event.target.result;
                    const jsonData = csvToJson(content);
                    if (jsonData !== null) {
                        // resolve(trimArrayToMax40(jsonData));
                        resolve(jsonData)
                    } else {
                        reject(new Error('Failed to parse CSV data'));
                    }
                };
                reader.readAsText(file);
            }).catch(error => {
                console.error('Error:', error);
                return null;
            });
            
            if (csvData !== null) {
                csvDataArray.push(csvData);
            }
        }
    
        // const formatName = ''; // Add your formatName logic here
        navigate('/dashboard/settings?tab=1', { state: { csvData: csvDataArray, formatName: formatName, source: "format" } });
    };
    
    

    const csvToJson = (csvContent) => {
        const { data, errors } = Papa.parse(csvContent, { header: true,skipEmptyLines:true }); // Parse CSV with PapaParse
        if (errors.length) {
            console.error("Invalid CSV File/Data !", errors);
            let errorString = errors.map((error,index) =>{
                return `Row # ${error.row}   ${error.message}`
            }).join("\n")
            Swal.fire({
                title: 'Invalid CSV File/Data !',
                animation:true,
                text: errorString,
              })
            return null;
        }
        return data;
    };

    const setFormatSelect = (format) => {
        console.log("Selected Format ", format)
        setSelectedFormat(format);
        setFields(formats.find(f => f.id === format)?.matchFields)
    }
    return (
        <div>
            <LoadingProgressBar show={loading}  />
           <Title>Format Preferences</Title>
           <hr/>

           <Tabs fill defaultActiveKey="outputFormats" transition={false} className="mb-3">
             <Tab eventKey="outputFormats" title='Output Formats '>
                 


           <Title style={{fontSize:'17px'}}>Add new Format</Title>

           <Row>
                <Col lg={4} md={3} xs={6}>
                    <Label>Format Name</Label>
                    <TextField name="formatName" placeholder='Format Name' value={formatName} onChange={(e) => setFormatName(e.target.value)} />
                </Col>

                <Col lg={4} md={3} xs={6}>
                {/* <input ref={fileInputRef} type="file" accept=".csv" onChange={handleFileChange}  /> */}
                    {/* <Button onClick={handleUploadButtonClick} label='Upload File '/> */}
                    <Label>Upload CSV File</Label>
                    <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
                    <Button onClick={handleUploadButtonClick} label='Upload File '/>

                </Col>

                <Col lg={3} md={3} xs={3}>
                    <Button style={{marginTop:'10px',float:'right'}} label='Save Format' onClick={(e) => saveFormat(e)}></Button>
                </Col>
           </Row>

            {file && (
                <div className="file-details">
                    {
                        fileArray.map(f => {
                            return <div>
                                <p className="file-label"><img alt="file icon" src={FileIcon} style={{ marginRight: '10px' }} />{f.name} - <img src={deleteIcon} onClick={() => { handleCancelFile(f.name) }} alt="delete file" style={{ cursor: 'pointer' }} /></p>
                                {/* <Button  type="button" className="btn-close"/> */}
                            </div>
                        })}
                    <p>{formatFileSize(totalSize)}</p>
                    <img alt='progressbar' src={progressBar} />
                </div>
            )
            }


          <br/>
          <Title style={{fontSize:'17px'}}>Existing Formats </Title>

          <DataTable columns={columsFormatList} data={dataForDataHealth} customWidths={customWidthsDataHealth} />


          <br/>

          </Tab>

          <Tab eventKey="grcModifiers" title='GRC Modifiers'>
                {/* GRC Drop Down Matching Tab  */}
                <Title style={{fontSize:'17px'}}>GRC Modifiers   <Button onClick={handleShow} style={{float:'right'}} label='Add New Modifier' />  </Title>
                <DataTable columns={columnsGRCModifiers} data={dataForGRCModifiers} customWidths={customWidthGRCModifier} />
                {/* GRC Drop Down Matching Tab  */}
            </Tab>
            </Tabs>



        <Modal  show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                 <Modal.Title>Add GRC Modifier</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Form Body  */}

                <Label>Modifier Name : </Label>
                <TextField value={modifierFormData.modifierName} name="modifierName" onChange={(e)=> handleFormChange(e)} placeholder='Modifier Name' />

                <Label style={{marginTop:'30px'}}>Select Format :</Label>
                <select name="format" onChange={(e) => setFormatSelect(e.target.value)}  className="select">
                <option disabled> - - - Select Format - - -  </option> 
                    {formats?.map((format, index) => { return <> <option  key={index} value={format.id}>{format.formatName}</option></> })}
                </select>


                <Label style={{marginTop:'30px'}}>Select Field  :</Label>
                <select name="field" onChange={(e) => setSelectedField(e.target.value)}  className="select">
                <option disabled selected> - - - Select Field - - -  </option>
                    {fields?.map((field, index) => { return <option  key={index} value={field.matchedColumn}>{field.matchedColumn}</option> })}
                </select>

                {selectedFormat && selectedFormat?.matchFields && <>
                 
                    <Label style={{marginTop:'30px'}}>Match Fields :</Label>
                    <ul>
                        {selectedFormat?.matchFields.map((field, index) => { return <li key={index}>{field}</li> })}
                    </ul>
                    
                </>}


                <Label style={{marginTop:'30px'}}>Modifier Type :</Label>
                <select onChange={handleFormChange} value={modifierFormData.typeSelected} name="typeSelected" className="select">
                <option disabled id="" value=''>- - - - Select Modifier Type - - - - </option>
                    <option  value='boolean'>Boolean</option>
                    <option  value='dropdown'>Dropdown(Multiple Values)</option>
                    <option disabled value='Integer'>Integer (Coming Soon )</option>
                    <option disabled value='Date Formatting'>Date Formatting (Coming Soon )</option>
                </select>
                <hr/>

                <Title style={{fontSize:'17px'}}>Values : </Title>

                {modifierFormData.typeSelected === 'boolean' && (
                    <>
                        <Label>Positive Value :</Label>
                        <TextField onChange={handleFormChange} value={modifierFormData.positiveValue}  name="positiveValue"/>

                        <Label style={{marginTop:'20px'}}>Negative Value :</Label>
                        <TextField onChange={handleFormChange} value={modifierFormData.negativeValue}  name="negativeValue"/>
                    </>
                )}


            {modifierFormData.typeSelected === 'dropdown' && (
            <div>
               
                {modifierFormData.multiSelectValues.map((value, index) => { return (
                    <div key={index}>
                        {/* <Label >Value # {index+1}</Label> */}
                        <TextField 
                        style={{marginBottom:'20px',width:'90%',marginRight:'20px'}}
                            value={value} 
                            placeholder={`Enter Value ${index+1}`}
                            onChange={(e) => handleValueChange(index, e.target.value)} 
                        />

                       
                        <img onClick={() => handleDeleteValue(index)} src={deleteIcon} style={{cursor:'pointer'}} />

                    </div>
                )})}
                <Button label='Add Another  ' type="button" onClick={handleAddValue} />
            </div>
            )}

                {/* Form Body  */}    
            </Modal.Body>
            <Modal.Footer>
                <Button label='Cancel' variant="default" onClick={handleClose} />
                <Button label='Save ' variant="primary" onClick={createGCRModifier} />
            </Modal.Footer>
        </Modal>


           {/* <Table striped bordered hover size="sm">
            <thead>
                <tr>
                <th>Format Name </th>
                <th style={{textAlign:'center'}}>Action </th>
                </tr>
            </thead>
            <tbody>

                {formats?.map((format,index) =>{
                    return(
                    <tr key={index}>
                        <td>{format?.formatName}</td>
                        <td style={{alignItems:'center',textAlign:'center'}}><Button onClick={(e)=> deleteFormat(e,format?.id)}  label='Delete'/></td>
                    </tr>
                )})}


              
            
            </tbody>
            </Table> */}
        </div>
    );
}

export default FormatPreferences;
