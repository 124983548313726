import React, { useState } from 'react';

import Button from '../../../components/Button';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import TextField from '../../../components/TextField';
import Title from '../../../components/Typorgraphy/Title';
import Label from '../../../components/Typorgraphy/Label';
import '../../../assets/css/App.scss'
import { useNavigate } from 'react-router-dom';
import keyIcon from '../../../assets/icons/key.svg';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import backgroundImage from '../../../assets/images/bg2.png';
const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [email,setEmail] = useState('');


const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/verify-email');
  }

  return (
   <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.centered}>
      
      


 <div style={{ display: 'flex', justifyContent: 'center', height: '100vh',background:'transparent',marginTop:'150px' }}>
      <form  onSubmit={handleSubmit} style={{ width: '350px' }}>
      
      <div className="text-center mb-3">
            <img src={keyIcon} alt="Logo" className='icon-border' />
          </div>
        <Title style={{textAlign:'center'}}>Thank's for Joining  </Title>
        <Paragraph style={{textAlign:'center'}}>You're on our waiting list, and access will be granted shortly. Keep an eye on your email for notification.</Paragraph>

        
        {/* <div style={{marginBottom:'20px',marginTop:'35px'}}>
          <Label>Email</Label>
          <TextField isRequired  name="email" type='email' placeholder="Enter Your Email Address" value={email} onChange={(e)=>setEmail(e.target.value)} />
        </div> */}
      
        <Button onClick={()=> window.location.href = "https://creditcurve.ai"}  style={{width:'100%',marginBottom:'30px'}} label={"Visit Our Website !"} ></Button>
        <div className="d-flex align-items-center" style={{textAlign:'center',justifyContent:'center',justifyItems:'center'}}>
             
           
              {/* <Label style={{cursor:'pointer',fontWeight:'600'}} onClick={()=> navigate('/login')}>
                <img src ={arrowLeft} alt="arrow" style={{marginRight:'5px'}}/>
                Back to log in </Label> */}
        </div>
       
      </form>
    </div>








      </div>
    </div>
    </div>
  );
};

const styles = {
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
        backgroundPosition: 'center top',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    
   
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  centered: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export default ForgotPasswordPage;
