import React,{useState} from "react";
import Title from '../../../components//Typorgraphy/Title'
import Badge from 'react-bootstrap/Badge';
import './conversations.css'
import TextField from "../../../components/TextField";
import {Row,Col} from "react-bootstrap"
import profileImage from "../../../assets/icons/profile.svg"
import Label from "../../../components/Typorgraphy/Label"
import Button from "../../../components/Button"
const Conversations = () => {
    const [searchText,setSearchtext ] = useState('')
    const [conversations,setConversations] = useState([{id:1,name:'Talha Bin Shafiq'},{id:1,name:'Talha Bin Shafiq'},{id:1,name:'Talha Bin Shafiq'},{id:1,name:'Talha Bin Shafiq'},{id:1,name:'Talha Bin Shafiq'}])

    return (
        <div>
            <Row>
                {/* Messaegs List Section  */}
                <Col className="chat-list" style={{borderRight:'1px solid #c0bec3'}} lg={3} md={3}>
                    <Title style={{fontSize:'18px',paddingLeft:'20px'}}>Messages <Badge className="badge-chat">40</Badge></Title>
                    <TextField style={{padding:'10px',fontSize:'14px',lineHeight:'10px',width:'100%',marginTop:'5px'}} value={searchText} onChange={(e)=>{setSearchtext(e.target.value)}} placeholder="Search conversations . . ." type="text" />
               <br/><br/>

               {conversations.map((conversation)=>{
                     return  <><div className="chat-item">
                     <Row>
                          <Col xs={3}>
                            <img src={profileImage} alt="profile image "/>
                          </Col>
                          <Col xs={9} style={{alignContent:'center',paddingTop:'10px'}}>
                              <Label style={{fontSize:'15px'}}>{conversation.name}</Label>
                          </Col>
                     </Row> </div> </>
               })}
                
               


               
                </Col>
                {/* Message COnversation Section */}
                <Col  lg={9} md={9}>
                
                
                {/* Topbar Section */}
                <Row>
                    <Col xs={1}>
                    <img src={profileImage} alt="profile image "/>
                    </Col>
                    <Col xs={8} style={{alignContent:'center',paddingTop:'0px'}}>
                        <Title >Talha Bin Shafiq</Title>
                    </Col>
                </Row>
                <hr/>
                {/* Top Bar Sectoion */}

               {/* Chat Messages section */}
                <Row>
                    <div className="chat-conversation"></div>

                </Row>
                {/*  Char messages section */}


                {/* Send Message Box  */}
                <textarea placeholder="Type your message here . . . ." rows={3} className="chat-send"></textarea>
                {/* Send Message box  */}

                <Button label="Send" />

                
                </Col>
            </Row>


          
           
            
        </div>
    );
}

export default Conversations;