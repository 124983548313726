import React from "react";
import Title from '../../../components//Typorgraphy/Title'

const Definitions = () => {

    return (
        <div>
           <Title>Definitions</Title>

            
        </div>
    );
}

export default Definitions;