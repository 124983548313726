import React,{useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import Paragraph from '../../Typorgraphy/Paragraph';
import './style.css';
import CustomRadioButton from '../../RadioButton/index';
import Label from '../../Typorgraphy/Label';
import TextField from '../../TextField';
import masterCard from '../../../assets/icons/mastercard.svg'
import visa from '../../../assets/icons/visa.svg'
import plusIcon from '../../../assets/icons/plus.svg'
import AnchorText from '../../Typorgraphy/AnchorText'
import cardHeading from '../../../assets/icons/cardheading.svg'
import Modal from '../../Modal';
import DataTable from  '../../DataTable/index'
import checkGreen from '../../../assets/icons/checkgreen.svg'
import downloadIcon from '../../../assets/icons/download.svg'


const columns = [
	{
		name: 'Invoice',
		selector: row => row.invoice,
		sortable: true,
	},
	{
		name: 'Amount',
		selector: row => row.amount,
		sortable: true,
	},
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row.actions,
        sortable: false,
    }
    
];

const data = [
  	{
		id: 1,
		invoice: <Label style={{marginTop:'15px'}}>Basic Plan – Oct 2022</Label>,
		amount: 'USD $10.00',
        date:'Dec 1, 2022',
        status:<p className='labelStatus'><img alt='status' src={checkGreen} style={{marginRight:'5px'}} />Paid</p>,
        actions:<img alt='download' src={downloadIcon} className='downloadButton' />
	},
    {
		id: 2,
		invoice: <Label style={{marginTop:'15px'}}>Basic Plan – Oct 2022</Label>,
		amount: 'USD $10.00',
        date:'Dec 1, 2022',
        status:<p className='labelStatus'><img alt='status' src={checkGreen} style={{marginRight:'5px'}} />Paid</p>,
        actions:<img alt='download' src={downloadIcon} className='downloadButton' />
	},
    {
		id: 3,
		invoice: <Label style={{marginTop:'15px'}}>Basic Plan – Oct 2022</Label>,
		amount: 'USD $10.00',
        date:'Dec 1, 2022',
        status:<p className='labelStatus'><img alt='status' src={checkGreen} style={{marginRight:'5px'}} />Paid</p>,
        actions:<img alt='download' src={downloadIcon} className='downloadButton' />
	},
    {
		id: 4,
		invoice: <Label style={{marginTop:'15px'}}>Basic Plan – Oct 2022</Label>,
		amount: 'USD $10.00',
        date:'Dec 1, 2022',
        status:<p className='labelStatus'><img alt='status' src={checkGreen} style={{marginRight:'5px'}} />Paid</p>,
        actions:<img alt='download' src={downloadIcon} className='downloadButton' />
	},
    
	
]

const Billing = () => {
    const [modalShow, setModalShow] = useState(false);
    const [cardDetails, setCardDetails] = useState({name:'',expiry:'',cardNumber:'',cvv:'' })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardDetails((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };
    const handleRadio = (e) => {
        if(e.target.value === '2'){
            setShowEmailInput(true);
        }else{
            setShowEmailInput(false);
        }
    }
    const [showEmailInput,setShowEmailInput] = React.useState(false);
    const [email,setEmail] = React.useState('');
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
    const handleCheckboxChange = (checkboxNumber) => {
        if (checkboxNumber === 1) {
          setIsChecked1(true);
          setIsChecked2(false);
        } else {
          setIsChecked1(false);
          setIsChecked2(true);
        }
      };
    return (
       <>
       <Row>
        <Col xs={12}> 
                    <p className="section-title" style={{color:'#101828'}}>Payment method</p>
                    <Paragraph style={{marginTop:'-10px'}}>Update your billing details and address.</Paragraph>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col xs={4}>
                <p className="section-subheading" style={{color:'#101828',fontSize:'14px'}}>Contact Email</p>
                <Paragraph style={{marginTop:'-10px'}}>Where should invoices be sent ? </Paragraph>
                </Col>
                <Col xs={6} >
                <CustomRadioButton  id="radio1" onChange={(e)=> handleRadio(e)} name="customRadio" label="Send to my account email" value="1" />
                <CustomRadioButton onChange={(e)=> handleRadio(e)} id="radio2" name="customRadio" label="Send to an alternative email" value="2" />
                {showEmailInput && <TextField onChange={(e)=>setEmail(e.target.value)} value={email} placeholder="Enter alternative email address" style={{lineHeight:'12px',fontSize:'14px',width:'85%',marginTop:'10px'}} />}
                </Col>
        </Row>
        <hr/>
        <Row>
            <Col xs={4}>
                <p className="section-subheading" style={{color:'#101828',fontSize:'14px'}}>Card details</p>
                <Paragraph style={{marginTop:'-10px'}}>Select default payment method. </Paragraph>
            </Col>
            <Col xs={6} >
                <div className={`payment-card ${isChecked1 ? 'selected' : ''} `}>
                <img src={visa} alt='visa' />
                    <div className='content'>
                        <span className='payment-title'>Visa ending in 1234</span>
                        <p style={{fontSize:'13px'}}>Expiry 06/2024</p>
                       <Row style={{marginTop:'-12px'}}>
                        <Col xs={9}>
                             <AnchorText onClick={() => handleCheckboxChange(1)}  style={{color:'#475467'}}>Set as default </AnchorText>
                        </Col>
                        <Col xs={2}>
                            <AnchorText onClick={()=> setModalShow(true)} >Edit </AnchorText>
                        </Col>
                       </Row>
                    </div>
                    <div className='small-text'>
                        <input  checked={isChecked1} onChange={() => handleCheckboxChange(1)} type='checkbox' className='custom-checkbox ' />
                    </div>
                </div>
                <div className={`payment-card ${isChecked2 ? 'selected' : ''} `}>
                <img src={masterCard} alt='mastercard' />
                    <div className='content'>
                        <span className='payment-title'>Mastercard ending in 1234</span>
                        <p style={{fontSize:'13px'}}>Expiry 06/2024</p>

                       <Row style={{marginTop:'-12px'}}>
                        <Col xs={9}>
                             <AnchorText onClick={() => handleCheckboxChange(2)} style={{color:'#475467'}}>Set as default </AnchorText>
                        </Col>
                        <Col xs={2}>
                            <AnchorText onClick={()=>setModalShow(true)} >Edit </AnchorText>
                        </Col>
                       </Row>
                    </div>
                    <div className='small-text'>
                        <input  checked={isChecked2} onChange={() => handleCheckboxChange(2)} type='checkbox' className='custom-checkbox ' />
                    </div>
                </div>

                <AnchorText onClick={()=> setModalShow(true)} style={{color:'#475467'}}><img  src={plusIcon} alt='plus' />Add new payment method</AnchorText>


            </Col>
        </Row>
        <hr/>   

        <Row>
            <p className="section-title" style={{color:'#101828'}}>Billing History</p>
        </Row>


        <DataTable columns={columns} data={data} selectableRows />
        <div style={{marginBottom:'50px'}}></div>






















        <Modal show={modalShow} onHide={() => setModalShow(false)} image={cardHeading} style={{padding:'20px'}}>
         <span className="card-title">Add new payment method</span>
         <Paragraph>Add your card details.</Paragraph>

            <Row style={{marginTop:'30px'}}>
                <Col xs={9}>
                    <Label>Name on Card</Label>
                    <TextField  name='name' onChange={(e)=>handleInputChange(e)} value={cardDetails.name} placeholder='Your name on card '></TextField>
                </Col>
                <Col xs={3}>
                    <Label>Expiry</Label>
                    <TextField name='expiry' onChange={(e)=>handleInputChange(e)} value={cardDetails.expiry}  placeholder='MM/YY'></TextField>
                </Col>
            </Row>

            <Row style={{marginTop:'30px'}}>
                <Col xs={9}>
                    <Label>Card Number</Label>
                    <TextField name='cardNumber' onChange={(e)=>handleInputChange(e)}  value={cardDetails.cardNumber}  placeholder='Enter your card number '></TextField>
                </Col>
                <Col xs={3}>
                    <Label>CVV</Label>
                    <TextField name='cvv' onChange={(e)=>handleInputChange(e)} value={cardDetails.cvv} type='password'  placeholder='CVV'></TextField>
                </Col>
            </Row>
      </Modal>
       </>
    )}
export default Billing;