// Label.js
import React from 'react';
import PropTypes from 'prop-types';
import './style.css'; // Import your custom CSS file

const Label = ({ text,variant='' }) => {
  return (
    <div className="customLabel2 label-with-bullet">
      <span className={`bullet ${variant}`} ></span>
      <span className="label-text">{text}</span>
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Label;
