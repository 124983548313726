import React from 'react';

import Button from '../../../components/Button';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Title from '../../../components/Typorgraphy/Title';

import Label from '../../../components/Typorgraphy/Label';
import '../../../assets/css/App.scss'
import { useNavigate } from 'react-router-dom';
import successIcon from '../../../assets/icons/success.svg';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import backgroundImage from '../../../assets/images/bg2.png';
const ForgotPasswordPage = () => {


    const navigate = useNavigate();

  return (
   <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.centered}>
      
      


 <div style={{ display: 'flex', justifyContent: 'center', height: '100vh',background:'transparent',marginTop:'150px' }}>
      <form  style={{ width: '350px' }}>
      
      <div className="text-center mb-3">
            <img src={successIcon} alt="Logo" className='icon-border' />
          </div>
        <Title style={{textAlign:'center'}}>Password Reset </Title>
        <Paragraph style={{textAlign:'center'}}>Your password has been successfully reset. Click below to log in magically.</Paragraph>

        
       
      
        <Button type='button' onClick={()=>{navigate('/login')}} style={{width:'100%',marginBottom:'30px',marginTop:'20px'}} label={"Continue"} ></Button>

       

        <div className="d-flex align-items-center" style={{textAlign:'center',justifyContent:'center',justifyItems:'center',marginTop:'20px'}}>
             
           
              <Label style={{cursor:'pointer',fontWeight:'600'}} onClick={()=> navigate('/login')}>
                <img src ={arrowLeft} alt="arrow" style={{marginRight:'5px'}}/>
                Back to log in </Label>
        </div>
       
      </form>
    </div>








      </div>
    </div>
    </div>
  );
};

const styles = {
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
        backgroundPosition: 'center top',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    
   
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  centered: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export default ForgotPasswordPage;
