import React, { useState } from 'react';
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import checkIcon from '../../../assets/images/check.svg'
import uncheckIcon from '../../../assets/images/uncheck.svg'
import "./style.css";

const VerticalTabs = ({  }) => {
    const tabs = [
        { id: 'tab1', title: 'Loan Status', content: <div>Content for Tab 1</div> },
        { id: 'tab2', title: 'Credit Card Type ', content: <div>Content for Tab 2</div> },
        { id: 'tab3', title: 'Payment Type ', content: <div>Content for Tab 3</div> },
      ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [completedTabs, setCompletedTabs] = useState([]);

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const markTabAsDone = (tabId) => {
    if (!completedTabs.includes(tabId)) {
      setCompletedTabs([...completedTabs, tabId]);
    }
  };


  return (
    <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            {tabs.map((tab) => (
              <Nav.Item key={tab.id}>
                <Nav.Link eventKey={tab.id}>
                  {tab.title}
                 
                    <img src={completedTabs.includes(tab.id) ? checkIcon : uncheckIcon} style={{ marginLeft: '10px', color: 'green',float:'right' }} / >
                
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {tabs.map((tab) => (
              <Tab.Pane eventKey={tab.id} key={tab.id}>
                {tab.content}
                <button onClick={() => markTabAsDone(tab.id)}>Mark as Done</button>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default VerticalTabs;
