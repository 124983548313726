import { Row,Col } from "react-bootstrap";
import {  useState, useEffect } from "react";
import Paragraph from '../../Typorgraphy/Paragraph'
import Label from '../../Typorgraphy/Label'
import TextField from "../../TextField";
import Modal from '../../Modal'
import Button from "../../Button";
import deleteIcon from '../../../assets/icons/delete.svg'
import editIcon from '../../../assets/icons/edit.svg'
import DataTable from '../../DataTable/index'
import CustomLabel from '../../CustomLabel'
import CustomLabel2 from '../../CustomLabel/CustomLabel2'
import { createRole, getRoles, addParticipant, getTeamDetails, deleteParticipant, deleteRole, updateRoleById, updateParticipantById } from '../../../api/team';

const Integrations = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [modalShow3, setModalShow3] = useState(false);
    const [modalShow4, setModalShow4] = useState(false);
    const [userInfo, setUserInfo] = useState({name:'',email:'',role:1});
    const [updateUserInfo, setUpdateUserInfo] = useState({skyflow_id: '', name:'', email: '', role:1});
    const [rolesData, setRolesData] = useState([])
    const [teamDetails, setTeamDetails] = useState([])
    const [newRoleInfo, setNewRoleInfo] = useState({
        name: '',
        description: '',
        resources: {
            marketplace: false,
            conversations: false,
            definitionManagement: false,
            dealFlow: false,
            formatPreferences: false,
        },
        permissions: {
            view: false, 
            create: false,
            edit: false,
            delete: false,
        }
    });

    const [updateRoleInfo, setUpdateRoleInfo] = useState({
        skyflow_id: '',
        name: '',
        description: '',
        resources: {
            marketplace: false,
            conversations: false,
            definitionManagement: false,
            dealFlow: false,
            formatPreferences: false,
        },
        permissions: {
            view: false, 
            create: false,
            edit: false,
            delete: false,
        }
    });

    const resourcesJson = {
        marketplace: "Marketplace",
        conversations: "Conversations",
        definitionManagement: "Definition Management",
        dealFlow: "Deal Flow",
        formatPreferences: "Format Preferences",
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserInfo((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };

      const handleUpdateInputChange = (e) => {
        const { name, value } = e.target;
        setUpdateUserInfo((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };

      const handleRoleInputChange = (e) => {
        const { name, value } = e.target;    
        setNewRoleInfo((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };

      const handleRoleCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setNewRoleInfo((prevInfo) => ({
            ...prevInfo,
            resources: {
                ...prevInfo.resources,
                [name]: checked, 
            },
        }));
      };

      const handleRolePermissionsCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setNewRoleInfo((prevInfo) => ({
            ...prevInfo,
            permissions: {
                ...prevInfo.permissions,
                [name]: checked, 
            },
        }));
      };

      const handleRoleUpdateInputChange = (e) => {
        const { name, value } = e.target;    
        setUpdateRoleInfo((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };

      const handleRoleUpdateCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setUpdateRoleInfo((prevInfo) => ({
            ...prevInfo,
            resources: {
                ...prevInfo.resources,
                [name]: checked, 
            },
        }));
      };

      const handleRoleUpdatePermissionsCheckBoxChange = (e) => {
        const { name, checked } = e.target;
        setUpdateRoleInfo((prevInfo) => ({
            ...prevInfo,
            permissions: {
                ...prevInfo.permissions,
                [name]: checked, 
            },
        }));
      };

    async function insertRole() {
        console.log(newRoleInfo)
        const trueResources = Object.keys(newRoleInfo.resources).filter(key => newRoleInfo.resources[key]);
        const truePermissions = Object.keys(newRoleInfo.permissions).filter(key => newRoleInfo.permissions[key]);

        const roleData = {
            name: newRoleInfo.name,
            description: newRoleInfo.description,
            resources: trueResources.join(','),
            permissions: truePermissions.join(','),
        };

        console.log({roleData})

        await createRole(roleData)
        setModalShow2(false)
        setNewRoleInfo({
            name: '',
            description: '',
            resources: {
                marketplace: true,
                conversations: true,
                definitionManagement: false,
                dealFlow: false,
                formatPreferences: false,
            },
            permissions: {
                view: true, 
                create: false,
                edit: false,
                delete: false,
            }
        })
        fetchData();
    }

    async function updateRole() {
        const trueResources = Object.keys(updateRoleInfo.resources).filter(key => updateRoleInfo.resources[key]);
        const truePermissions = Object.keys(updateRoleInfo.permissions).filter(key => updateRoleInfo.permissions[key]);

        const roleData = {
            name: updateRoleInfo.name,
            description: updateRoleInfo.description,
            resources: trueResources.join(','),
            permissions: truePermissions.join(','),
        };

        console.log({roleData})

        await updateRoleById(updateRoleInfo.skyflow_id, roleData)
        setModalShow2(false)
        setUpdateRoleInfo({
            name: '',
            description: '',
            resources: {
                marketplace: true,
                conversations: true,
                definitionManagement: false,
                dealFlow: false,
                formatPreferences: false,
            },
            permissions: {
                view: true, 
                create: false,
                edit: false,
                delete: false,
            }
        })
        fetchData();
    }

    async function insertParticipant() {

        const participantData = {
            name: userInfo.name,
            email: userInfo.email,
            role_id: userInfo.role,
        }

        console.log({participantData})

        await addParticipant(participantData)

        fetchData()

        
    }

    async function updateParticipant() {
        const participantData = {
            name: updateUserInfo.name,
            email: updateUserInfo.email,
            role_id: updateUserInfo.role,
        }

        console.log({participantData})

        await updateParticipantById(updateUserInfo.skyflow_id, participantData)

        fetchData()
    }

    async function fetchData() {
        const roles = await getRoles()

        if (roles!== null && roles !== undefined && roles !== ''){
            console.log(2)
            function replaceResources(role) {
                return role.resources
                    .split(',')
                    .map(key => resourcesJson[key] || key)
                    .join(',');
            }
            const rolesWithReplacedResources = roles.map(role => ({
                ...role,
                resources: replaceResources(role),
            }));
            setRolesData(rolesWithReplacedResources)

            if (rolesWithReplacedResources.length){
                setUserInfo({name:'',email:'',role:rolesWithReplacedResources[0].skyflow_id})
            }
        } else {
            setRolesData([])
        }

        const teamDetails = await getTeamDetails()

        console.log({teamDetails})

        setTeamDetails(teamDetails)

    }
    async function removeParticipant(participantId) {
        await deleteParticipant(participantId)

        fetchData()

    }

    async function removeRole(roleId) {
        await deleteRole(roleId)

        fetchData()

    }

    async function editRole(role) {
        console.log(role)
        setUpdateRoleInfo({
            skyflow_id: role.skyflow_id,
            name: role.name,
            description: role.description,
            resources: {
                marketplace: role.resources.includes(resourcesJson.marketplace),
                conversations: role.resources.includes(resourcesJson.conversations),
                definitionManagement: role.resources.includes(resourcesJson.definitionManagement),
                dealFlow: role.resources.includes(resourcesJson.dealFlow),
                formatPreferences: role.resources.includes(resourcesJson.formatPreferences),
            },
            permissions: {
                view: role.permissions.includes('view'), 
                create: role.permissions.includes('create'),
                edit: role.permissions.includes('edit'), 
                delete: role.permissions.includes('delete'),
            }
        })

        setModalShow3(true)

    }

    async function editParticipant(participant) {
        console.log(participant)
        setUpdateUserInfo({
            skyflow_id: participant.skyflow_id,
            name: participant.name,
            email: participant.email,
            role: participant.role_id
        })

        setModalShow4(true)
    }



      useEffect(() => {

        fetchData();

    }, [])

    const customWidths = {
        'Name': '15%', // Custom width for the 'Name' column
        'Permission': '30%', // Custom width for the 'Permission' column
        'Resources': '45%', // Custom width for the 'Permission' column
        'Action': '10%', // Custom width for the 'Action' column

      };
    const columnsRole = [
        {name: 'Name', selector: row => row.name,sortable: true},
        {name: 'Permissions',selector: row => row.permission,sortable: true},
        {name: 'Resources',selector: row => row.resource,sortable: true},
        {name: 'Action',selector: row => row.actions,sortable: false}
    ];
    const dataRoles = []

    for (let i=0; i<rolesData.length; i++){
        dataRoles.push({
            skyflow_id: rolesData[i].skyflow_id,
            id: i,
            name: <><div style={{ display: 'flex', alignItems: 'center' }}><CustomLabel variant="one" text={rolesData[i].name}/> </div></>,
            resource: <><Label style={{marginTop:'15px'}}>{rolesData[i].resources.replaceAll(',',', ')}</Label> </>,
            permission: <><div style={{ display: 'flex', alignItems: 'center' }}>{rolesData[i].permissions.includes('view') && <CustomLabel2 variant="success" text="View" />}{rolesData[i].permissions.includes('create') && <CustomLabel2 variant="info" text="Create" />}{rolesData[i].permissions.includes('edit') && <CustomLabel2 variant="warning" text="Edit" />}{rolesData[i].permissions.includes('delete') && <CustomLabel2 variant="error" text="Delete" />}</div></>,
            actions: <><img alt='edit' onClick={()=> removeRole(rolesData[i].skyflow_id)} src={deleteIcon} className='downloadButton' /> <img alt='edit' onClick={()=> editRole(rolesData[i])} src={editIcon} className='downloadButton' /></>
        })
    }


    const columnsTeam = [
        {name: 'Name', selector: row => row.name,sortable: true},
        {name: 'Email', selector: row => row.email,sortable: true},
        {name: 'Role', selector: row => row.role,sortable: true},
        {name: 'Action',selector: row => row.actions,sortable: false}
    ];

    const dataTeams = [];
    for (let i = 0; i < teamDetails?.participants?.length; i++) {
        const participant = teamDetails.participants[i]
        dataTeams.push({
            id: 1,
            name: <><Label style={{ marginTop: '15px' }}>{participant.name}</Label> </>,
            email: <><Label style={{ marginTop: '15px' }}>{participant.email}</Label> </>,
            role: <CustomLabel variant="one" text={participant.role_name} />,
            actions: <><img alt='edit' onClick={()=> removeParticipant(participant.skyflow_id)} src={deleteIcon} className='downloadButton' /> <img alt='edit' onClick={()=> editParticipant(participant)} src={editIcon}  className='downloadButton' /></>
        })
    }
    
    return (
       <>
        <Row>
            <Col xs={6}> 
                <p className="section-title" style={{color:'#101828'}}>Team Management</p>
                <Paragraph>Manage your team participants and their account permissions here.</Paragraph>
            </Col>
            <Col xs={2}> 
            </Col>
            <Col xs={4}> 
                <Button onClick={()=> setModalShow(true)} style={{float:'right',marginRight:'10px',marginTop:'20px'}} className='float-right' label='Add New Participant'></Button>
            </Col>
        </Row>
         <hr/>
            {/* Team Table Here  */}
            <DataTable columns={columnsTeam} data={dataTeams} selectableRows />
            {/* Team Table Here  */}

        <Row style={{marginTop:'30px'}}>
        <hr/>
            <Col xs={6}> 
                <p className="section-title" style={{color:'#101828'}}>Role Management</p>
                <Paragraph>Manage your team participants and their account permissions here.</Paragraph>
            </Col>
            <Col xs={2}> 
            </Col>
            <Col xs={4}> 
                <Button onClick={()=> setModalShow2(true)} variant="default" style={{float:'right',marginRight:'10px',marginTop:'20px'}} className='float-right' label='Add New Role'></Button>
            </Col>
        </Row>
         <hr/>
            {/* Roles Table Here  */}
           
            <DataTable columns={columnsRole} data={dataRoles} customWidths={customWidths} />

            {/* Roles Table Here  */}

        <div style={{marginBottom:'40px'}}></div>



        <Modal show={modalShow} onHide={() => setModalShow(false)} onSave={()=> insertParticipant()}   style={{padding:'20px'}}>
         <span className="card-title" >Add Team Participants</span>
         <Paragraph>Add your team participant</Paragraph>
            <div style={{marginTop:'30px'}}>
                <Label>Name *</Label>
                <TextField value={userInfo.name} onChange={(e)=> handleInputChange(e)} name="name" placeholder='Name'></TextField>
            </div>
            <div style={{marginTop:'30px'}}>
                <Label>Email *</Label>
                <TextField name='email' onChange={(e)=> handleInputChange(e)} value={userInfo.email} type="email" placeholder='Email Address '></TextField>
            </div>

            <div style={{marginTop:'30px'}}>
                <Label>Role *</Label>
                <select name='role' onChange={(e)=> handleInputChange(e)} value={userInfo.role} className="select">
                    {rolesData?.map(role => (
                        <option key={role.skyflow_id} value={role.skyflow_id}>
                            {role.name}
                        </option>
                    ))}
                </select>

            </div>
            
      </Modal>

      <Modal show={modalShow2} onHide={() => setModalShow2(false)} onSave={()=> insertRole()}   style={{padding:'20px'}}>
         <span className="card-title" >Add New Role</span>
         <Paragraph>Add a new role</Paragraph>
            <div style={{marginTop:'30px'}}>
                <Label>Name *</Label>
                <TextField value={newRoleInfo.name} onChange={(e)=> handleRoleInputChange(e)} name="name" placeholder='Name'></TextField>
            </div>
            <div style={{marginTop:'30px'}}>
                <Label>Description *</Label>
                <TextField name='description' onChange={(e)=> handleRoleInputChange(e)} value={newRoleInfo.description} type="description" placeholder='Role description'></TextField>
            </div>

            <div style={{marginTop:'30px'}}>
                <Label>Resources </Label>
                <Row>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Marketplace</Paragraph>
                            <input name="marketplace" checked={newRoleInfo.resources.marketplace} onChange={(e)=> handleRoleCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Conversations</Paragraph>
                            <input name="conversations" checked={newRoleInfo.resources.conversations} onChange={(e)=> handleRoleCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Definition Management</Paragraph>
                            <input name="definitionManagement" checked={newRoleInfo.resources.definitionManagement} onChange={(e)=> handleRoleCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Deal Flow</Paragraph>
                            <input name="dealFlow" checked={newRoleInfo.resources.dealFlow} onChange={(e)=> handleRoleCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Format Preferences</Paragraph>
                            <input name="formatPreferences" checked={newRoleInfo.resources.formatPreferences} onChange={(e)=> handleRoleCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop:'30px'}}>
                <Label>Permissions </Label>
                <Row>
                    <Col sm={6}>
                        <div>
                            <Paragraph>View</Paragraph>
                            <input name="view" checked={newRoleInfo.permissions.view} onChange={(e)=> handleRolePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Create</Paragraph>
                            <input name="create" checked={newRoleInfo.permissions.create} onChange={(e)=> handleRolePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Edit</Paragraph>
                            <input name="edit" checked={newRoleInfo.permissions.edit} onChange={(e)=> handleRolePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Delete</Paragraph>
                            <input name="delete" checked={newRoleInfo.permissions.delete} onChange={(e)=> handleRolePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                </Row>
            </div>
      </Modal>


      <Modal show={modalShow3} onHide={() => setModalShow3(false)} onSave={()=> updateRole()}   style={{padding:'20px'}}>
         <span className="card-title" >Update a role</span>
         <Paragraph>Update Role</Paragraph>
            <div style={{marginTop:'30px'}}>
                <Label>Name *</Label>
                <TextField value={updateRoleInfo.name} onChange={(e)=> handleRoleUpdateInputChange(e)} name="name" placeholder='Name'></TextField>
            </div>
            <div style={{marginTop:'30px'}}>
                <Label>Description *</Label>
                <TextField name='description' onChange={(e)=> handleRoleUpdateInputChange(e)} value={updateRoleInfo.description} type="description" placeholder='Role description'></TextField>
            </div>

            <div style={{marginTop:'30px'}}>
                <Label>Resources </Label>
                <Row>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Marketplace</Paragraph>
                            <input name="marketplace" checked={updateRoleInfo.resources.marketplace} onChange={(e)=> handleRoleUpdateCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Conversations</Paragraph>
                            <input name="conversations" checked={updateRoleInfo.resources.conversations} onChange={(e)=> handleRoleUpdateCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Definition Management</Paragraph>
                            <input name="definitionManagement" checked={updateRoleInfo.resources.definitionManagement} onChange={(e)=> handleRoleUpdateCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Deal Flow</Paragraph>
                            <input name="dealFlow" checked={updateRoleInfo.resources.dealFlow} onChange={(e)=> handleRoleUpdateCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Format Preferences</Paragraph>
                            <input name="formatPreferences" checked={updateRoleInfo.resources.formatPreferences} onChange={(e)=> handleRoleUpdateCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop:'30px'}}>
                <Label>Permissions </Label>
                <Row>
                    <Col sm={6}>
                        <div>
                            <Paragraph>View</Paragraph>
                            <input name="view" checked={updateRoleInfo.permissions.view} onChange={(e)=> handleRoleUpdatePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Create</Paragraph>
                            <input name="create" checked={updateRoleInfo.permissions.create} onChange={(e)=> handleRoleUpdatePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Edit</Paragraph>
                            <input name="edit" checked={updateRoleInfo.permissions.edit} onChange={(e)=> handleRoleUpdatePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Paragraph>Delete</Paragraph>
                            <input name="delete" checked={updateRoleInfo.permissions.delete} onChange={(e)=> handleRoleUpdatePermissionsCheckBoxChange(e)} type='checkbox' className='custom-checkbox ' />
                        </div>
                    </Col>
                </Row>
            </div>
      </Modal>


      <Modal show={modalShow4} onHide={() => setModalShow4(false)} onSave={()=> updateParticipant()}   style={{padding:'20px'}}>
         <span className="card-title" >Update Team Participants</span>
         <Paragraph>Update your team participant: {updateUserInfo.email}</Paragraph>
            <div style={{marginTop:'30px'}}>
                <Label>Name *</Label>
                <TextField value={updateUserInfo.name} onChange={(e)=> handleUpdateInputChange(e)} name="name" placeholder='Name'></TextField>
            </div>

            <div style={{marginTop:'30px'}}>
                <Label>Role *</Label>
                <select name='role' onChange={(e)=> handleUpdateInputChange(e)} value={updateUserInfo.role} className="select">
                    {rolesData?.map(role => (
                        <option key={role.skyflow_id} value={role.skyflow_id}>
                            {role.name}
                        </option>
                    ))}
                </select>

            </div>
            
      </Modal>
       </>
    );
}

export default Integrations;