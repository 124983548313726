import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser'
const analytics = AnalyticsBrowser.load({ writeKey: 'a63e171cee150d0b73e6' })

export const eventNames = {
    onboarding: "onboarding",
}

export const eventTypes = {
    buttonClicked: 'button_clicked',
    checkboxClicked: 'checkbox_clicked',
    multiSelectCheckboxClicked: 'multi_select_checkbox_clicked',
    pageLoaded: 'page_loaded',
}

export const sendEventToCustomerIO = async (eventName, eventProperties = {}) => {
    try {
        await analytics.track(eventName, eventProperties);
        console.log(`Event "${eventName}" sent successfully with properties:`, eventProperties);
    } catch (error) {
        console.error(`Failed to send event "${eventName}":`, error);
    }
};

export const createCustomerIOCompany = async (companyData, userData) => {
    console.log("companyData", {companyData}, {userData})

    await analytics.group(companyData.companyName, { 
        name: companyData.companyName,
        website: companyData.websiteUrl,
        assetTypes: companyData.assetTypes,
        geographicFocus: companyData.geographicFocus,
        businessDescription: companyData.businessDescription,
        minAmount: companyData.minAmount,
        maxAmount: companyData.maxAmount,
        user: userData.email,
    });
}



export { analytics };
