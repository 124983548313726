import React, { useState,useEffect } from 'react';
import { login } from '../../../api/authApi';

import Button from '../../../components/Button';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import AnchorText from '../../../components/Typorgraphy/AnchorText';
import TextField from '../../../components/TextField';
import Title from '../../../components/Typorgraphy/Title';
import Label from '../../../components/Typorgraphy/Label';
import { Row, Col,  } from 'react-bootstrap';
import '../../../assets/css/App.scss'
import logo from '../../../assets/images/logoMain3.webp';
import { useNavigate } from 'react-router-dom';
import LoaderSpinner from '../../../components/Spinner';
import Cookies from 'js-cookie';
import { analytics, eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  async function parseOnboarding(onboarding) {
      console.log(onboarding)
      Cookies.set('onboardingState', onboarding);
        window.location.href = '/dashboard';

      // navigate('/dashboard', {state: onboarding})

      // if (!onboarding?.account_type){
      //   // if no account type, we navigate to onboarding page to set account type
      //   navigate('/onboarding', {state: onboarding})
      // } else {
      //   window.location.href = '/dashboard';
      // }
  }
  const handleForgotPassword = async (e) => {

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Login Form",
      buttonName: 'Forgot Password'
    })

    navigate('/forgot')
  };

  const handleSignupClicked = async (e) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Login Form",
      buttonName: 'Sign Up'
    })

    navigate('/register')
  };

  const handleLogin = async (e) => {

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Login Form",
      buttonName: 'Sign In'
    })

      e.preventDefault();
      
      setLoading(true)
      try {
        const result = await login(credentials);
        console.log(123,({result}))
        // console.log("Result",result.user)
        // if(result?.user?.is_allowed === 0){
        //   navigate('/waiting');
        // }
        // else{
          Cookies.set('token', result.token);
          // navigate('/dashboard')
          analytics.identify(result?.user?.email)

          parseOnboarding(result.onboardingStatus)


          // window.location.href = '/dashboard';
          
          setErrorMessage(null); 
        // }
       
      } catch (error) {
        setErrorMessage(error.message || error)
      }
      finally{
        setLoading(false)
      }
    };

    useEffect(() => {
      const isFirstVisit = localStorage.getItem('isFirst');
      if (!isFirstVisit) {
        localStorage.setItem('isFirst', 'false');
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 800);
      }
    }, []);


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoaderSpinner show={loading} />
      <form onSubmit={handleLogin} style={{ width: '350px' }}>
       <div className="text-center mb-3">
            <img width={80} src={logo} alt="Logo" />
          </div>
        <Title style={{textAlign:'center'}}>Welcome Back</Title>
        <Paragraph style={{textAlign:'center'}}>Welcome back! Please enter your details.</Paragraph>

         {errorMessage && (
          <div style={{ color: 'red', marginTop: '10px', textAlign: 'center',marginBottom:'15px' }}>{errorMessage}</div>
        )}
        <div style={{marginBottom:'20px',marginTop:'35px'}}>
          <Label>Email</Label>
          <TextField isRequired  name="email" autocomplete="email" type='email' placeholder="Enter your email address" value={credentials.email} onChange={(e)=>handleInputChange(e)} />
        </div>
        <div style={{marginBottom:'30px'}}>
          <Label>Password</Label>
            <TextField isRequired name="password" autocomplete="password" type='password' placeholder="Enter your  password" value={credentials.password} onChange={handleInputChange} />
        </div>
        <Row style={{marginBottom:'10px'}}>
          <Col sm={7}>
              <div className="d-flex align-items-center">
                  <input style={{marginTop:'-10px',cursor:'pointer'}} type="checkbox" id="remember" name="remember" className='custom' value="remember" />
                  <Label style={{marginLeft:'5px',marginTop:'7px'}}>Remember for 30 days </Label>
              </div>
          </Col>
          <Col sm={5} className="d-flex justify-content-end">
            <AnchorText onClick={handleForgotPassword}>Forgot Password </AnchorText>
          </Col>
        </Row>
        <Button type='submit' style={{width:'100%',marginBottom:'20px'}} label={"Sign in"} ></Button>
        <div className="d-flex align-items-center" style={{textAlign:'center',justifyContent:'center',justifyItems:'center'}}>
             
            <Paragraph style={{textAlign:'center',marginRight:'7px'}}>Don't have an account? </Paragraph>
              <AnchorText onClick={handleSignupClicked}>Sign Up </AnchorText>
            </div>
       
      </form>
    </div>
  );
};

export default Login;
