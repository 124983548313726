import React from 'react';
import { Spinner } from 'react-bootstrap';
import './spinner.css'; // Add CSS for blur effect

const LoaderSpinner = ({ show }) => {
  return (
    <div className={`loader-container ${show ? 'show' : ''}`}>
      <div className="loader-overlay"></div>
      <div className="loader-content">
      <Spinner animation="grow" variant="light" />
      </div>
    </div>
  );
};

export default LoaderSpinner;
