import React from 'react';
import './card.css'
import Title from '../../../Typorgraphy/Title';
import redArrow from '../../../../assets/icons/redArrow.svg'
import greenArrow from '../../../../assets/icons/greenArrow.svg'
import chartRed from '../../../../assets/icons/chartRed.svg'
import chartGreen from '../../../../assets/icons/chartGreen.svg'

const Card = ({ title, text,content,percentage,type }) => {
  return (
    <div style={{ borderRadius: '10px', border: '1px solid #EAECF0', padding: '20px', margin: '10px', width: '95%',marginBottom:'20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <p className="section-title" style={{color:'#101828',marginBottom:'5px'}}>{title}</p>
      
      </div>
      <Title style={{margin:'5px'}}>{content}</Title>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{fontWeight:500}}><img alt='arrow' src ={type === 'success' ? greenArrow : redArrow} style={{marginRight:'10px'}} /><span>{percentage}</span> {text}</p>
        <img alt='graph' src ={type === 'success' ? chartGreen : chartRed}  style={{ maxWidth: '100px', maxHeight: '100px',marginTop:'-50px' }} />
      </div>
    </div>
  );
}

export default Card;
