import axios from 'axios';


const path = '/auth/user';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
  URL,
  headers: {
    'Content-Type': 'application/json',
  },
 
});

export const login = async (credentials) => {
  try {
    const response = await authApi.post(URL+'/login', credentials);
    return response.data;
  } catch (error) {
    throw error?.response?.data || error.message;
  }
};

export const register = async (userData) => {
  try {
    const response = await authApi.post(URL+'/register', userData);
    return response.data;
  } catch (error) {
    throw error?.response?.data || error.message;
  }
};

export const getDeal = async (token) => {
  try {
    console.log(token)
      const response = await authApi.get(URL + '/get-deal',{
        params: {
          token: token,
        },
      });
      return response?.data;
  } catch (error) {
      console.log(error)
      return null
      // throw error?.response?.data || error.message;
  }
};

export const acceptInvite = async (userData) => {
  try {
    const response = await authApi.post(URL+'/accept-invite', userData);
    return response.data;
  } catch (error) {
    throw error?.response?.data || error.message;
  }
};

export const acceptDealInvite = async (userData) => {
  try {
    const response = await authApi.post(URL+'/accept-deal-invite', userData);
    return response.data;
  } catch (error) {
    throw error?.response?.data || error.message;
  }
};

export default authApi;