import React from 'react';
import './radio.css';
function CustomRadioButton({ id, name, label, value, checked, onChange }) {
  return (
    <div className="custom-radio">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
       
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default CustomRadioButton;
