import React, { useState, useRef } from "react";
import Button from "../../../components/Button";
import Title from '../../../components/Typorgraphy/Title'
import Paragraph from "../../../components/Typorgraphy/Paragraph";
import { useNavigate } from "react-router-dom";
import Papa from 'papaparse'; // Import PapaParse library
import './style.css';
import uploadImage from '../../../assets/images/upload.svg'
import FileIcon from '../../../assets/icons/FileIcon.svg'
import progressBar from '../../../assets/icons/progress.svg'
import Swal from 'sweetalert2'
import Link from 'react-router-dom'
import deleteIcon from '../../../assets/icons/delete.svg'
// import { uploadCSVFile } from '../../../api/csv'
import loanOptionsData from '../../../assets/json/loanTypes.json';
import TextField from "../../../components/TextField";
import { Col, Row } from "react-bootstrap";
import Label from "../../../components/Typorgraphy/Label";
import LoaderSpinner from '../../../components/Spinner';
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const CSVUpload = () => {
    const [uploadedFiles,setUploadedFiles] = useState([])
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [loading,setLoading] = useState(false)
    const [portfolioName, setPortfolioName] = useState('');
    const [portfolioDescription, setPortfolioDescription] = useState('');
    const [selectedLoan, setSelectedLoan] = useState('Term loans');
    const [portfolioImageDataURL, setportfolioImageDataURL] = useState(null)

    let totalSize = 0, fileNames ="";

    let fileArray = []

    if (file){
        for (let f of file){
            totalSize+= f.size;
             
            if (fileNames === "") {
                fileNames+= f.name
            } else {
                fileNames = `${fileNames}, ${f.name}`
            }
        }
        fileArray =  Object.values(file);
    }

    const handleFileChange = (e) => {

        console.log(e.target.files)
        const selectedFile = e.target.files;
        setFile(selectedFile);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files;
        setFile(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleUploadButtonClick = () => {
        sendEventToCustomerIO(eventNames.onboarding, {
            eventType: eventTypes.buttonClicked,
            location: "Add Portfolio Page",
            buttonName: 'Upload File'
        })
        fileInputRef.current.click();
    };

    const handleSelectChange = (e) => {
        setSelectedLoan(e.target.value);
    };

    const handleCancelFile = (name) => {
        const filteredFiles = Array.from(file).filter(file => file.name !== name);
        setFile(filteredFiles); 

    };

    function trimArrayToMax40(array) {
        if (array.length > 40) {
            return array.slice(0, 40);
        } else {
            return array;
        }
    }

    const formatFileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const convertFileToJsonAndSave = async (file) => {
    //     var jsonData;
    //    // localStorage.setItem('CSVData', null)
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //         const content = event.target.result;
    //          jsonData = csvToJson(content);
    //          if(jsonData !== null){
    //             //localStorage.setItem('CSVData', JSON.stringify(jsonData));
    //             navigate('/dashboard/settings?tab=1',{ state: { csvData:trimArrayToMax40(jsonData) }})
    //          }
    //     };
    //     reader.readAsText(file);

    // const formData = new FormData();
    // formData.append('file', file);
    // formData.append('name', "name");

    setLoading(true)

    const csvDataArray = [];
    for (let i = 0; i < file.length; i++) {
        const currentFile = file[i];
        const csvData = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                const jsonData = csvToJson(content);
                if (jsonData !== null) {
                    // resolve(trimArrayToMax40(jsonData));
                    resolve(jsonData)
                } else {
                    reject(new Error('Failed to parse CSV data'));
                }
            };
            reader.readAsText(currentFile);
        }).catch(error => {
            console.error('Error:', error);
            return null;
        });
        
        if (csvData !== null) {
            csvDataArray.push(csvData);
        }
    }

    const portfolioData = {
        name: portfolioName,
        description: portfolioDescription,
        loanType: selectedLoan,
        portfolioImage: portfolioImageDataURL,
        listed: true
    }
    console.log(portfolioData)
    // const jsonData = await uploadCSVFile(portfolioData,file)
    setLoading(false)

    // console.log({jsonData})

    // if (jsonData !== null) {
        //localStorage.setItem('CSVData', JSON.stringify(jsonData));
        // navigate('/dashboard/marketPlace', { state: { csvData: jsonData.result} })
        navigate('/dashboard/settings?tab=1', { state: { csvData: csvDataArray, source: "portfolio", uploadData: { portfolioData, file } } });
    // }

    // fetch('http://localhost/api/csv/upload', {
    //   method: 'POST',
    //   body: formData,
    //   headers: {
    //     // Add any additional headers, such as authorization, if needed
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlYXQiOjE3MDkyMTk1MzYsImVtYWlsIjoic2FmaS5maXZlc0BnbWFpbC5jb20iLCJpYXQiOjE3MDkyMTc3MzYsImlkIjoyLCJyb2xlIjoyLCJ1c2VybmFtZSI6InNhZmkgc2FsZWVtIn0.RS1vI31AwqUVjIhXyRCLimirX5ylxB71gce91L3BSdQ'
    //   },
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle the response data as needed
    //     console.log(data);
    //   })
    //   .catch(error => {
    //     console.error('Error uploading CSV:', error);
    //   });

       
    };

    const csvToJson = (csvContent) => {
        const { data, errors } = Papa.parse(csvContent, { header: true,skipEmptyLines:true }); // Parse CSV with PapaParse
        if (errors.length) {
            console.error("Invalid CSV File/Data !", errors);
            let errorString = errors.map((error,index) =>{
                return `Row # ${error.row}   ${error.message}`
            }).join("\n")
            Swal.fire({
                title: 'Invalid CSV File/Data !',
                animation:true,
                text: errorString,
              })
            return null;
        }
        return data;
    };

    const handlePorfolioImageInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = 180; // Adjust width as needed
              canvas.height = 180; // Adjust height as needed
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const imageDataURL = canvas.toDataURL('image/jpeg', 0.7); // Adjust compression quality
              // imageDataURL is now a string representing the compressed image data
              // You can store this string in your database
              console.log(imageDataURL);
              setportfolioImageDataURL(imageDataURL)
            };
          };
          reader.readAsDataURL(file);
        }
      };

    return (
        <div>
            <LoaderSpinner show={loading} />
            <Title>Add New Portfolio</Title>
            <br/>

            <p className="section-title" style={{color:'#101828'}}>Upload Portfolio</p>
            <Paragraph>Select or drop a new Portfolio for review</Paragraph>

            <div className="file-upload-container">
                <div className="file-upload-box" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <img  src={uploadImage} alt="Upload Icon" className="upload-icon" />
                    <br/>
                    <Paragraph><b>Select a file for Portfolio CSV upload</b> <br/>Or drag and drop here</Paragraph>
                    <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
                    <Button onClick={handleUploadButtonClick} label='Upload File '/>
                </div>
            </div>

            {file && (
                <>
                    <div className="file-details">
                    {
                    fileArray.map(f=>{
                        return <div>
                            <p className="file-label"><img alt="file icon" src={FileIcon} style={{marginRight:'10px'}}/>{f.name} - <img src={deleteIcon} onClick={() => {handleCancelFile(f.name)}} alt="delete file" style={{cursor:'pointer'}}  /></p>
                            {/* <Button  type="button" className="btn-close"/> */}
                        </div>
                    })}
                        <p>{formatFileSize(totalSize)}</p>
                        <img alt='progressbar' src={progressBar} />
                    </div>
                    <br/>
                    <div className="file-details" style={{padding:'20px'}}>

                    <form onSubmit={(e) => e.preventDefault()}>

                <Row>
                    <Col lg={6} md={6} xs={12}>
                    <Label>Portfolio Name:</Label>
                        <TextField name="portfolioName" placeholder='Portfolio Name' value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} />
                    </Col>
                    <Col lg={6} md={6} xs={12}>
                    <Label>Select a Loan Type:</Label>
                        <select className="form-select" id="loanSelect" value={selectedLoan} onChange={handleSelectChange}>
                            <optgroup label="Business Loans">
                                {loanOptionsData.businessLoans.map((loan, index) => (
                                    <option key={index} value={loan}>{loan}</option>
                                ))}
                            </optgroup>
                            <optgroup label="Consumer Loans">
                                {loanOptionsData.consumerLoans.map((loan, index) => (
                                    <option key={index} value={loan}>{loan}</option>
                                ))}
                            </optgroup>
                        </select>
                    </Col>
                    </Row>
                    <br/>
                    <Row>

                    <Col lg={6} md={12} xs={12}>
                        <Label>Portfolio Description:</Label>
                        <textarea
                            id="portfolioDescription"
                            value={portfolioDescription}
                            className="form-control"
                            onChange={(e) => setPortfolioDescription(e.target.value)}
                            required
                        />
                    </Col>

                    <Col lg={12} md={12} xs={12} style={{marginTop: '20px'}}>
                    <Label>Portfolio Image</Label>
                    <input type="file" accept="image/*" onChange={(e) => handlePorfolioImageInputChange(e)} />
                    </Col>

                    {portfolioImageDataURL && (
                        <Row style={{ marginTop: '20px' }}>
                            <Col xs={12}>
                                <img src={portfolioImageDataURL} alt="Portfolio Image" />
                            </Col>
                        </Row>
                    )}


                </Row>
            </form>
            </div>
                    <br/>
                    <Button onClick={() => {
                        convertFileToJsonAndSave(file);
                        // Any other logic you want to execute before continuing
                    }} label='Continue'/>
                    <br/>
                </>
            )}

            {/* Progress bar and label goes here */}
        </div>
    );
}

export default CSVUpload;
