import React, { useState, useRef, useEffect } from "react";
import Button from "../../../components/Button";
import Title from '../../../components/Typorgraphy/Title'
import Paragraph from "../../../components/Typorgraphy/Paragraph";
import { useNavigate } from "react-router-dom";
import Papa from 'papaparse'; // Import PapaParse library
import './style.css';
import uploadImage from '../../../assets/images/upload.svg'
import FileIcon from '../../../assets/icons/FileIcon.svg'
import progressBar from '../../../assets/icons/progress.svg'
import Swal from 'sweetalert2'
import Link from 'react-router-dom'
import { useLocation } from "react-router-dom";

const CSVUpload = () => {
    const location = useLocation();
    const [uploadedFiles,setUploadedFiles] = useState([])
    const navigate = useNavigate();
    const [skyflow_id,setSkyFlowId] = useState('')
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        setFile(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    function trimArrayToMax40(array) {
        if (array.length > 40) {
            return array.slice(0, 40);
        } else {
            return array;
        }
    }

    const formatFileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const convertFileToJsonAndSave = async (file) => {
        var jsonData;
       // localStorage.setItem('CSVData', null)
        const reader = new FileReader();
        reader.onload = (event) => {
            const content = event.target.result;
             jsonData = csvToJson(content);
             if(jsonData !== null){
                //localStorage.setItem('CSVData', JSON.stringify(jsonData));
                // navigate('/dashboard/settings?tab=1',{ state: { csvData:trimArrayToMax40(jsonData), csvId: skyflow_id}})
                navigate('/dashboard/settings?tab=1',{ state: { csvData:jsonData, csvId: skyflow_id}})
             }
        };
        reader.readAsText(file);

    // fetch('http://localhost/api/csv/upload', {
    //   method: 'POST',
    //   body: formData,
    //   headers: {
    //     // Add any additional headers, such as authorization, if needed
    //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlYXQiOjE3MDkyMTk1MzYsImVtYWlsIjoic2FmaS5maXZlc0BnbWFpbC5jb20iLCJpYXQiOjE3MDkyMTc3MzYsImlkIjoyLCJyb2xlIjoyLCJ1c2VybmFtZSI6InNhZmkgc2FsZWVtIn0.RS1vI31AwqUVjIhXyRCLimirX5ylxB71gce91L3BSdQ'
    //   },
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     // Handle the response data as needed
    //     console.log(data);
    //   })
    //   .catch(error => {
    //     console.error('Error uploading CSV:', error);
    //   });

       
    };

    const csvToJson = (csvContent) => {
        const { data, errors } = Papa.parse(csvContent, { header: true,skipEmptyLines:true }); // Parse CSV with PapaParse
        if (errors.length) {
            console.error("Invalid CSV File/Data !", errors);
            let errorString = errors.map((error,index) =>{
                return `Row # ${error.row}   ${error.message}`
            }).join("\n")
            Swal.fire({
                title: 'Invalid CSV File/Data !',
                animation:true,
                text: errorString,
              })
            return null;
        }
        return data;
    };

    useEffect(()=>{
        const skyflow_id = location?.state?.skyflow_id
        setSkyFlowId(skyflow_id) // skyflow id saved in hook 
        console.log("Skyflow ID ",skyflow_id)
    },[])

    return (
        <div>
            <Title>Set Format </Title>
            <br/>

            <p className="section-title" style={{color:'#101828'}}>Upload CSV Format </p>
            <Paragraph>Select or drop a new CSV Format File for review</Paragraph>

            <div className="file-upload-container">
                <div className="file-upload-box" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <img  src={uploadImage} alt="Upload Icon" className="upload-icon" />
                    <br/>
                    <Paragraph><b>Select a file for  CSV Format upload</b> <br/>Or drag and drop here</Paragraph>
                    <input ref={fileInputRef} type="file" accept=".csv" onChange={handleFileChange} className="file-input" />
                    <Button onClick={handleUploadButtonClick} label='Upload File '/>
                </div>
            </div>

            {file && (
                <>
                    <div className="file-details">
                        <p className="file-label"><img alt="file icon" src={FileIcon} style={{marginRight:'10px'}}/>Selected file: {file.name}</p>
                        <p>{formatFileSize(file.size)}</p>
                        <img alt='progressbar' src={progressBar} />
                    </div>
                    <br/>
                    <Button onClick={() => {
                        convertFileToJsonAndSave(file);
                        // Any other logic you want to execute before continuing
                    }} label='Continue'/>
                </>
            )}

            {/* Progress bar and label goes here */}
        </div>
    );
}

export default CSVUpload;
