

import React from 'react';
import './Dashboard.css'; // Import custom CSS for styling
import Sidebar from '../../components/Sidebar';
import { Outlet } from 'react-router-dom';
const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="sidebar">
       <Sidebar/>
      </div>
      <div className="page-content">
       <Outlet/>
      </div>
    </div>
  );
};

export default Dashboard;
