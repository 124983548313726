import React, { useCallback } from 'react';
import { ReactFlow, ReactFlowProvider, addEdge, useNodesState, useEdgesState, Background, Controls, MiniMap } from '@xyflow/react';
import '@xyflow/react/dist/style.css';

const Sidebar = ({ fields, operations }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside>
      {/* <div className="description">Drag these nodes to the pane on the right.</div> */}
      <div className="section">
        <h4>Input Fields </h4>
        <div className="scrollable">
          {fields.map((field, index) => (
            <div key={index} className="dndnode field" onDragStart={(event) => onDragStart(event, field)} draggable>
              {field}
            </div>
          ))}
        </div>
      </div>
      <div className="section">
        <h4>Operations</h4>
        <div className="scrollable">
          {operations.map((operation, index) => (
            <div key={index} className="dndnode operation" onDragStart={(event) => onDragStart(event, operation)} draggable>
              {operation}
            </div>
          ))}
        </div>
      </div>
    </aside>
  );
};

const FlowComponent = ({ customFieldName, fields, operations }) => {
  const initialNodes = [
    {
      id: 'customField',
      type: 'default',
      position: { x: 250, y: 250 },
      data: { label: customFieldName },
      style: { backgroundColor: 'green',color:'white' },
    },
  ];
  const initialEdges = [];
  
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const isOperation = (type) => ['addition', 'subtraction', 'multiplication', 'average'].includes(type);

  const onConnect = useCallback((params) => {
    const sourceNode = nodes.find(node => node.id === params.source);
    const targetNode = nodes.find(node => node.id === params.target);

    if (targetNode.id === 'customField' && edges.some(edge => edge.target === 'customField')) {
      return;
    }

    if ((isOperation(sourceNode.data.label.toLowerCase()) && targetNode.id === 'customField') || 
        (!isOperation(sourceNode.data.label.toLowerCase()) && isOperation(targetNode.data.label.toLowerCase())) ||
        (isOperation(sourceNode.data.label.toLowerCase()) && isOperation(targetNode.data.label.toLowerCase()))) {
      setEdges((eds) => addEdge(params, eds));
    }
  }, [edges, nodes, setEdges]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();

    const reactFlowBounds = event.target.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };

    const newNode = {
      id: `${type}_${new Date().getTime()}`,
      type: isOperation(type) ? 'operation' : 'default',
      position,
      data: { label: `${type.charAt(0).toUpperCase() + type.slice(1)}` },
      style: isOperation(type) ? { backgroundColor: 'lightblue' } : {},
    };

    setNodes((nds) => nds.concat(newNode));
  }, [setNodes]);

  const onLoad = useCallback((reactFlowInstance) => {
    reactFlowInstance.fitView({ padding: 0.2 });
  }, []);

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <Sidebar fields={fields} operations={operations} />
        <div className="reactflow-wrapper" style={{ height: 600 }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onLoad={onLoad}
            fitView
          >
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

const App = () => {
  const fields = ['Field1', 'Field2', 'Field3', 'Field4', 'Field5', 'Field6'];
  const operations = ['addition', 'subtraction', 'multiplication', 'average','aggregation'];
  const customFieldName = 'ResultField';

  return <FlowComponent customFieldName={customFieldName} fields={fields} operations={operations} />;
};

export default App;

// Add the CSS in the same file for simplicity
const css = `
.dndflow {
  display: flex;
}

aside {
  width: 200px;
  padding: 10px;
  border-right: 1px solid #ddd;
  background: #f7f7f7;
  overflow: auto; /* Make sidebar scrollable */
}

.description {
  margin-bottom: 10px;
}

.section {
  margin-bottom: 20px;
}

.scrollable {
  max-height: 250px; /* Adjust based on your needs */
  overflow-y: auto;
}

.dndnode {
  padding: 5px;
  margin-bottom: 5px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: grab;
}

.dndnode.field {
  border-radius: 5px;
}

.dndnode.operation {
  border-radius: 0%;
  background: lightblue;
}

.reactflow-wrapper {
  flex: 1;
  height: 100%;
}
`;

// Inject the CSS into the document
const style = document.createElement('style');
style.textContent = css;
document.head.append(style);
