import React, { useState, useEffect } from 'react';

import Button from '../../../components/Button';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import TextField from '../../../components/TextField';
import Title from '../../../components/Typorgraphy/Title';
import Label from '../../../components/Typorgraphy/Label';
import '../../../assets/css/App.scss'
import { useNavigate, useLocation } from 'react-router-dom';
import keyIcon from '../../../assets/icons/key.svg';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import backgroundImage from '../../../assets/images/bg2.png';
import ReactCodeInput from 'react-code-input'
import { requestOTP } from '../../../api/onboarding';


const VerifyOTPPage = ({onSubmitOTP}) => {
    const navigate = useNavigate();
    const query = useQuery();
    const [otp, setOTP] = useState('');
    const [key, setKey] = useState('');


const handleSubmit = (e) => {
    onSubmitOTP(otp)
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  async function requestAnotherCode(){
    await requestOTP();
  }

  useEffect(() => {
    const paramValue = query.get('otp');
    if (paramValue) {
      setOTP(paramValue);
      setKey(1)
    }
  }, []);

  return (
   <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.centered}>
      
      


 <div style={{ display: 'flex', justifyContent: 'center', height: '100vh',background:'transparent',marginTop:'150px' }}>
      <form  onSubmit={handleSubmit} style={{ width: '350px' }}>
      
      <div className="text-center mb-3">
            <img src={keyIcon} alt="Logo" className='icon-border' />
          </div>
        <Title style={{textAlign:'center'}}>Verify Email</Title>
        <Paragraph style={{ textAlign: 'center' }}>
          Please check your email for the verification link.{' '}
          <span
            onClick={requestAnotherCode}
            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
          >
            Click here
          </span>
          {' '}to request another code.
        </Paragraph>
        
        <div style={{ marginBottom: '20px', marginTop: '35px' }}>
          <ReactCodeInput key={key} placeholder={otp} onChange={(v) => setOTP(v)} value={otp} type='text' fields={4} />
        </div>
      
        <Button style={{width:'100%',marginBottom:'30px'}} label={"Verify Email"} onClick={handleSubmit}></Button>
        <div className="d-flex align-items-center" style={{textAlign:'center',justifyContent:'center',justifyItems:'center'}}>
             
           
              <Label style={{cursor:'pointer',fontWeight:'600'}} onClick={()=> navigate('/login')}>
                <img src ={arrowLeft} alt="arrow" style={{marginRight:'5px'}}/>
                Back to log in </Label>
        </div>
       
      </form>
    </div>








      </div>
    </div>
    </div>
  );
};

const styles = {
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'contain',
        backgroundPosition: 'center top',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    
   
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  centered: {
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
  },
};

export default VerifyOTPPage;
