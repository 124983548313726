import React from 'react';
import './ThankYou.css';

const ThankYou = () => {

  return (
    <div className="thank-you-container">
      <div className="thank-you-card">
        <h1 className="thank-you-title">Thank You!</h1>
        <p className="thank-you-message">
          Thank you for submitting your deal. Please check your email for further instructions.
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
