import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paragraph from '../../Typorgraphy/Paragraph'
import Label from '../../Typorgraphy/Label'
import TextField from "../../TextField";
import AnchorText from '../../Typorgraphy/AnchorText'
import Switch from "../../Switch";
import loanPro from '../../../assets/images/LMS/loanpro.png'
import peachFinance from '../../../assets/images/LMS/peach.png'
import fusionFabric from '../../../assets/images/LMS/fusionfabric.png'
import marqeta from '../../../assets/images/LMS/marqeta.jpeg'
import lithic from '../../../assets/images/LMS/lithic.jpeg'
import Placeholder from 'react-bootstrap/Placeholder';
import Modal from '../../Modal'
import Button from "../../Button";
import { getLithicToken, createLithicIntegration } from '../../../api/lithic';
import LoaderSpinner from '../../../components/Spinner';


const Integrations = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [modalShow, setModalShow] = useState(false);
    const [currentService, setCurrentService] = useState(null)
    const [privateKey, setPrivateKey] = useState('')
    const [searchText, setSearchtext] = useState('');
    const handleModalClick = (serviceIndex) => {
        setCurrentService(serviceIndex)
        setModalShow(true)
    }

    const handleSwitchChange = (serviceIndex) => {
        setCurrentService(serviceIndex)
        if (externalLMSServices[serviceIndex].status === 0) {
            setModalShow(true)
        }
    }


    const [externalLMSServices, setExternalLMSServices] = useState([
        { id: 0, name: 'LoanPro.io', description: 'Building the future of finance', status: 0, image: loanPro, keys: { public: '999', private: '' } },
        { id: 1, name: 'Peach Finance ', description: 'Scale innovative credit products. ', status: 0, image: peachFinance, keys: { public: '', private: '' } },
        { id: 2, name: 'Fusion Fabric  ', description: 'Innovate for the future of finance', status: 0, image: fusionFabric, keys: { public: '', private: '' } },
        { id: 3, name: 'Marqeta', description: `World's first modern card issuing platform`, status: 0, image: marqeta, keys: { public: '', private: '' } },
        { id: 4, name: 'Lithic', description: 'Card Issuing Platform .', status: 0, image: lithic, keys: { public: '', private: '' } }
    ]);

    useEffect(() => {
        currentService !== null ? setModalShow(true) : setModalShow(false)
    }, [currentService])

    async function fetchData() {
        const response = await getLithicToken()
        const lithicAuthToken = response?.lithicAuthToken;

        if (lithicAuthToken) {
            const updated = [...externalLMSServices];
            updated[4] = { ...updated[4], status: 1 };

            setExternalLMSServices(updated)
        }
    }

    const handlePrivateKeyChange = (event) => {
        setPrivateKey(event.target.value);
    };

    const handleModalOnHide = (event) => {
        setModalShow(false)
        fetchData();
    };

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        fetchData();

    }, [])
    
    return (
        <>

        <LoaderSpinner show={loading} />;

            <Row>
                <Col xs={6}>
                    <p className="section-title" style={{ color: '#101828' }}>Connect External LMS</p>
                    <Paragraph>Connect your external LMS to the platform to import data.</Paragraph>
                </Col>
                <Col xs={2}>
                </Col>
                <Col xs={4}>
                    <TextField className='float-right' style={{ padding: '10px', fontSize: '14px', lineHeight: '10px', width: '80%', marginTop: '20px', float: 'right' }} value={searchText} onChange={(e) => { setSearchtext(e.target.value) }} placeholder="Search" type="text" />
                    <Button onClick={() => { navigate('/dashboard/add-portfolio') }} style={{ marginTop: '10px', float: 'right' }} label='Upload Custom Portfolio' />
                </Col>
            </Row>
            <hr />

            <Row>
                {!loading ? externalLMSServices.map((service, index) => {
                    return (
                        <Col key={index} lg={4} md={4}>
                            <div className='connection-card'>
                                <div className="upper">
                                    <img src={service.image} alt="service-ico" />
                                    <span className="card-title">{service.name}</span>
                                </div>
                                <div className="switch-container" style={{ float: 'right', marginRight: '10px', marginTop: '15px' }}>
                                    <Switch checked={service.status} onChange={() => handleSwitchChange(index)} />
                                </div>

                                <div className="description" style={{ marginTop: '90px', marginLeft: '15px' }}>
                                    <Paragraph>{service.description}</Paragraph>
                                </div>

                                <div className="footer" style={{ borderTop: '1px solid #EAECF0', paddingTop: '20px', marginBottom: '30px' }}>
                                    <AnchorText onClick={() => handleModalClick(index)} style={{ float: 'right', marginRight: '15px' }}>View Integration</AnchorText>
                                </div>
                            </div>
                        </Col>
                    )
                }) : Array.from({ length: 5 }, (index) => {
                    return (<Col key={index} lg={4} md={4}>
                        <div className='connection-card'>
                            <Placeholder as="p" animation="glow"> <Placeholder xs={12} size="lg" /> </Placeholder>
                            <Placeholder as="p" animation="wave"> <Placeholder style={{ width: '70%' }} xs={12} size="sm" /> </Placeholder>
                            <Placeholder as="p" animation="glow"> <Placeholder style={{ width: '50%' }} xs={12} size="sm" /> </Placeholder>
                        </div>
                    </Col>)
                })
                }
            </Row>
            <Modal show={modalShow} privateKey={privateKey} createLithicIntegration={createLithicIntegration} onHide={() => handleModalOnHide()} image={externalLMSServices[currentService]?.image || ''} style={{ padding: '20px' }}>
                <span className="card-title">Connect with your API Key</span>
                <Paragraph>This is your key for accessing the API, remember it’s just for you. By generating your API key, you agree to the Terms of use.</Paragraph>
                {/* <div style={{marginTop:'30px'}}>
                <Label>Public KEY</Label>
                <TextField   placeholder='Enter your public key '></TextField>
            </div> */}
                <div style={{ marginTop: '30px' }}>
                    <Label>Private Key</Label>
                    <TextField placeholder='Enter your Private KEY' value={privateKey} onChange={handlePrivateKeyChange}></TextField>
                </div>
            </Modal>
        </>
    );
}

export default Integrations;