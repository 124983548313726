import './style.css'
import React, { useState } from 'react';

const Switch = ({ checked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);
    
    // Invoke the onChange callback with the new checked value
    if (onChange) {
      onChange(newCheckedValue);
    }
  };

  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <span className="slider"></span>
    </label>
  );
};

export default Switch;