import React, { useState } from 'react';
import { storeQuestionnaire } from '../../../api/onboarding';
import Header from './Header';
import LoaderSpinner from '../../../components/Spinner';
import Button from '../../../components/Button';
import { Container, Row, Col } from 'react-bootstrap';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Label from '../../../components/Typorgraphy/Label';
import MultiSelectDropdown from './MultiSelectDropdown';
import { toast } from "sonner";
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';
const BuyerQuestionnaireForm = ({onSubmit}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    investmentStrategy: '',
    geographicFocus: [],
    industryFocus: '',
    assetClassType: [],
    borrowerType: '',
    dealSizeRange: '',
    returnProfile: '',
    fundDuration: '',
    riskProfile: '',
    aum: '',
    esgConsiderations: [],
    investorRequirements: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.checkboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: name,
      value: value
    })

    if (type === 'checkbox') {
      const updatedValues = checked
        ? [...formData[name], value]
        : formData[name].filter(item => item !== value);

      setFormData({ ...formData, [name]: updatedValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleGeographicFocusChange = (selectedOptions) => {
    
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.checkboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: "geographicFocus",
      value: selectedOptions
    })

    setFormData((prevState) => ({
      ...prevState,
      geographicFocus: selectedOptions,
    }));
  };

  const handleAssetClassTypeChange = (selectedOptions) => {

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.checkboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: "assetClassType",
      value: selectedOptions
    })

    setFormData((prevState) => ({
      ...prevState,
      assetClassType: selectedOptions,
    }));
  };

  const handleEsgConsiderationsChange = (selectedOptions) => {

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.checkboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: "esgConsiderations",
      value: selectedOptions
    })


    setFormData((prevState) => ({
      ...prevState,
      esgConsiderations: selectedOptions,
    }));
  };

  const geographicFocusOptions = [
    'North America',
    'Europe',
    'Asia',
    'Australia',
    'LATAM',
    'Middle East & Africa',
    'Global',
  ];
  const handleSubmit = async (e) => {

    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Questionnaire",
      buttonName: 'Continue'
    })
    
    e.preventDefault();
    console.log(formData);
  
    // Check if all required fields are set
    
    const requiredFields = [
      'investmentStrategy',
      'geographicFocus',
      'industryFocus',
      'assetClassType',
      'borrowerType',
      'dealSizeRange',
      'returnProfile',
      'fundDuration',
      'riskProfile',
      'aum',
      'esgConsiderations',
      'investorRequirements'
    ];
  
    const missingFields = requiredFields.filter(field => {
      const value = formData[field];
      return value === undefined || (Array.isArray(value) && value.length === 0);
    });
  
    if (missingFields.length > 0) {
      console.error('Please fill out all required fields:', missingFields);
      toast.error('Please fill out all required fields');
      return;
    }
  
    const resultArray = [];
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const value = formData[key];
        let answer;
  
        if (Array.isArray(value)) {
          answer = value.join(', '); 
        } else {
          answer = value.toString(); 
        }
  
        resultArray.push({ question: key, answer });
      }
    }
  
    try {
      setLoading(true)
      await storeQuestionnaire(resultArray);
      await onSubmit();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form');
    }
    finally{
      setLoading(false)
    }
  };

  return (
    <div>
      <LoaderSpinner show={loading} />

      <Header title='Questionnaire' description='Please fill in the questionnaire below'/>

      <Container>
      <div style={{backgroundColor:'white',height:'auto',width:'80%',marginLeft:'auto',marginRight:'auto',border :'1px solid #EAECF0',borderRadius:'12px',marginBottom:'15px',marginTop:'15px',padding:'20px'}} >
       {/* Body */}

      <Row>
        <Col xs={12} md={4}>
          <Label htmlFor="investmentStrategy">Primary investment strategy :</Label>
          <select className='form-select' id="investmentStrategy" name="investmentStrategy" value={formData.investmentStrategy} onChange={handleInputChange}>
            <option value="directLending">Direct lending</option>
            <option value="mezzanine">Mezzanine</option>
            <option value="distressedDebt">Distressed debt</option>
            <option value="specialSituations">Special situations</option>
            <option value="ventureDebt">Venture debt</option>
            <option value="other">Other (please specify)</option>
          </select>
        </Col>

        <Col xs={12} md={4}>
          <Label>Geographic focus :</Label>
          <MultiSelectDropdown
            options={geographicFocusOptions}
            selectedOptions={formData.geographicFocus}
            setSelectedOptions={handleGeographicFocusChange}
            title="Select Geographic Focus" />
        </Col>

        <Col xs={12} md={4}>
          <Label htmlFor="industryFocus">Industry focus :</Label>
          <select className='form-select' id="industryFocus" name="industryFocus" value={formData.industryFocus} onChange={handleInputChange}>
          <option value="">Select...</option>
          <option value="generalist">Generalist</option>
          <option value="technology">Technology</option>
          <option value="healthcare">Healthcare</option>
          <option value="energy">Energy</option>
          <option value="realEstate">Real Estate</option>
          <option value="infrastructure">Infrastructure</option>
          <option value="consumer">Consumer</option>
          <option value="industrials">Industrials</option>
          <option value="financialServices">Financial Services</option>
          <option value="mediaEntertainment">Media and Entertainment</option>
          <option value="otherIndustry">Other</option>
          </select>
        </Col>
      </Row>


      <Row style={{marginTop:'40px'}}>
        <Col xs={12} md={4}>
          <Label>Asset class type :</Label>
          <MultiSelectDropdown
            options={['Senior secured loans', 'Unsecured loans', 'Subordinated debt', 'Convertible debt', 'Preferred equity', 'Common equity', 'Royalties', 'Other']}
            selectedOptions={formData.assetClassType}
            setSelectedOptions={handleAssetClassTypeChange}
            title="Select Asset Class Type" />
          </Col>

          <Col xs={12} md={4}>
            <Label htmlFor="borrowerType">Borrower type :</Label>
            <select className='form-select' id="borrowerType" name="borrowerType" value={formData.borrowerType} onChange={handleInputChange}>
            <option value="">Select...</option>
          <option value="b2b">Business-to-Business (B2B)</option>
          <option value="b2c">Business-to-Consumer (B2C)</option>
          <option value="government">Government</option>
          <option value="otherBorrower">Other (please specify)</option>
          </select>
          </Col>


          <Col xs={12} md={4}>
            <Label htmlFor="dealSizeRange">Typical deal size range :</Label>
            <select className='form-select' id="dealSizeRange" name="dealSizeRange" value={formData.dealSizeRange} onChange={handleInputChange}>
              <option value="">Select...</option>
              <option value="lessThan10m">Less than $10 million</option>
              <option value="10mto50m">$10 million - $50 million</option>
              <option value="50mto100m">$50 million - $100 million</option>
              <option value="100mto500m">$100 million - $500 million</option>
              <option value="greaterThan500m">Greater than $500 million</option>
          </select>
          </Col>
      </Row>


      <Row style={{marginTop:'40px'}}>
        <Col xs={12} md={4}>
          <Label htmlFor="returnProfile">Target return profile :</Label>
          <select className='form-select' id="returnProfile" name="returnProfile" value={formData.returnProfile} onChange={handleInputChange}>
            <option value="">Select...</option>
            <option value="lessThan5">Less than 5%</option>
            <option value="5to10">5% - 10%</option>
            <option value="10to15">10% - 15%</option>
            <option value="15to20">15% - 20%</option>
            <option value="greaterThan20">Greater than 20%</option>
          </select>
        </Col>


        <Col xs={12} md={4}>
          <Label htmlFor="fundDuration">Fund duration or term :</Label>
          <select className='form-select' id="fundDuration" name="fundDuration" value={formData.fundDuration} onChange={handleInputChange}>
          <option value="">Select...</option>
          <option value="lessThan3">Less than 3 years</option>
          <option value="3to5">3 - 5 years</option>
          <option value="5to7">5 - 7 years</option>
          <option value="7to10">7 - 10 years</option>
          <option value="greaterThan10">Greater than 10 years</option>
          </select>
        </Col>



        <Col xs={12} md={4}>
          <Label htmlFor="riskProfile">Risk profile :</Label>
          <select className='form-select' id="riskProfile" name="riskProfile" value={formData.riskProfile} onChange={handleInputChange}>
          <option value="">Select...</option>
          <option value="conservative">Conservative</option>
          <option value="moderate">Moderate</option>
          <option value="aggressive">Aggressive</option>
          </select>
        </Col>
      </Row>


      <Row style={{marginTop:'40px'}}>
        <Col xs={12} md={4}>
          <Label htmlFor="aum">Assets under management (AUM) :</Label>
          <select className='form-select' id="aum" name="aum" value={formData.aum} onChange={handleInputChange}>
          <option value="">Select...</option>
          <option value="lessThan100m">Less than $100 million</option>
          <option value="100mto500m">$100 million - $500 million</option>
          <option value="500mto1b">$500 million - $1 billion</option>
          <option value="1bto5b">$1 billion - $5 billion</option>
          <option value="greaterThan5b">Greater than $5 billion</option>
          </select>
        </Col>


        <Col xs={12} md={4}>
          <Label>ESG considerations :</Label>
          <MultiSelectDropdown
            options={['Environmental', 'Social', 'Governance', 'None']}
            selectedOptions={formData.esgConsiderations}
            setSelectedOptions={handleEsgConsiderationsChange}
            title="Select ESG Considerations" />
        </Col>


        <Col xs={12} md={4}>
          <Label htmlFor="investorRequirements">Investor requirements :</Label>
          <select className='form-select' id="investorRequirements" name="investorRequirements" value={formData.investorRequirements} onChange={handleInputChange}>
          <option value="">Select...</option>
          <option value="qualifiedInstitutional">Qualified institutional buyers (QIBs) only</option>
          <option value="accreditedInvestors">Accredited investors only</option>
          <option value="openToAll">Open to all investors</option>
          </select>
        </Col>
      </Row>


       {/* Body */}
       </div>
      </Container>


    {/* Footer */}
     <Container>
          <div style={{backgroundColor:'white',borderRadius:'12px',border :'1px solid #EAECF0', padding :'10px',width:'80%',marginLeft:'auto',marginRight:'auto',paddingTop:'15px' }}>
          <Row>
            <Col>
            <Paragraph style={{fontWeight:'500',float:'left', margin: '10px'}}>© CreditCurve 2024</Paragraph>
            </Col>
            <Col>
            <Button label='Continue ' onClick={(e)=> handleSubmit(e)} style={{float:'right',}} />
            </Col>
          </Row>
          </div>
    </Container>
    {/* Footer */}

    </div>
  );
};

export default BuyerQuestionnaireForm;
