import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deletePortfolio, getCSVFormatsById, getCSVsByPorfoliosById, getPermissionRequests, grantPermission, requestPermission } from "../../../../api/csv";
import favouriteIcon from '../../../../assets/icons/favourite.svg';
import LocationIcon from '../../../../assets/icons/location.svg';
import Button from '../../../Button';
import Modal from '../../../Modal1';
import './listing.css';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { toast } from "sonner";
import { getCSVMatch } from '../../../../api/csv';
import uploadIcon from "../../../../assets/icons/uploadFile.svg";
import LoaderSpinner from "../../../Spinner";
import LinkLabel from "../../../Typorgraphy/AnchorText";
import Label from "../../../Typorgraphy/Label";
import Paragraph from "../../../Typorgraphy/Paragraph";
import Title from "../../../Typorgraphy/Title";

const MarketplaceListing = ({skyflow_id,image,title,type,country,description,favourite = false,isOwner = false, hasPermission=false, onDelete}) => {

    const navigate = useNavigate()
    const[showModal,setShowModal] = useState(false)
    const[showModal2,setShowModal2] = useState(false)
    const[showModal3,setShowModal3] = useState(false)
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [formats, setFormats] = useState([])
    const [isRequested,setIsRequested] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const [portfolioCsvs, setPortfolioCsvs] = useState(null);
    
    const [permissionRequests,setPermissionRequests] = useState([
        {
            skyflow_id: "bab7c947-0eb2-4cd1-931c-d881b654809a",
            credit_curve_account_id: "3",
            requester_name: "Talha Bin Shafiq",
            requester_email: "talha1@gmail.com",
            csvid: "9264f91b-b1a0-4dba-b2c1-5431ef1ced45"
        }
    ])

    const [loading,setLoading] = useState(false)

    const givePermission = async(id,e) => {
        e.preventDefault();

        try{
            const data = await grantPermission(id)
            if(data?.status === 200){
                toast.success('Permission Granted !')
            }
            else{
                throw new Error('Failed to Grant Permission !')
            }
            console.log("Permissions Granted Data ",data)
        }
        catch(e){
            console.log("Error Granting Permission !",e)
        }
      
    }

    const getRequestsList = async() => {
       
        try{
            const data = await getPermissionRequests()
            if(data?.status === 200){
                if(data?.data){
                    setPermissionRequests(data.data)
                }
            }
            console.log("Permissions Data ",data)
        }
        catch(e){
            console.log("Error Fetching Permission Requests !",e)
        }
       

    }


    const requestPortfolioPermission = async(id,e) => {
        e.preventDefault();
        setLoading(true)
            try{
                const data = await requestPermission(id)
                if(data?.status === 200)
                {
                    toast.success('Portfolio Permission Requested !')
                }
                else{
                    throw new Error('You Don\'t have permission to view this portfolio. Please request permission.')
                }
                
            } catch(e){
                console.log("Failed to Request Permission !")
                toast.error('Failed to Request Permission !')
            }
            finally{
                setLoading(false)
                setShowModal(false)
            }
        }
    
    const viewPortfolio = async(id,e) => {
        e.preventDefault()
        setLoading(true)

        try{
            const data = await getCSVFormatsById(id,selectedOption)
            if(data?.status === 200)
            {
                // navigate('/dashboard/show-portfolio',{state:{csvData:data?.data?.data}})

            //console.log("Portfolio Data Final Navigation ",data?.data?.data)
            }
            else{
                throw new Error('You Don\'t have permission to view this portfolio. Please request permission.')
            }
            console.log(data)
            
           
        } catch(e){
            console.log("You Don't have permission to view this portfolio. Please request permission.")

            toast(`You Don't have Permission to access this Portfolio`, {
                style:{
                    width:'465px',
                
                },
                classNames:{
                    actionButton:'action-button-class',
                },
                
                action: {
                  label: 'Get Permission',
                  onClick: () => setShowModal(true),
                },
              });
        }
        finally{
            setLoading(false)
            
        }
      }

      const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        setFile(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    async function fetchCSVFormats() {
        const response = await getCSVMatch();

        const result = response.map(res => ({
            id: res.skyflow_id,
            formatName: res.name
        }))

        setFormats(result)
    }

    async function fetchCSVsByPortfolioId() {
        const response = await getCSVsByPorfoliosById(skyflow_id);


        setPortfolioCsvs(response)

    }

    async function removePortfolio(portfolioId) {
        setLoading(true)
        await deletePortfolio(portfolioId)
        getRequestsList()
        fetchCSVFormats();

        onDelete()

        setLoading(false)
    }


    useEffect(() => {
        getRequestsList()

        fetchCSVFormats();
    },[])

  // Event handler for dropdown change
  const handleDropdownChange = (event) => {
    const selectedId = (event.target.value);

    const selectedFormat = formats.find(option => option.id === selectedId);
    setSelectedOption(selectedFormat);
  };

    return(<>
    <LoaderSpinner show={loading} />

    <Row className="listing-card">
       
        <Col xs ={4} className="image-box">
            <img src={image} style={{maxWidth:'180px',borderRadius:'10px'}} alt="listing" className="listing-image"/>
        </Col>
        <Col xs ={8}  className="">

            <p className="text-listing-type">{type}</p>
            <img src={favouriteIcon} className="listing-fav-icon" />
            <span className="text-listing-title">{title}</span>

            <p className="listing-description">{description}</p>
            <img src={LocationIcon} className="listing-location-icon" />
            <span className="listing-description">U.S.A</span>
          
            {isOwner ? 
                <>


                    <Button onClick={() => {
                        setShowModal2(true)
                        fetchCSVsByPortfolioId(skyflow_id)
                    }}
                        variant="default" label="Manage Portfolio"
                        style={{ marginTop: '10px', float: 'right' }} />


                    <Button variant="default" onClick={() => 
                        navigate('/dashboard/listing-details',{ state: { portfolioId: skyflow_id } })
                        } 
                        style={{ marginTop: '10px', marginRight: '10px', float: 'right' }} 
                        label="View Details"/>

                    <Button variant="default" onClick={() =>
                        removePortfolio(skyflow_id)
                    }
                        style={{ marginTop: '10px', marginRight: '10px', float: 'right' }}
                        label="Delete" />

                    
                </>

                 : 
                
                    <>
                        {!hasPermission &&
                            <Button onClick={() => {
                                setShowModal3(true)
                                fetchCSVsByPortfolioId(skyflow_id)
                            }} //{viewPortfolio(skyflow_id,e)}}
                                variant="primary"
                                label="View Portfolio"
                                style={{ marginTop: '10px', float: 'right' }} />
                        }
                        
                        {hasPermission &&
                            <Button variant="default" onClick={() =>
                                navigate('/dashboard/listing-details', { state: { portfolioId: skyflow_id } })
                            }
                                style={{ marginTop: '10px', marginRight: '10px', float: 'right' }}
                                label="View Details" />
                        }


                    </>
            }
        </Col>
    </Row>



    {/* Modal for Buyer Permission Request  */}

    <Modal show={showModal} title="Request Permission" onHide={()=> setShowModal(false)}> 

    <Tabs
      defaultActiveKey="note" id="fill-tab-example" className="mb-3 c-tabs" fill>
      <Tab eventKey="note" title="Add Note" className="tab-item">
        <Title style={{fontSize:'18px',color:'#101828'}}> Add Note </Title>
        <Label >Note/Message * </Label>
        <textarea className="form-control" rows="6"></textarea>
      </Tab>


      <Tab eventKey="signature" title="Sign MNDA" className="tab-item">
        <Title style={{fontSize:'17px',color:'#101828',fontWeight:500}}> Upload & Attach Digital Signature </Title>

        <div className="sign-upload">
            <div className="file-upload-box" onDrop={handleDrop} onDragOver={handleDragOver}>
            <img src={uploadIcon} alt='signature upload'/>
                    <br/>
                    <Paragraph><b>Click to Upload </b> Or drag and drop here</Paragraph>
                    <input ref={fileInputRef} type="file" accept=".csv" onChange={handleFileChange} className="file-input" />
                    <Button variant="default" onClick={handleUploadButtonClick} label='Upload File '/>
                </div>
        </div>

      </Tab>
    </Tabs>

    <Row style={{marginTop:'20px'}}>
        <Col lg={6}><Button onClick={() => setShowModal(false)} label={"Cancel"} style={{width:'100%',textAlign:'center'}} variant='default'> </Button></Col>
        <Col lg={6}><Button type='submit' onClick={(e)=>requestPortfolioPermission(skyflow_id,e)}label={"Request Permission"} style={{width:'100%'}}> </Button></Col>
        </Row>
    
    </Modal>

     {/* Modal for Buyer Permission Request  */}


    {/* Modal for portfolio Owner  */}


    <Modal show={showModal2} title="Manage Portfolio " onHide={()=> setShowModal2(false)} > 

<Tabs
  defaultActiveKey="requests" id="fill-tab-example" className="mb-3 c-tabs" fill>
  <Tab eventKey="requests" title="Requests" className="tab-item">
    <Title style={{fontSize:'18px',color:'#101828'}}>Requests</Title>

    <div className="permissions-box">
        {permissionRequests?.map((request,index) => {
            return( <Row key={index} style={{padding:'10px',marginBottom:'px'}}>
                <Col lg={8}> 
                    <Label style={{fontWeight:600,fontSize:'16px'}}>{request.requester_name}</Label>
                    <Label>{request.requester_email}</Label>
                </Col>
                <Col lg={4}> 
                   <LinkLabel onClick={(e)=> givePermission(request.skyflow_id,e)} style={{float:'right'}}>Allow Access </LinkLabel>
                </Col>
            </Row>)
        })}
       
       
    </div>
   
  </Tab>


  <Tab eventKey="users" title="Users" className="tab-item">
  <Title style={{fontSize:'18px',color:'#101828'}}>Users</Title>

  <div className="permissions-box"></div>

 


  </Tab>

  <Tab eventKey="manage" title="Manage" className="tab-item">
  <Title style={{fontSize:'18px',color:'#101828'}}>Manage Portfolio</Title>


  {portfolioCsvs?.map((csv, index) => (
    <div className="file-details" key={index}>
    <Row>
        <Col lg={12} md={12} xs={12}>
            <Label>File Name: {csv.name}</Label>
        </Col>
        <Col lg={6} md={6} xs={6}>
            <div>
                <select className="form-select" onChange={(e) => handleDropdownChange(e, csv.skyflow_id)} style={{ marginRight: '10px' }}>
                    <option value="">Select Format</option>
                    {formats.map(option => (
                        <option key={option.id} value={option.id}>{option.formatName}</option>
                    ))}
                </select>
            </div>
        </Col>

        <Col lg={6} md={6} xs={6}>
            <Button style={{marginLeft: '100px'}} onClick={(e) => { viewPortfolio(csv.skyflow_id, e) }} variant="primary" label="View data" />
        </Col>
    </Row>
    </div>
))}


  </Tab>
</Tabs>

{/* <Row style={{marginTop:'20px'}}>
    <Col lg={6}><Button onClick={() => setShowModal2(false)} label={"Cancel"} style={{width:'100%',textAlign:'center'}} variant='default'> </Button></Col>
        <Col lg={6}><Button type='submit' onClick={(e)=>requestPortfolioPermission(skyflow_id,e)}label={"Rsequest Permission"} style={{width:'100%'}}> </Button></Col>
    </Row> */}

</Modal>

<Modal show={showModal3} title="View Portfolio " onHide={()=> setShowModal3(false)} > 

<Tabs
  defaultActiveKey="manage" id="fill-tab-example" className="mb-3 c-tabs" fill>

  <Tab eventKey="manage" title="Manage" className="tab-item">
  <Title style={{fontSize:'18px',color:'#101828'}}>Manage Portfolio</Title>


  {portfolioCsvs?.map((csv, index) => (
    <div className="file-details" key={index}>
    <Row>
        <Col lg={12} md={12} xs={12}>
            <Label>File Name: {csv.name}</Label>
        </Col>
        <Col lg={6} md={6} xs={6}>
            <div>
                <select className="form-select" onChange={(e) => handleDropdownChange(e, csv.skyflow_id)} style={{ marginRight: '10px' }}>
                    <option value="">Select Format</option>
                    {formats.map(option => (
                        <option key={option.id} value={option.id}>{option.formatName}</option>
                    ))}
                </select>
            </div>
        </Col>

        <Col lg={6} md={6} xs={6}>
            <Button style={{marginLeft: '100px'}} onClick={(e) => { viewPortfolio(csv.skyflow_id, e) }} variant="primary" label="View data" />
        </Col>
    </Row>
    </div>
))}


  </Tab>
</Tabs>

<Row style={{marginTop:'20px'}}>
    <Col lg={6}><Button onClick={() => setShowModal3(false)} label={"Cancel"} style={{width:'100%',textAlign:'center'}} variant='default'> </Button></Col>
        <Col lg={6}><Button type='submit' onClick={(e)=>requestPortfolioPermission(skyflow_id,e)}label={"Request Permission"} style={{width:'100%'}}> </Button></Col>
    </Row>

</Modal>


    {/* Modal for portfolio Owner  */}


    </>)



}

export default MarketplaceListing;