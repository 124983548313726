import React from "react";
import Title from '../../../components//Typorgraphy/Title'
const Support = () => {

    return (
        <div>
           <Title>Support</Title>
            
        </div>
    );
}

export default Support;