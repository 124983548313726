import './small.css'
import React, { useState } from 'react';
const Switch = ({checked = false }) =>{ 

    const [isChecked, setIsChecked] = useState(checked);
    const handleToggle = () => {
        setIsChecked(!isChecked); // Toggle the isChecked state
    };
    return (
        
        <label className="toggle-switch">
        <input type="checkbox" checked={isChecked} onChange={handleToggle} />
        <span className="slider"></span>
        </label>
)}

export default Switch

    