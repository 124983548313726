import React from "react";
import Title from '../../../components//Typorgraphy/Title'
const Teams = () => {

    return (
        <div>
           <Title>Teams</Title>
            
        </div>
    );
}

export default Teams;