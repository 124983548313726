import React from "react";
import Card from 'react-bootstrap/Card';
import marker from '../../../assets/icons/marker.svg'
import './style.css'
const ListingCard = ({title,img,type,location}) =>{
    return(<>
        <Card className="listing-card">
            <Card.Img variant="top" src={img} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text>
                  <img alt="marker location" src={marker}  style={{marginRight:'10px'}}/>  {location}
                </Card.Text>
            </Card.Body>
        </Card>
    </>)
}

export default ListingCard;