import React,{useState, useEffect} from 'react';
import { Row, Col } from 'react-bootstrap';
import Paragraph from '../../Typorgraphy/Paragraph';
import './style.css';
import CustomRadioButton from '../../RadioButton/index';
import Label from '../../Typorgraphy/Label';
import TextField from '../../TextField';
import masterCard from '../../../assets/icons/mastercard.svg'
import visa from '../../../assets/icons/visa.svg'
import plusIcon from '../../../assets/icons/plus.svg'
import AnchorText from '../../Typorgraphy/AnchorText'
import cardHeading from '../../../assets/icons/cardheading.svg'
import Modal from '../../Modal1';
import DataTable from  '../../DataTable/index'
import checkGreen from '../../../assets/icons/checkgreen.svg'
import downloadIcon from '../../../assets/icons/download.svg'
import listingImage from '../../../assets/icons/listing.svg'
import { getTeam, createTeam } from '../../../api/team';
import Button from '../../Button';
import LoaderSpinner from '../../Spinner';

const CompanyProfile = () => {
    const [modalShow, setModalShow] = useState(false);
    const [logoImageDataURL, setLogoImageDataURL] = useState(null)
    const [loading, setLoading] = useState(true)
    const [teamsData, setTeamsData] = useState(null)
    const [teamDetailsInput, setTeamDetailsInput] = useState({})
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTeamDetailsInput((prevCredentials) => ({ ...prevCredentials, [name]: value }));
      };

      async function fetchData() {
        const response = await getTeam()

        console.log({response})
        if (response!== null && response !== ''){
            console.log(1)
            setTeamsData(response)
        }
    }

    async function insertTeam() {
        console.log(teamDetailsInput)

        const teamData = {
            company_name: teamDetailsInput.company_name,
            company_tagline: teamDetailsInput.company_tagline,
            company_about: teamDetailsInput.company_about,
            company_logo: logoImageDataURL,
            location: teamDetailsInput.location,
            website_url: teamDetailsInput.website_url,
            twitter_url: teamDetailsInput.twitter_url,
            facebook_url: teamDetailsInput.facebook_url,
            linkedin_url: teamDetailsInput.linkedin_url,
          };

          await createTeam(teamData)
          setModalShow(false)

          await fetchData();



          console.log(teamData)

    }

      useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

        fetchData();

    }, [])

    const handleLogoInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = 100; // Adjust width as needed
              canvas.height = 100; // Adjust height as needed
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              const imageDataURL = canvas.toDataURL('image/jpeg', 0.7); // Adjust compression quality
              // imageDataURL is now a string representing the compressed image data
              // You can store this string in your database
              console.log(imageDataURL);
              setLogoImageDataURL(imageDataURL)
            };
          };
          reader.readAsDataURL(file);
        }
      };
      

    return (
       <>
        <LoaderSpinner show={loading} />;

        {teamsData ? (
            <div>
                <Row>
                    <Col xs={2}>
                        <img src={teamsData.company_logo} alt="Company Logo" />
                    </Col>
                    <Col xs={10}>
                        <p className="section-title" style={{ color: '#101828' }}>{teamsData.company_name}</p>
                        <Paragraph style={{ marginTop: '-10px' }}>{teamsData.company_tagline}</Paragraph>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={6}>
                        <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>About Us</p>
                        <Paragraph style={{ marginTop: '-10px' }}>{teamsData.company_about}</Paragraph>
                    </Col>
                    <Col xs={6} >
                        <Row>
                            <Col xs={3}></Col>
                            <Col xs={4} >
                                <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Location</p>
                                <Paragraph style={{ marginTop: '-10px' }}>{teamsData.location}</Paragraph>
                            </Col>
                            <Col xs={4} >
                                <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Website</p>
                                <AnchorText onClick={() => window.open(`${teamsData.website_url}`, "_blank")}>Visit Website</AnchorText>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs={3}></Col>
                            <Col xs={4} >
                                <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Facebook</p>
                                <AnchorText onClick={() => window.open(`${teamsData.facebook_url}`, "_blank")}>Visit Facebook</AnchorText>
                            </Col>
                            <Col xs={4} >
                                <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>Twitter</p>
                                <AnchorText onClick={() => window.open(`${teamsData.twitter_url}`, "_blank")}>Visit Twitter</AnchorText>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col xs={3}></Col>
                            <Col xs={4} >
                                <p className="section-subheading" style={{ color: '#101828', fontSize: '14px' }}>LinkedIn</p>
                                <AnchorText onClick={() => window.open(`${teamsData.linkedin_url}`, "_blank")}>Visit LinkedIn</AnchorText>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        ) : (
            <div>
                <Row>
                    <Col xs={6}>
                        <p className="section-title" style={{ color: '#101828' }}>Add Company details</p>
                        <Paragraph>Add details of your team and company</Paragraph>
                    </Col>
                    <Col xs={2}>
                    </Col>
                    <Col xs={4}>
                        <Button onClick={() => setModalShow(true)} style={{ float: 'right', marginRight: '10px', marginTop: '20px' }} className='float-right' label='Add Team'></Button>
                    </Col>
                </Row>
                <hr />
            </div>
        )}


        <hr/>
        {/* <Modal show={showModal2}  onHide={()=> setShowModal2(false)} >  */}

        <Modal title="New Team" show={modalShow} onHide={() => setModalShow(false)}>
            <span className="card-title">Add new team</span>
            <Paragraph>Add your team details.</Paragraph>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Company Name</Label>
                    <TextField name='company_name' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.company_name} placeholder='Company Name '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Company Tagline</Label>
                    <TextField name='company_tagline' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.company_tagline} placeholder='Company Tagline '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Company About</Label>
                    <textarea
                        name="company_about"
                        value={teamDetailsInput.company_about}
                        className="form-control"
                        onChange={(e) => handleInputChange(e)}
                        required
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Location</Label>
                    <TextField name='location' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.location} placeholder='Location '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Website URL</Label>
                    <TextField isRequired autocomplete="off" name='website_url' type="url" onChange={(e) => handleInputChange(e)} value={teamDetailsInput.website_url} placeholder='Locationaaa '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Twitter URL</Label>
                    <TextField name='twitter_url' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.twitter_url} placeholder='Location '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Facebook URL</Label>
                    <TextField name='facebook_url' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.facebook_url} placeholder='Location '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>LinkedIn URL</Label>
                    <TextField name='linkedin_url' onChange={(e) => handleInputChange(e)} value={teamDetailsInput.linkedin_url} placeholder='Location '></TextField>
                </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
                <Col xs={12}>
                    <Label>Company Logo</Label>
                    <input type="file" accept="image/*" onChange={(e) => handleLogoInputChange(e)} />
                </Col>
            </Row>

            {logoImageDataURL && (
                <Row style={{ marginTop: '20px' }}>
                    <Col xs={12}>
                        <img src={logoImageDataURL} alt="Company Logo" />
                    </Col>
                </Row>
            )}

            <Row style={{ marginTop: '20px' }}>
                <Col lg={6}><Button onClick={() => setModalShow(false)} label={"Cancel"} style={{ width: '100%', textAlign: 'center' }} variant='default'> </Button></Col>
                <Col lg={6}><Button type='submit' onClick={(e) => insertTeam(e)} label={"Add Team"} style={{ width: '100%' }}> </Button></Col>
            </Row>



        </Modal>
       </>
    )}
export default CompanyProfile;