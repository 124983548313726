import Label from '../../../components/Typorgraphy/Label';
import TextField from "../../../components/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import pencilIcon from '../../../assets/images/text-input.svg'
import Swal from 'sweetalert2';
import { toast } from 'sonner';
import Button from '../../../components/Button';
import filtericon from '../../../assets/icons/filter.svg';
import spacingIcon from '../../../assets/icons/spacing.svg';
import calenderIcon from '../../../assets/icons/calendar.svg';
import gridIcon from '../../../assets/icons/grid.svg';
import questionIcon from '../../../assets/images/question.svg';
import DataTable from '../../../components/DataTable';
import SearchableSelect from '../../../components/SearchAbleSelect/searchAbleSelect';
import copyIcon from '../../../assets/icons/copy.svg';
import minusSquare from '../../../assets/icons/minuxSquare.svg'
import arrowDownRed from '../../../assets/icons/arrowDownRed.svg'
import LoadingSpinner from '../../../components/Spinner/index';
import { createCSVMatch, createPorfolioMatch, uploadCSVFile } from '../../../api/csv';
import knowColumns from '../../../utils/knownColumns.json';
import { storeQuestionnaire, storeOnboardingInvites, getUser } from '../../../api/onboarding';
import Header from './Header';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import linkedin from '../../../assets/images/linkedin.png'
import LoadingProgressBar from '../../../components/Spinner/LoadingProgressBar';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { useLocation } from 'react-router-dom';
import './style.css'
import axios from 'axios';
import { eventNames, eventTypes, sendEventToCustomerIO, createCustomerIOCompany } from '../../../utils/CustomerIOAnalytics';
const Formats = () => {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [progressBar,setProgressBar] = useState(3)
    const [show, setShow] = useState(false);
    const [context, setContext] = useState('');
    const [selectedFieldIndex, setSelectedFieldIndex] = useState(null);
    const location = useLocation();
    const dataState = location.state;
    const [matchedFields, setMatchedFields] = useState([]);
    const [unmatchedFields, setUnmatchedFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const [source, setSource] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [inputData, setInputData] = useState({ currentFormat: '', source: '', assetClass: '' })
    const customWidthMatched = { 'Imported File Column': '40%', 'CreditCurve Field': '30%', 'Actions': '30%' }
    const columnsMatched = [
        { name: 'Imported File Column', selector: row => row.importedColumn, sortable: false },
        { name: 'CreditCurve Field', selector: row => row.dv01Field, sortable: false },
        { name: 'Actions', selector: row => row.actions, sortable: false }
    ]
    const dataMatched = matchedFields?.map((item, index) => {
        return {
            id: index,
            importedColumn: item.csvColumn,
            dv01Field: item.matchedColumn + ' (' + item.description + ')',
            actions: <><img src={minusSquare} alt='delete' style={{ marginRight: '10px' }} /> <img src={copyIcon} alt='copy' /></>
        }
    });

    const changeMatchedColumn = (value,index) => {
        const selectedFieldDescription = knowColumns.find(item => item.name == value)?.description;
        console.log('Selected Field Description:', selectedFieldDescription);
        const updatedUnmatchedFields = [...unmatchedFields];
        updatedUnmatchedFields[index].matchedColumn = value;
        updatedUnmatchedFields[index].description = selectedFieldDescription;
        setUnmatchedFields(updatedUnmatchedFields);
        console.log('Updated Unmatched Fields:', updatedUnmatchedFields);
    }
    const customWidthsUnmatched = {'Imported File Column': '18%', 'First Row Data': '10%', 'Confidence': '13%','Score 2':'8%', 'A.I Description' :'22%','Creditcurve Field': '28%', };
    const columnsUnmatched = [
        { name: 'Imported File Column', selector: row => row.importedColumn, sortable: false },
        { name: 'First Row Data', selector: row => row.firstRow, sortable: false },
        { name: 'Confidence', selector: row => row.confidence, sortable: true },
        { name: 'Score 2', selector: row => row.score_2, sortable: true },
        { name: 'A.I Description', selector: row => row.description, sortable: false },
        { name: 'Creditcurve Field', selector: row => row.creditCurveField, sortable: false },
    ];
    const dataForUnmatched = unmatchedFields?.map((item, index) => {
        return {
            id: index,
            llm: item.llm,
            importedColumn: item.csvColumn,
            firstRow: item.firstRowValue,
            score_2: item.score_2 + "%",
            confidence: Math.round(item.score * 100) + '%',
            description: <span style={{ wordWrap: 'break-word', display: 'block', whiteSpace: 'normal', width: '250px' }}>{item.description}</span>,
            creditCurveField: <div style={{border:'none'}}>

            <div style={{ display: 'flex', alignItems: 'center',border:'none' }}> 
            
            <SearchableSelect
        onChange={(e) => changeMatchedColumn(e.target.value, index)}
        selectedValue={item.matchedColumn}
        style={{ marginRight: '10px',cursor:'pointer' }}
        className=''
      >
                
                <optgroup label="Best Matched ">
                <option selected value={item.matchedColumn}>{item.matchedColumn}</option>
        </optgroup>
        
        {item?.otherPossibleMatches?.length > 0 && (
          <optgroup label="Other Possible Matches">
            {item.otherPossibleMatches.map((option, index) => (
              <option key={index} value={option.column}>{option.column}</option>
            ))}
          </optgroup>
        )}
        <optgroup label="Available GRC Fields">
          {knowColumns.map((option, index) => (
            <option key={index} value={option.name}>{option.name}</option>
          ))}
        </optgroup>
        
        
        </SearchableSelect>



        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip1"><strong>Field Not Found ? </strong> <br/> Click to Alert  </Tooltip>}>
             <img src={questionIcon} alt='Field Not Found' style={{marginRight:'10px',cursor:'pointer',marginLeft:'10px'}} onClick={()=> fieldNotFoundAlert(item.csvColumn,item.firstRowValue,item.matchedColumn,item.description)} />
        </OverlayTrigger>
        
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip2"><strong>Edit Field by A.I </strong> <br/> Click to Edit  </Tooltip>}>
        <img  src={pencilIcon} alt='delete' style={{marginRight:'10px',cursor:'pointer'}} onClick={()=> handleShow(index)} />
        </OverlayTrigger>



        </div>
        </div>
                  ,
           
        }
    })



    const conditionalRowStyles = [
        {
            when: row => row.llm === true,
            style: {
                backgroundColor: '#7F56D9',
                color: 'white',
                fontWeight: 'bold',

                '&:hover': {
                    // cursor: 'pointer',
                },
            },
        }
    ]

    async function postFormatData(name, matchFields) {
        await createCSVMatch(name, matchFields)
    }

    async function postPortfolioData(uploadData, matchFields) {
        setLoading(true)
        const { portfolioData, file } = uploadData

        const jsonData = await uploadCSVFile(portfolioData, file)

        if (jsonData !== null) {
            await createPorfolioMatch(jsonData.portfolioId, matchFields)
        }

        await storeQuestionnaire(dataState.completedQuestionnaireData);
        await storeOnboardingInvites(dataState.invitesData);

        const user = await getUser();

        await createCustomerIOCompany(dataState.formData, user.data)
        setLoading(false)
        navigate('/onboarding')
    }

    const matchAllItems = () => {
        sendEventToCustomerIO(eventNames.onboarding, {
            eventType: eventTypes.buttonClicked,
            location: "Onboarding Match",
            buttonName: 'Match'
          })
        setLoading(true);

        const updatedMatchedFields = [...matchedFields];
        const updatedUnmatchedFields = [...unmatchedFields];

        // Move all unmatched items to matched fields
        updatedUnmatchedFields.forEach(field => {
            updatedMatchedFields.push({ csvColumn: field.csvColumn, description: field.description, matchedColumn: field.matchedColumn });
        });

        // Clear unmatched fields
        // setUnmatchedFields([]);

        // Update state with matched fields
        setMatchedFields(updatedMatchedFields);
        setTimeout(() => {
            setLoading(false)
        }, 1200);

    };

    const fieldNotFoundAlert = async (csvColumn, firstRow, bestMatched,description) => {

        Swal.fire({
            title: ' Field Not Found',
            text: `Field '${csvColumn}' not found in the GRC database. Would you like to send a feedback to add this field ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#7F56D9',
            cancelButtonColor: '#d33',
        }).then( async (result)  =>  {
            if (result.isConfirmed) {
                const url = '/api/grcFieldAlert';
                const data = {
                    csvColumn,
                    firstRow,
                    bestMatched,
                    description
                };
            
                try {
                    setLoading(true);
                    console.log('Sending field not found alert:', {data});
                    const response = await axios.post(url, data);
                    console.log('Response:', response.data);
                    toast.success('Feedback sent successfully!');
                } catch (error) {
                    console.error('Error:', error.response ? error.response.data : error.message);
                }
                finally{
                    setLoading(false)
                
                }
            }
        })
    }

    const fetchDataFromLocalStorage = () => {
        return new Promise((resolve, reject) => {
            try {
                const data = dataState.csvData;
                if (data) {
                    resolve(data);
                } else {
                    reject(new Error("Data not found in local storage."));
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    const handleLinkedin = async (option) => {
        sendEventToCustomerIO(eventNames.onboarding, {
            eventType: eventTypes.buttonClicked,
            location: "Onboarding Match",
            buttonName: 'Connect with us on LinkedIn'
        })
  
        // window.location.href = 'https://www.linkedin.com/company/creditcurve/posts/?feedView=all'

        window.open('https://www.linkedin.com/company/creditcurve/posts/?feedView=all', '_blank');
      };

      const handleMatching = async () => {
        console.log(dataState.csvData);
        setLoading(true);
        const firstFiveItems = dataState?.csvData[0]?.slice(0, 5);

    // Create nested array for values
    const values = [];

    // Populate the values array
    const keys = Object.keys(firstFiveItems[0]);

    keys.forEach(key => {
    const columnValues = firstFiveItems.map(item => item[key]);
    values.push(columnValues);
    });

    console.log("First 5 values" , {values});
    
        try {
            const data = await fetchDataFromLocalStorage();
            if (!data || data.length === 0) {
                console.log('No data found in local storage');
                setProgress('0/0');
                setLoading(false);
                return;
            }
    
            const transformedData = data.map(csvData => {
                const columns = Object.keys(csvData[0] || {});
                const chunkedColumns = [];
    
                for (let i = 0; i < columns.length; i += 10) {
                    chunkedColumns.push(columns.slice(i, i + 10));
                }
    
                return chunkedColumns.map(chunk => {
                    const transformedChunk = csvData.slice(0, 2).map(record => {
                        const chunkRecord = {};
                        chunk.forEach(col => {
                            chunkRecord[col] = record[col];
                        });
                        return chunkRecord;
                    });
    
                    return transformedChunk;
                });
            }).flat();
    
            console.log('Transformed Data:', transformedData);
    
            // Calculate total number of columns
            const totalColumns = transformedData.reduce((sum, chunk) => {
                const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
                console.log(`Chunk columns: ${numColumns}`);
                return sum + numColumns;
            }, 0);
            
            console.log('Total Columns:', totalColumns);
    
            let processedColumns = 0;
            setProgress(`${2}/${totalColumns}`);
            const results = [];
    
            for (let chunk of transformedData) {
                try {
                    const response = await axios.post('/api/matchColumns', { csvData: chunk,values:values });
                    const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
                    processedColumns += numColumns;
                    console.log(`Processed Columns: ${processedColumns}/${totalColumns}`);
                    setProgressBar((processedColumns / totalColumns) * 100);
                    setProgress(`${processedColumns}/${totalColumns}`);
                    results.push(response.data);
                } catch (error) {
                    console.error('Error:', error);
                    const numColumns = chunk[0] ? Object.keys(chunk[0]).length : 0;
                    processedColumns += numColumns;
                    console.log(`Processed Columns: ${processedColumns}/${totalColumns}`);
                    setProgress(`${processedColumns}/${totalColumns}`);
                    results.push({ matched: [], unmatched: [] });
                }
            }
    
            const matchedFields = results.flatMap(result => result.matched);
            const unmatchedFields = results.flatMap(result => result.unmatched);
    
            setMatchedFields(matchedFields);
            setUnmatchedFields(unmatchedFields);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateColumn = async () => {
        setLoading(true)
        try {
            const response = await axios.post('/api/updateColumn', { csvData: { context: context, field: unmatchedFields[selectedFieldIndex].csvColumn } });
            console.log("Update Column Response", response)
            const llm = response?.data?.response;
            if (response.data.status === 'success') {
                const updatedUnmatchedFields = [...unmatchedFields];
                updatedUnmatchedFields[selectedFieldIndex].matchedColumn = 'Other'; // llm.fieldName;
                updatedUnmatchedFields[selectedFieldIndex].description = llm.description;
                updatedUnmatchedFields[selectedFieldIndex].score = llm.confidence / 100;
                updatedUnmatchedFields[selectedFieldIndex].llm = true;
                setUnmatchedFields(updatedUnmatchedFields);
                handleClose();
            }
        } catch (error) {
            console.error('Error:', error);
        }
        finally {
            setLoading(false)
        }
    }

    const saveFormat = async () => {
        sendEventToCustomerIO(eventNames.onboarding, {
            eventType: eventTypes.buttonClicked,
            location: "Onboarding Match",
            buttonName: 'Save Loan Tape'
          })
        // Fetch data from local storage
        let newFormatArray = []
        // const jsonData = localStorage.getItem('CSVData');
        const data = dataState.csvData;
        // Get matchedFields from the state or any other source

        // Iterate over each object in the data array
        data.map((item, index) => {
            // Create a new object to store updated column names
            const updatedItem = {};

            // Iterate over each key-value pair in the object
            Object.entries(item).forEach(([key, value]) => {
                // Check if the key matches any csvColumn name in matchedFields
                const matchedField = matchedFields.find(field => field.csvColumn === key);

                // If a match is found, update the key with the corresponding matchedColumn name
                if (matchedField) {
                    updatedItem[matchedField.matchedColumn] = value;
                } else {
                    // If no match is found, keep the original key
                    updatedItem[key] = value;
                }
            });
            if (index < 40) {
                newFormatArray.push(updatedItem)
            }

        });

        console.log(123, { dataState })

        if (dataState.source === "format") {
            postFormatData(inputData.currentFormat, matchedFields)
            navigate('/dashboard/format-preferences', {})
        } else if (dataState.source === "portfolio") {
            postPortfolioData(dataState.uploadData, matchedFields)
        }
        // //localStorage.setItem('CSVDataUpdated',JSON.stringify(newFormatArray))
        // navigate('/dashboard/show-portfolio',{state:{csvData:newFormatArray}})

    };

    useEffect(() => {
        console.log(dataState)
        if (dataState?.csvData) {
            handleMatching();
            if (dataState?.source) {
                setSource(dataState.source)
            }
            if (dataState?.formatName) {
                setInputData((prevCredentials) => ({ ...prevCredentials, currentFormat: dataState.formatName }));
            }
        }
        else {
            navigate('/onboarding')
        }

    }, [])


    const CustomInput = ({ value, onClick }) => {
        return (<input readOnly style={{ width: '100%' }} value={value} onClick={onClick} name='date' type='text' className='datepicker' />)
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevCredentials) => ({ ...prevCredentials, [name]: value }));
    };



    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setSelectedFieldIndex(index)
        setShow(true)

    };

    return (

        <>

            <Header title='Upload Loan Tape' description='Please match each field from the uploaded loan tape with a corresponding CreditCurve Field.' />


            <Container>
                <div style={{ backgroundColor: 'white', height: 'auto', width: '100%', marginLeft: 'auto', marginRight: 'auto', border: '1px solid #EAECF0', borderRadius: '12px', marginBottom: '15px', marginTop: '15px', padding: '20px' }} >
                    {/* Body */}

                    <div>
                    { <LoadingProgressBar show={loading} progress={progress} progressBar={progressBar} />}

                        <Row>
                            <Col lg={3} md={3} sm={6} hidden={source === "portfolio"}>
                                <Label>Name </Label>
                                <TextField placeholder="Name " type='text' name='currentFormat'
                                    onChange={(e) => handleInputChange(e)}
                                    isRequired
                                    value={inputData.currentFormat} />
                            </Col>
                            <Col lg={3} md={3} sm={6}>

                                <Button type='submit' disabled={inputData.currentFormat === '' && source !== "portfolio"} onClick={() => saveFormat()} label={"Save Loan Tape"} style={{ marginLeft: '0px', marginTop: '20px' }} variant={(inputData.currentFormat === '' && source !== "portfolio") ? 'default' : undefined} />
                            </Col>
                            {/* <Col lg={3} md={3} sm={6}>
                    <Label>Source</Label>
                    <div className='custom-select'>
                    <select className='select'>
                        <option>ABC</option>
                    </select>
                    </div>
                </Col> */}


                            {/* <Col lg={3} md={3} sm={6}>
                    <Label>Asset Class</Label>
                         <TextField placeholder="Asset Class " name='assetClass' onChange={(e)=> handleInputChange(e)} value={inputData.assetClass}  type="text" />
                </Col>
                 <Col lg={3} md={3} sm={6}>
                    <Label>As of</Label>
                   <DatePicker  customInput={<CustomInput/>} selected={startDate} onChange={(date) => setStartDate(date)} />
                </Col> */}


                        </Row>


                        <Row style={{ marginTop: '18px' }}>
                            <Col lg={6} md={6} sm={12}>
                                <Button style={{ paddingLeft: '20px', paddingRight: '20px', marginRight: '10px' }} showIconLeft iconLeft={filtericon} variant='default' label='August-2018-RPLCSV' />
                                <Button style={{ paddingLeft: '16px', paddingRight: '16px', marginRight: '10px' }} showIconLeft iconLeft={calenderIcon} variant='default' label='08/31/2024' />
                                <Button style={{ paddingLeft: '17px', paddingRight: '17px', marginRight: '10px' }} showIconLeft iconLeft={spacingIcon} variant='default' label='704' />
                                <Button style={{ paddingLeft: '20px', paddingRight: '20px', marginRight: '0px' }} showIconLeft iconLeft={spacingIcon} variant='default' label='27' />
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <Button style={{ paddingLeft: '12px', paddingRight: '12px', marginRight: '0px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} showIconLeft iconLeft={gridIcon} variant='default' label='FIELD MATCHING' />
                                <Button style={{ paddingLeft: '12px', paddingRight: '12px', marginRight: '0px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeft: '0px' }} showIconLeft iconLeft={filtericon} variant='default' label='DATA WRALNGLING' />

                            </Col>
                        </Row>


                        <hr />
                        <Row style={{ marginTop: '30px', backgroundColor: '#f0f2f3', margin: '0px', borderRadius: '12px', padding: '16px' }}>
                            <Col lg={12} md={12} sm={12}>
                                <span className='section-title'>UNMATCHED FIELDS({unmatchedFields.length})</span>
                            </Col>
                        </Row>
                        <br />
                        <DataTable progressPending={loading}
                            defaultSortFieldId={3}

                            columns={columnsUnmatched} conditionalRowStyles={conditionalRowStyles} data={dataForUnmatched} customWidths={customWidthsUnmatched} />
                        <button className='match-button' onClick={() => matchAllItems()}><img src={arrowDownRed} alt='arrowdoen' /> Match <img src={arrowDownRed} alt='arrowdoen' /></button>
                        <Row style={{ marginTop: '30px', backgroundColor: '#f0f2f3', margin: '0px', borderRadius: '12px', padding: '16px' }}>
                            <Col lg={12} md={12} sm={12}>
                                <span className='section-title'>MATCHED FIELDS({matchedFields.length})</span>
                            </Col>
                        </Row><br />
                        <DataTable progressPending={loading} columns={columnsMatched} data={dataMatched} customWidths={customWidthMatched} />
                        <br /><br />







                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update Field </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>CSV Field </Form.Label>
                                        <Form.Control
                                            type="text"
                                            disabled
                                            value={selectedFieldIndex !== null ? unmatchedFields[selectedFieldIndex].csvColumn : ''}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Context</Form.Label>
                                        <Form.Control autoFocus as="textarea" onChange={(e) => setContext(e.target.value)} value={context} placeholder='Please provide more context about this CSV Column . . . ' rows={3} />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant='default' label='Cancel' onClick={() => handleClose()} />
                                <Button label='Update' onClick={() => updateColumn()} />

                            </Modal.Footer>
                        </Modal>
                    </div>

                    {/* Body */}
                </div>
            </Container>



            {/* Footer */}

            <Container>
                <div style={{ backgroundColor: 'white', borderRadius: '12px', border: '1px solid #EAECF0', padding: '10px', width: '80%', marginLeft: 'auto', marginRight: 'auto', paddingTop: '15px' }}>

                    <Row>
                        <Col>
                            <Paragraph style={{ fontWeight: '500', margin: '10px'}}>© CreditCurve 2024</Paragraph>
                        </Col>
                        <Col>
                            <Button variant='default' showIconLeft iconLeft={linkedin} label=' Connect with us on LinkedIn' onClick={handleLinkedin} style={{ float: 'right', }} />
                        </Col>


                    </Row>




                </div>
            </Container>

            {/* Footer */}


        </>






    )
}
export default Formats