import React from "react";
import { Container } from "react-bootstrap";
import logoImage from '../../../assets/images/logoHeader.svg';
import Title from "../../../components/Typorgraphy/Title";
import Paragraph from "../../../components/Typorgraphy/Paragraph";


const Header = () => {
    return (
        <Container>
          <div style={{backgroundColor:'white',borderRadius:'12px',border :'1px solid #EAECF0', padding :'10px',width:'80%',marginLeft:'auto',marginRight:'auto',paddingTop:'15px' }}>
          <Paragraph style={{fontWeight:'500', margin: '10px'}}>© CreditCurve 2024</Paragraph>
          </div>
        </Container>
    );
}

export default Header;