import React from 'react';
import PropTypes from 'prop-types';
import './Paragraph.scss';
const Label = ({ style={}, children, className,onClick }) => {
  return (
    <p onClick={onClick} style={style} className='label'>
      {children}
    </p>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Label;
