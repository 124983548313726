import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Row,Col} from 'react-bootstrap'
import Button from '../Button'


const handleSave = (props) => {
  const { onHide, privateKey, createLithicIntegration } = props
  onHide()
  // console.log(privateKey, createLithicIntegration)
  createLithicIntegration(privateKey)
}

function ModalComp(props) {
  return (
    <Modal
      {...props}
      size="md"
    
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Header closeButton style={{border:'none'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
         {props.image ? <img src={props.image} alt='ico' style={{width:'48px',borderRadius:'10px'}} /> : '' } 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
     
    </Modal>
  );
}

export default ModalComp;
