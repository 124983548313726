import React, { useCallback, useState, useEffect } from 'react';
import { ReactFlow, ReactFlowProvider, addEdge, useNodesState, useEdgesState, Background, Controls, MiniMap, Handle } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import knownColumns from '../../../../utils/knownColumns.json';

const CustomNode = ({ id, data, isConnectable }) => {
  if (data.label.toLowerCase() === 'aggregation') {
    return (
      <div className="custom-node aggregation">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div className="aggregation-fields">
          <div 
            className="aggregation-field" 
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => data.onFieldDrop(event, id, 'field1')}
          >
            {data.field1 || 'Drop field 1'}
          </div>
          <div 
            className="aggregation-field" 
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => data.onFieldDrop(event, id, 'field2')}
          >
            {data.field2 || 'Drop field 2'}
          </div>
        </div>
        {id !== 'customField' && (
          <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        )}
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'custom_value') {
    return (
      <div className="custom-node custom-value">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div 
          className="custom-value-field" 
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => data.onFieldDrop(event, id, 'field')}
        >
          {data.field || 'Drop field'}
        </div>
        <input
          type="text"
          placeholder="Enter custom value"
          value={data.customValue || ''}
          onChange={(e) => data.onCustomValueChange(id, e.target.value)}
        />
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'rand_id_generator') {
    return (
      <div className="custom-node rand-id-generator">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div 
          className="rand-id-field" 
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => data.onFieldDrop(event, id, 'field')}
        >
          {data.field || 'Drop field'}
        </div>
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  if (data.label.toLowerCase() === 'rename') {
    return (
      <div className="custom-node rename">
        <Handle type="target" position="top" isConnectable={isConnectable} />
        <div>{data.label}</div>
        <div 
          className="rename-field" 
          onDragOver={(event) => event.preventDefault()}
          onDrop={(event) => data.onFieldDrop(event, id, 'field')}
        >
          {data.field || 'Drop field'}
        </div>
        <input
          type="text"
          placeholder="Enter first value"
          value={data.renameValue1 || ''}
          onChange={(e) => data.onRenameValueChange(id, 'renameValue1', e.target.value)}
        />
        <input
          type="text"
          placeholder="Enter second value"
          value={data.renameValue2 || ''}
          onChange={(e) => data.onRenameValueChange(id, 'renameValue2', e.target.value)}
        />
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
        <Handle type="source" position="bottom" isConnectable={isConnectable} />
      </div>
    );
  }

  return (
    <div className={`custom-node ${data.isOperation ? 'operation' : 'field'} ${id==='customField' ? 'final_field' :''}`}>
      <Handle type="target" position="top" isConnectable={isConnectable} />
      <div>{data.label}</div>
      {id !== 'customField' && (
        <button className="delete-button" onClick={() => data.onDelete(id)}>x</button>
      )}
      <Handle type="source" position="bottom" isConnectable={isConnectable} />
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const FlowComponent = ({ customFieldName, fields, operations, savedFlow, onSave, itemIndex }) => {
  const initialNodes = [
    {
      id: 'customField',
      type: 'custom',
      position: { x: 250, y: 250 },
      data: { label: customFieldName, isOperation: false },
      style: { backgroundColor: 'green', color: 'white' },
    },
  ];
  const initialEdges = [];
  
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [searchTerm, setSearchTerm] = useState('');
  const [rfInstance, setRfInstance] = useState(null);

  useEffect(() => {
    if (savedFlow) {
      const flow = JSON.parse(savedFlow);
      if (flow.nodes) {
        setNodes(flow.nodes.map(node => ({
          ...node,
          data: {
            ...node.data,
            onDelete: onDeleteNode,
            onFieldDrop: onFieldDrop,
            onCustomValueChange: onCustomValueChange,
            onRenameValueChange: onRenameValueChange,
          }
        })));
      }
      if (flow.edges) {
        setEdges(flow.edges);
      }
    }
  }, [savedFlow]);

  const isOperation = (type) => ['addition', 'subtraction', 'multiplication', 'average', 'aggregation', 'custom_value', 'rand_id_generator', 'rename'].includes(type);

  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds));
  }, [setEdges]);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onFieldDrop = useCallback((event, nodeId, fieldKey) => {
    event.preventDefault();
    const fieldName = event.dataTransfer.getData('application/reactflow');
    setNodes((nds) => 
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              [fieldKey]: fieldName,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onCustomValueChange = useCallback((nodeId, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              customValue: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onRenameValueChange = useCallback((nodeId, key, value) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              [key]: value,
            },
          };
        }
        return node;
      })
    );
  }, [setNodes]);

  const onDrop = useCallback((event) => {
    event.preventDefault();

    const reactFlowBounds = event.target.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };

    const newNode = {
      id: `${type}_${new Date().getTime()}`,
      type: 'custom',
      position,
      data: { 
        label: `${type.charAt(0).toUpperCase() + type.slice(1)}`,
        isOperation: isOperation(type),
        onDelete: onDeleteNode,
        onFieldDrop: onFieldDrop,
        onCustomValueChange: onCustomValueChange,
        onRenameValueChange: onRenameValueChange,
      },
      style: isOperation(type) ? 
        (type.toLowerCase() === 'aggregation' ? { backgroundColor: 'orange' } : 
         type.toLowerCase() === 'custom_value' ? { backgroundColor: 'yellow' } : 
         { backgroundColor: 'lightblue' }) 
        : {},
    };

    setNodes((nds) => nds.concat(newNode));
  }, [setNodes, onFieldDrop, onCustomValueChange, onRenameValueChange]);

  const onDeleteNode = useCallback((id) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) => eds.filter((edge) => edge.source !== id && edge.target !== id));
  }, [setNodes, setEdges]);

  const onInit = useCallback((reactFlowInstance) => {
    setRfInstance(reactFlowInstance);
    reactFlowInstance.fitView({ padding: 0.2, maxZoom: 0.90 });
  }, []);

  const generatePayload = useCallback(() => {
    const payload = {
      resultField: customFieldName,
      operations: []
    };

    const operationIndices = new Map();

    const processNode = (nodeId, visitedNodes = new Set()) => {
      if (visitedNodes.has(nodeId)) return operationIndices.get(nodeId);
      visitedNodes.add(nodeId);

      const node = nodes.find(n => n.id === nodeId);
      const incomingEdges = edges.filter(e => e.target === nodeId);

      if (node.data.isOperation) {
        const operation = {
          type: node.data.label.toLowerCase(),
          inputs: []
        };

        switch (node.data.label.toLowerCase()) {
          case 'aggregation':
            if (node.data.field1) operation.inputs.push(node.data.field1);
            if (node.data.field2) operation.inputs.push(node.data.field2);
            break;
          case 'custom_value':
            if (node.data.field) operation.inputs.push(node.data.field);
            operation.inputs.push(node.data.customValue || '');
            break;
          case 'rand_id_generator':
            if (node.data.field) operation.inputs.push(node.data.field);
            operation.inputs.push('');
            break;
          case 'rename':
            if (node.data.field) operation.inputs.push(node.data.field);
            operation.inputs.push(node.data.renameValue1 || '');
            operation.inputs.push(node.data.renameValue2 || '');
            break;
          default:
            for (const edge of incomingEdges) {
              const input = processNode(edge.source, visitedNodes);
              if (input !== undefined) {
                if (typeof input === 'number') {
                  operation.inputs.push(`operation: ${input}`);
                } else {
                  operation.inputs.push(input);
                }
              }
            }
        }

        const operationIndex = payload.operations.length;
        operationIndices.set(nodeId, operationIndex);
        payload.operations.push(operation);
        return operationIndex;
      } else {
        return node.data.label;
      }
    };

    const resultNodeEdges = edges.filter(e => e.target === 'customField');
    for (const edge of resultNodeEdges) {
      processNode(edge.source);
    }

    nodes.forEach(node => {
      if (['aggregation', 'custom_value', 'rand_id_generator', 'rename'].includes(node.data.label.toLowerCase())) {
        if (!operationIndices.has(node.id)) {
          const operation = {
            type: node.data.label.toLowerCase(),
            inputs: []
          };
          switch (node.data.label.toLowerCase()) {
            case 'aggregation':
              operation.inputs = [node.data.field1, node.data.field2].filter(Boolean);
              break;
            case 'custom_value':
              operation.inputs = [node.data.field, node.data.customValue || ''].filter(Boolean);
              break;
            case 'rand_id_generator':
              operation.inputs = [node.data.field, ''].filter(Boolean);
              break;
            case 'rename':
              operation.inputs = [node.data.field, node.data.renameValue1 || '', node.data.renameValue2 || ''].filter(Boolean);
              break;
          }
          payload.operations.push(operation);
        }
      }
    });

    const flow = rfInstance.toObject();
    console.log('Payload:', JSON.stringify(payload, null, 2));
    onSave(payload, flow, itemIndex);
  }, [nodes, edges, customFieldName, rfInstance, onSave, itemIndex]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFields = fields.filter(field => field.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="dndflow">
      <ReactFlowProvider>
        <div className="flow-container">
          <aside>
            <div className="section">
              <h4>Input Fields</h4>
              <input
                type="text"
                placeholder="Search fields"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
              />
              <div className="scrollable">
                {filteredFields.map((field, index) => (
                  <div key={index} className="dndnode field" onDragStart={(event) => onDragStart(event, field)} draggable>
                    {field}
                  </div>
                ))}
                </div>
            </div>
            <div className="section">
              <h4>Operations</h4>
              <div className="scrollable">
                {operations.map((operation, index) => (
                  <div key={index} className="dndnode operation" onDragStart={(event) => onDragStart(event, operation)} draggable>
                    {operation}
                  </div>
                ))}
              </div>
            </div>
          </aside>
          <div className="reactflow-wrapper">
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              onInit={onInit}
              nodeTypes={nodeTypes}
              fitView
            >
              <MiniMap />
              <Controls />
              <Background />
            </ReactFlow>
          </div>
        </div>
        <div className="button-container">
          <button onClick={generatePayload}>Generate Payload</button>
        </div>
      </ReactFlowProvider>
    </div>
  );
};

const App = ({csvColumn = '', onSave, itemIndex, savedFlow = null,isFormat=true,userFields = null}) => {
  const fields = isFormat ?  knownColumns.map((column) => column.name) : userFields;
  const operations = ['addition', 'subtraction', 'multiplication', 'average', 'aggregation', 'custom_value', 'rand_id_generator', 'rename'];

  return <FlowComponent onSave={onSave} itemIndex={itemIndex} customFieldName={csvColumn} fields={fields} operations={operations} savedFlow={savedFlow} />;
};

// Styles
const styles = `
.dndflow {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.flow-container {
  display: flex;
  flex: 1;
}

aside {
  width: 200px;
  padding: 10px;
  border-right: 1px solid #ddd;
  background: #f7f7f7;
  overflow: auto;
}

.reactflow-wrapper {
  flex: 1;
  height: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-top: 1px solid #ddd;
}

.button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.button-container button:hover {
  background-color: #45a049;
}

.search-input {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.scrollable {
  max-height: 250px;
  overflow-y: auto;
}

.dndnode {
  padding: 5px;
  margin-bottom: 5px;
  background: #fff;
  border: 1px solid #ddd;
  cursor: grab;
}

.dndnode.field {
  border-radius: 5px;
}

.dndnode.operation {
  border-radius: 0%;
  background: lightblue;
}

.custom-node {
  padding: 5px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  min-width: 90px;
  justify-content: space-between;
  align-items: center;
}

.custom-node.operation {
  background-color: lightblue;
}

.custom-node.aggregation {
  background-color: orange;
  flex-direction: column;
  width: 200px;
  height: 100px;
}

.custom-node.custom-value {
  background-color: yellow;
  flex-direction: column;
  width: 200px;
}

.custom-node.rand-id-generator {
  background-color: lightgreen;
  flex-direction: column;
  width: 200px;
}

.custom-node.rename {
  background-color: lightpink;
  flex-direction: column;
  width: 200px;
}

.aggregation-fields, .custom-value-field, .rand-id-field, .rename-field {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.aggregation-field, .custom-value-field, .rand-id-field, .rename-field {
  border: 1px dashed #888;
  padding: 5px;
  margin: 5px;
  flex: 1;
}

.custom-node input {
  width: 90%;
  margin: 5px 0;
  padding: 3px;
}

.delete-button {
  background-color: #ff4d4d;
  color: white;
  margin-left: 10px;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
}

.delete-button:hover {
  background-color: #ff0000;
}

.final_field {
  background-color: green;
  color: white;
}
`;

// Inject the CSS into the document
const styleElement = document.createElement('style');
styleElement.textContent = styles;
document.head.appendChild(styleElement);

export default App;