import axios from 'axios';
import Cookies from 'js-cookie';


const path = '/api';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },

});

export const getUser = async () => {
  try {
    const response = await authApi.get(URL + '/details');
    return response.data;
  } catch (error) {
    throw error?.response?.data || error.message;
  }
};

export const getOnboardingStatus = async () => {
    try {
        console.log(URL + '/onboarding')
        const response = await authApi.get(URL + '/onboarding/status');
        return response.data;
    } catch (error) {
        console.log(error)
        return error
      }
};

export const getExistingDeal = async () => {
  try {
      const response = await authApi.get(URL + '/onboarding/deal');
      return response.data;
  } catch (error) {
      console.log(error)
      return null
    }
};

export const storeAccountType = async (userData) => {
    try {
      const response = await authApi.post(URL+'/onboarding/account-type', userData);
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const verifyOTP = async (userData) => {
    try {
      const response = await authApi.post(URL+'/onboarding/verify-otp', userData);
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const requestOTP = async () => {
    try {
      const response = await authApi.post(URL+'/onboarding/request-otp', {});
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const storeQuestionnaire = async (questionnaireData) => {
    try {
      const response = await authApi.post(URL+'/onboarding/questionnaire', questionnaireData);
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const storeOnboardingInvites = async (emailInvites) => {
    try {
      const response = await authApi.post(URL+'/onboarding/teamEmails', emailInvites);
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const acceptTerms = async () => {
    try {
      const response = await authApi.post(URL+'/onboarding/terms',{});
      return response.data;
    } catch (error) {
      return error
    }
  };

  export const selectPaymentOption = async (selectedOption) => {
    try {
      const response = await authApi.post(URL+'/onboarding/payment-option',selectedOption);
      return response.data;
    } catch (error) {
      return error
    }
  };




export default authApi;