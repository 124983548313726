import React from 'react';
import { Spinner } from 'react-bootstrap';
import './spinner.css'; // Add CSS for blur effect
import ProgressBar from 'react-bootstrap/ProgressBar';

const LoadingProgressBar = ({ show,progress=0,progressBar=0,apiFunction=false }) => {
  return (
    <div className={`loader-container ${show ? 'show' : ''}`}>
      <div className="loader-overlay"></div>
      <div className="loader-content">
        <div className='progress-bar-loading'>
           
            <p>{apiFunction ? `Processing CSV Fields for Field Matching  : ${progress}` :'Please wait for a few moments . . .'} </p>
    
            <ProgressBar animated now={progressBar} />
        </div>
      
      </div>
    </div>
  );
};

export default LoadingProgressBar;
