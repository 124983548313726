import Cookies from 'js-cookie';
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import listingRestaurantImage from '../../../assets/images/card-restaurant.png';
import listingStartupImage from '../../../assets/images/card-startup.png';
import listingImage from '../../../assets/images/card.jpeg';
import ListingCard from "../../../components/PageComponents/Dashboard/ListingCard";
import Card from "../../../components/PageComponents/Marketplace/MarketplaceCard";
import Title from "../../../components/Typorgraphy/Title";
import { getPortfolioCounts } from '../../../api/csv';
import LoaderSpinner from "../../../components/Spinner";

const HomeDashboard = () => {
    const [portfoliosData, setPortfoliosData] = useState(null);
    const [loading,setLoading] = useState(false)


    useEffect(() => {
        fetchPortfoliosData();
    }, []);
    const fetchPortfoliosData = async () => {
        setLoading(true)
        const portfolioCountData = await getPortfolioCounts();
        setPortfoliosData(portfolioCountData)
        setLoading(false)
    };

    if(loading){
        return <LoaderSpinner show={loading}/>
      }

    return (
        <div>
            <h1>Dashboard</h1>
           
           <Row>
                <Col xs={4}>
                    <Card percentage={12} content={portfoliosData ? portfoliosData.portfolios_count : 0} type='success' title='Portfolios' text='vs last month'/>
                </Col>

                <Col xs={4}>
                <Card percentage={2} content='0'  type='error' title='Conversations' text='vs last month'/>
                </Col>

                <Col xs={4}>
                <Card percentage={12} content={portfoliosData ? portfoliosData.listed_portfolios_count : 0} type='success' title='Listings' text='vs last month'/>
                </Col>
           </Row>
           <hr/>
           <Title style={{fontSize:'16px'}}>Deals that may interest you</Title>
          <Row>
                <Col xs={4}>
                    <ListingCard img={listingRestaurantImage} title='Working capital primarily in the restaurant industry' location='Texas, New York, Ohio' />
                </Col>
                <Col xs={4}>
                    <ListingCard img={listingImage} title='Non Card Installment Loans For Physical Retail Goods' location='Canada' />
                </Col>
                <Col xs={4}>
                    <ListingCard img={listingStartupImage} title='Small book of business for a Seed Series startup' location='USA' />
                </Col>
               
          </Row>


           <br/><br/>

        </div>
    );
}

export default HomeDashboard;