import React, { useEffect, useState } from "react";
import Title from '../../../components//Typorgraphy/Title'
import Button from "../../../components/Button";
import { Col, Row } from "react-bootstrap";
import Card from "../../../components/PageComponents/Marketplace/MarketplaceCard";
import { getCSVFormats, getCSVFormatsById, getPortfolios} from '../../../api/csv';
import Listing from '../../../components/PageComponents/Marketplace/Listing'
import listingImage from '../../../assets/images/logoMain3.png'
import LoaderSpinner from "../../../components/Spinner";
import './marketplace.css'
import Paragraph from '../../../components/Typorgraphy/Paragraph'
import { Link, useNavigate } from "react-router-dom";

const Marketplace = () => {
  const [loading,setLoading] = useState(false)
  const [listings,setListings] = useState([])
  const navigate = useNavigate()



  async function getCSVData() {
    // to get all the formats (this will not contain data, it will only return array of skyflow_id and name for each csv record)
    const formats =  await getPortfolios();
   //setListings(formats)
    // console.log(123,formats)
    setListings(formats)
    setLoading(false)

    console.log(formats)
    
    // if (formats){
    //       // to get the csv record, individually or loop through the formats and then make this api call to get by skyflow_id
    //   const data = await getCSVFormatsById(formats[0].skyflow_id)
    //   console.log(456,data)
    // }
  }

  


 


useEffect(() => {
  setLoading(true)
  getCSVData()
//   const temp = [
//     {
//         skyflow_id: "ce131d81-a517-413c-8db0-d9812ad89475",
//         name: "CSV Sample",
//         data: null
//     },
//     {
//         skyflow_id: "47284804-c3df-45b3-9130-95211257d9c7",
//         name: "Working capital primarily in the restaurant industry",
//         data: null
//     }
// ]
// setListings(temp)
// setLoading(false)
},[])

if(loading){
  return <LoaderSpinner show={loading}/>
}
    return (
        <>
   
            <div style={{float:'right',width:'50%',textAlign:'right'}} >
    
            </div>
            
            <Row>
                {/* Main Marketplace Tab  */}
              <Col xs={8} >
                <div className="row" style={{borderBottom:'1px solid #EAECF0',paddingBottom:'5px'}}>
                    <div className="col-md-7">
                             <Title>Portfolio Marketplace</Title>
                    </div>
                    <div className="col-md-5 text-right" style={{marginTop:'-17px', textAlign: 'right'}}>
                            {/* <Button variant="default" style={{ marginRight: '10px', marginTop: '20px' }} label="Save Search">Create Portfolio</Button> */}
                            <Button variant="default" onClick={() => navigate('/dashboard/add-portfolio')} style={{  marginTop: '20px' }} label="Create Portfolio">Save Search</Button>
                     </div>
                </div>

        <div className="listings-section">

          {listings?.map((listing,index) => {
            return <Listing key={index} isOwner={listing.isOwner} hasPermission={listing.hasPermission} title={listing.name} skyflow_id={listing.skyflow_id} description={listing.description} image={listing.portfolio_image? listing.portfolio_image : listingImage} type={listing.loan_type} favourite={true} onDelete={getCSVData}/>
          })}

          {listings.length === 0  && (
                <Paragraph>No listings available</Paragraph>
          )}

      

        </div>
            
              
              </Col>



                {/* Over View  */}
              {/* <Col style={{borderLeft:'1px solid #EAECF0',height:'100vh'}} xs={4}>
              <Title>Overview</Title>


              <Card percentage={12} content='20.8k' type='success' title='Users' text='vs last month'/>
              <Card percentage={2} content='26.4k'  type='error' title='Sessions' text='vs last month'/>
              <Card percentage={12} content='3M 52s' type='success' title='Session Duration' text='vs last month'/>
              </Col> */}


            </Row>
        </>
    );
}
export default Marketplace;