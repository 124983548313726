import axios from 'axios';
import Cookies from 'js-cookie';


const path = '/api';
const URL = process.env.REACT_APP_API_BASE_URL + path;

const authApi = axios.create({
    URL,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },
});

const authCSV = axios.create({
    URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${Cookies.get('token')}`
    },
});

export const uploadCSVFormat = async (name, data) => {
    try {
        const response = await authApi.post(URL + '/csv/format', {name,data});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createCSVMatch = async (name, matchFields, dropDownColumns) => {
    try {
        const response = await authApi.post(URL + '/csv/match', {name, matchFields, dropDownMatchFields: dropDownColumns});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createModifier = async (body) => {
    try {
        const response = await authApi.post(URL + '/csv/modifier', body);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const deleteCSVMatchById = async (formatId) => {
    try {
        const response = await authApi.delete(URL + `/csv/match/${formatId}`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const updateCSVMatchById = async (formatId, name, matchFields, dropDownColumns) => {
    try {
        const response = await authApi.patch(URL + `/csv/match/${formatId}`, {name, matchFields, dropDownMatchFields: dropDownColumns});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getCSVMatchById = async (formatId) => {
    try {
        const response = await authApi.get(URL + `/csv/match/${formatId}`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const createPorfolioMatch = async (portfolioId, matchFields,dropDownMatchFields) => {
    try {
        const response = await authApi.post(URL + `/portfolios/${portfolioId}/match`, {matchFields,dropDownMatchFields});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const processDeal = async (portfolioId) => {
    try {
        const response = await authApi.post(URL + `/portfolios/${portfolioId}/process-deal`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const uploadCSVFile = async (portfolioData, files) => {
    try {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
        }
        formData.append('name', portfolioData.name);
        formData.append('description', portfolioData.description);
        formData.append('loanType', portfolioData.loanType);
        formData.append('portfolioImage', portfolioData.portfolioImage);
        formData.append('listed', portfolioData.listed);

        const response = await authCSV.post(URL + '/csv/upload', formData);

        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCSVFormats = async () => {
    try {
        const response = await authApi.get(URL + '/csv/format');
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};



export const getPortfolios = async () => {
    try {
        const response = await authApi.get(URL + '/portfolios');
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getCSVMatch = async () => {
    try {
        const response = await authApi.get(URL + '/csv/match');
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getModifiers = async () => {
    try {
        const response = await authApi.get(URL + '/csv/modifier');
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getModifiersLendable = async () => {
    try {
        const response = await authApi.get(URL + '/csv/modifier/deal');
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getPortfolioMatch = async (portfolioId) => {
    try {
        const response = await authApi.get(URL +  `/portfolios/${portfolioId}/match`);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getPortfolioCounts = async () => {
    try {
        const response = await authApi.get(URL +  `/portfolios/count`);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getMatchings = async (portfolioId,formatId) => {
    try {
        const response = await authApi.get(URL + `/portfolios/${portfolioId}/matchbuyer?format=${formatId}`);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const getCSVFormatsById = async (id, format) => {
    let query = ""

    if (format && format?.id){
        query = `?format=${format.id}`
    }

    console.log(format)

    try {
        const response = await authApi.get(URL + `/csv/format/${id}` + query, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'text/csv' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `output.csv`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        return response;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
};

export const getCSVsByPorfoliosById = async (id) => {

    try {
        const response = await authApi.get(URL + `/portfolios/${id}`);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};


export const requestPermission = async (id) => {
    try{
        const response = await authApi.post(URL + `/csv/permissions`,{portfolio_id:id});
        return response;
    }
    catch(e){
        console.log(e)
        return e?.response || e
    }
}

export const grantPermission = async (id) => {
    try{
        const response = await authApi.post(URL + `/portfolio/permissions/${id}`);
        return response;
    }
    catch(e){
        console.log(e)
        return e?.response || e
    }
}

export const getPermissionRequests = async () => {
    try{
        const response = await authApi.get(URL + `/csv/permissions`);
        return response;
    }
    catch(e){
        console.log(e)
        return e?.response || e
    }
}

export const deletePortfolio = async (portfolioId) => {
    try {
        const response = await authApi.delete(URL + `/portfolios/${portfolioId}`, {});
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const downloadPortfolioById = async (id, format, dropDownMatchFields) => {
    try {
        const response = await authApi.post(URL + `/portfolios/${id}/download`, {csvMatch: format, csvDropdown:dropDownMatchFields}, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/zip' }); // Change the MIME type to 'application/zip'
        const contentDisposition = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${contentDisposition}.zip`; // Change the filename extension to '.zip'

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        return response;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
};

export const downloadPortfolioOutput = async (id,formatID) => {
    try {
        const response = await authApi.post(URL + `/portfolios/${id}/download-output`, {formatId: formatID}, {
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/zip' }); // Change the MIME type to 'application/zip'
        const contentDisposition = response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${contentDisposition}.zip`; // Change the filename extension to '.zip'

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        return response;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
};

export const getPortfolioHealthById = async (id) => {

    try {
        const response = await authApi.get(URL + `/portfolios/${id}/health`);
        return response.data;
    } catch (error) {
        console.log(error)
        // throw error?.response?.data || error.message;
    }
};

export const downloadPortfolioDealById = async (id) => {
    try {
        const response = await authApi.post(URL + `/portfolios/${id}/download-deal`, {})

        return response.data;
    } catch (error) {
        console.log(error);
        return error?.response;
    }
};




export default authApi;