import React from 'react';
import PropTypes from 'prop-types';
import './TextField.scss';

const TextField = ({name='', type="text", value=' ', onChange, placeholder, disabled,style={}, isRequired=false, autocomplete }) => {
  return (
    <input 
    className='textField'
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      required={isRequired}
      style={style}
      autoComplete={autocomplete}
    />
  );
};

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  placeholder: '',
  disabled: false,
};

export default TextField;
