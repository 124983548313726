import React, { useState, useRef, useEffect } from "react";
import Title from '../../../components/Typorgraphy/Title'
import Table from 'react-bootstrap/Table';
import LoaderSpinner from "../../../components/Spinner";
import { useLocation } from "react-router-dom";
const Portfolio = () => {
  const location = useLocation();
  const  dataState = location.state;
    const [data,setData] = useState([]);
    const [loading,setLoading]  = useState(true);

    useEffect(()=>{
        const data  = dataState.csvData
        if(data && loading){
            setData(data);
           
            setTimeout(() => {
                setLoading(false);
            }, 1200);
        }
    })
    if(loading){
        return (<LoaderSpinner  show={loading}/>)
    }
    return (
        <div>
            <Title>Portfolio (CSV Matched Fields)</Title>
            <br/>
            <hr/>

            <Table responsive striped='columns'>
      <thead>
      <tr>
          <th>#</th>
          {Object.keys(data[0]).map((key, index) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
      {data?.map((record, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            {Object.values(record).map((value, index) => (
              <td key={index}>{value}</td>
            ))}
          </tr>
        ))}
       
      </tbody>
    </Table>
        </div>
    );
}

export default Portfolio;
