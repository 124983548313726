import React from 'react';
import PropTypes from 'prop-types';
import './Paragraph.scss';
const Title = ({style={}, children, className }) => {
  return (
    <p className='title' style={style}>
      {children}
    </p>
  );
};

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Title;
