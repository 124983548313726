import React from 'react';
import PropTypes from 'prop-types';
import './Paragraph.scss';
const Paragraph = ({ style={}, children, className }) => {
  return (
    <p style={style} className={className}>
      {children}
    </p>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Paragraph;
