// Sidebar.js
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardIcon from '../../assets/icons/dashboard.svg';
import dealFlowIcon from '../../assets/icons/deals-flow.svg';
import logoutIcon from '../../assets/icons/logout.svg';
import marketPlaceIcon from '../../assets/icons/marketplace.svg';
import profileIcon from '../../assets/icons/profile.svg';
import reportingIcon from '../../assets/icons/reporting.svg';
import settingsIcon from '../../assets/icons/settings.svg';
import userIcon from '../../assets/icons/users.svg';
import logo from '../../assets/images/logoSidebar.svg';
import TextField from '../TextField/index';
import './sidebar.css';

import Cookies from 'js-cookie';

import { analytics } from '../../utils/CustomerIOAnalytics';


const Sidebar = () => {
  const userEmail = jwtDecode(Cookies.get('token')).email || '';
  const userName = jwtDecode(Cookies.get('token')).username || '';

  useEffect(() => {
    
  }, []);


  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const handleMenuItemClick = (path) => {
    navigate(path);
  }

  const logOut = () => {
    Swal.fire({
      title: 'Confirmation !',
      animation:false,
      text: "Are you sure you want to log out ? ",
      showCancelButton: true,
      confirmButtonColor: '#7F56D9',
      cancelButtonText: 'No, cancel',
      confirmButtonText: 'Yes, logout'
    }).then((result) => {
      if (result.isConfirmed) {
        Cookies.remove('token');
        analytics.send.reset()
        window.location.href = '/login';
       // navigate('/login');
      }
    })
  }
    const navList = [
      {route:'/dashboard',image:dashboardIcon,text:'Dashboard'},
      {route:'/dashboard/marketplace',image:marketPlaceIcon,text:'Marketplace'},
      // {route:'/dashboard/conversations',image:conversationsIcon,text:'Conversations'},
       {route:'/dashboard/deal-flow',image:dealFlowIcon,text:'Deals Flow'},
      {route:'/dashboard/format-preferences',image:reportingIcon,text:'Format Preferences'},
      // {route:'/dashboard/definition-management',image:definitionManagementIcon,text:'Definition Management'},
      {route:'/dashboard/settings?tab=3',image:userIcon,text:'Teams'}
  ]
  const [searchText,setSearchtext ] = useState('')
  return (
    <div className='sideBar'>
      <img src={logo} alt="logo" />
      <TextField style={{padding:'10px',fontSize:'14px',lineHeight:'10px',width:'100%',marginTop:'20px'}} value={searchText} onChange={(e)=>{setSearchtext(e.target.value)}} placeholder="Search" type="text" />
        <ul className='nav1'>
          {navList.map((item,index)=>{
            return (
              <li key={index} className={`sidebar-menu-item ${item.route === currentRoute ? 'active':''}`} onClick={()=>{handleMenuItemClick(item.route)}}><img alt='dashboard' src={item.image} className='item-icon' /> {item.text}</li>
            )})}
        </ul>
      <ul className="nav2">
        {/* <li style={{width:'250px'}} className={`sidebar-menu-item ${"/dashboard/support" === currentRoute ? 'active':''}`} onClick={()=>{handleMenuItemClick('/dashboard/support')}}><img alt='support' src={supportIcon} className='item-icon' /> Support</li> */}
       <li style={{width:'250px'}} className={`sidebar-menu-item ${"/dashboard/settings" === currentRoute ? 'active':''}`}  onClick={()=>{handleMenuItemClick('/dashboard/settings')}}><img alt='settings' src={settingsIcon} className='item-icon' /> Settings</li>
       <hr/>
      </ul>
      <div className="logout-button">
        <div style={{display: 'flex'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
             <img src={profileIcon} alt="profile" style={{ marginRight: '10px'}} />
             <span><span style={{fontSize:'14px',fontWeight:'600'}}>{userName}</span><br/> <span style={{fontSize:'12px'}} >{userEmail}</span></span>
           </div>
          <div style={{marginLeft: 'auto'}}>
              <img src={logoutIcon} onClick={()=>logOut()} alt="logout" style={{marginLeft:'20px',marginTop:'10px'}} />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Sidebar;



