import React, { useState,useEffect } from 'react';
import { acceptInvite } from '../../../api/authApi';
import Button from '../../../components/Button';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import AnchorText from '../../../components/Typorgraphy/AnchorText';
import TextField from '../../../components/TextField';
import Title from '../../../components/Typorgraphy/Title';
import Label from '../../../components/Typorgraphy/Label';
import '../../../assets/css/App.scss'
import LoaderSpinner from '../../../components/Spinner';
import { useNavigate,useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { analytics, eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const AcceptInvite = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
     const navigate = useNavigate();
   const [credentials, setCredentials] = useState({ username: '', password: '',email :'', roleid:2 });
  const [errorMessage, setErrorMessage] = useState(null);
  const [color,setColor] = useState('red');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
  };

  const handleLoginClicked = async (e) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Register Form",
      buttonName: 'Log In'
    })

    navigate('/login')
  };

  async function parseOnboarding(onboarding) {
    console.log(onboarding)
    Cookies.set('onboardingState', onboarding);
      window.location.href = '/dashboard';

    // navigate('/dashboard', {state: onboarding})

    // if (!onboarding?.account_type){
    //   // if no account type, we navigate to onboarding page to set account type
    //   navigate('/onboarding', {state: onboarding})
    // } else {
    //   window.location.href = '/dashboard';
    // }
}
  const handleRegister = async (e) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Register Form",
      buttonName: 'Get Started'
    })
    e.preventDefault();
    if (credentials.password.length < 6) {
      setErrorMessage("Password must be at least 10 characters long");
      return; 
    }
    setLoading(true)
    try {
      const result = await acceptInvite(credentials);
      console.log({result})
      Cookies.set('token', result.token);

      analytics.identify(result?.user?.email)

      await analytics.group(result?.teamName)

      // navigate('/dashboard')

      parseOnboarding(result.onboardingStatus)

      setCredentials({ username: '', password: '',token :'', roleid:2 })
      setColor('green');
      setErrorMessage('Account Created Successfully'); 
      // navigate('/waiting');
    } catch (error) {
      setErrorMessage(error.message || error)
    }
    finally{
      setLoading(false)
    }
  };

  useEffect(() => {

    const paramValue = queryParams.get('token');
    if(paramValue){
      setCredentials((prevCredentials) => ({ ...prevCredentials, token: paramValue }));
    }
   
    const isFirstVisit = localStorage.getItem('isFirstR');
    if (!isFirstVisit) {
      localStorage.setItem('isFirstR', 'false');
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  }, []);


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
       <LoaderSpinner show={loading} />
      <form onSubmit={handleRegister} style={{ width: '350px' }}>
       
        <Title style={{textAlign:'left'}}>Welcome</Title>
        <Paragraph style={{textAlign:'left'}}>You have been invited to join CreditCurve. Please enter your name and set a password to continue.</Paragraph>

         {errorMessage && (
          <div style={{ color: color, marginTop: '10px', textAlign: 'center',marginBottom:'15px' }}>{errorMessage}</div>
        )}
        <div style={{marginBottom:'20px',marginTop:'35px'}}>
          <Label>Name</Label>
          <TextField isRequired type="text"  autocomplete="off"  name="username" placeholder="Enter your name" value={credentials.username} onChange={(e)=>handleInputChange(e)} />
        </div>

        
        <div style={{marginBottom:'30px'}}>
          <Label>Password</Label>
            <TextField isRequired type="password" name="password"  placeholder="Create a password" value={credentials.password} onChange={handleInputChange} />
             {/* <Paragraph style={{textAlign:'left',fontSize:'13px'}}></Paragraph> */}

        </div>
        
        <Button type='submit' style={{width:'100%',marginBottom:'20px'}} label={"Get Started"} ></Button>
        <div className="d-flex align-items-center" style={{textAlign:'center',justifyContent:'center',justifyItems:'center'}}>
             
            <Paragraph style={{textAlign:'center',marginRight:'7px'}}>Already have an account ? </Paragraph>
              <AnchorText onClick={handleLoginClicked}>Log in </AnchorText>
              
        </div>
       
      </form>
     
    </div>
  );
};

export default AcceptInvite;
