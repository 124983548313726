import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './MultiSelectDropdown.css';

const MultiSelectDropdown = ({ options, selectedOptions, setSelectedOptions, title }) => {
  const [open, setOpen] = useState(false);

  const handleSelect = (eventKey) => {
    const newSelection = [...selectedOptions];
    if (newSelection.includes(eventKey)) {
      const index = newSelection.indexOf(eventKey);
      newSelection.splice(index, 1);
    } else {
      newSelection.push(eventKey);
    }
    setSelectedOptions(newSelection);
  };

  return (
    <div className="multi-select-dropdown">
      <Dropdown show={open} onToggle={() => setOpen(!open)}>
        <Dropdown.Toggle 
          id="dropdown-basic-button" 
          className={`dropdown-toggle-custom ${open ? 'open' : ''}`}
          style={{width: "100%",textAlign:'center'}}>
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-custom" style={{width: "100%"}}>
          {options.map((option) => (
            <Dropdown.Item
              key={option}
              as="div"
              onClick={(e) => {
                e.stopPropagation();
                handleSelect(option);
              }}
              active={selectedOptions.includes(option)}
              className={selectedOptions.includes(option) ? 'selected-item' : ''}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelect(option);
                }}
              />
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default MultiSelectDropdown;
