import React, { useState } from 'react';
import { selectPaymentOption } from '../../../api/onboarding';
import Header from './Header';
import Footer from './Footer';
import { Container } from 'react-bootstrap';
import {Row,Col} from 'react-bootstrap';
import standard1 from '../../../assets/images/standard2.svg'
import Button from '../../../components/Button';
import LoaderSpinner from '../../../components/Spinner';
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';

const SellerPaymentOptions = ({ onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleOptionChange = async (option) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Payment Options",
      buttonName: option
    })
    setLoading(true)
    setSelectedOption(option);
    await selectPaymentOption({selectedOption: option});
    await onSubmit();
    setLoading(false)
  };

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   if (selectedOption) {
  //       console.log(selectedOption);
  //       await selectPaymentOption({selectedOption});
  //       await onSubmit();
  //   } else {
  //     console.error('Please select a payment option');
  //   }
  // };


  return (
    <> <LoaderSpinner show={loading} />
    <div style={{backgroundColor:'#f9fafb',height:'100vh'}}>

      <Header title='Payment Options' description='Select a payment package below'/>
   
     
      <Container>
      <div style={{backgroundColor:'white',height:'500px',width:'80%',marginLeft:'auto',marginRight:'auto',border :'1px solid #EAECF0',borderRadius:'12px',marginBottom:'15px',marginTop:'15px',padding:'20px'}} >
       {/* Body */}


       <Row style={{}}>
        <Col  xs={12} md={6} >
          <div className='plan-card'> 
            <div className='standard1' ></div>
            <Button onClick={(e)=>  {handleOptionChange('option1')}} label='Select Plan' style={{width:'60%'}}></Button>
          </div>
        </Col>
        <Col  xs={12} md={6} >
          <div className='plan-card'> 
            <div className='standard2' ></div>
            <Button onClick={(e) => {handleOptionChange('option2')}} label='Select Plan' style={{width:'60%'}}></Button>
          </div>
        </Col>
       </Row>




      {/* Body */}
      </div>
      </Container>
      
     

      <Footer/>
      {/* <h2>Select Payment Option:</h2>
      <form onSubmit={handleFormSubmit}>
        <div>
          <label>
            <input
              type="radio"
              name="paymentOption"
              value="onePercentFee"
              checked={selectedOption === 'onePercentFee'}
              onChange={() => handleOptionChange('onePercentFee')}
            />
            Option 1: 1% fee standard
          </label>
          <p>Standard fee of 1% for each deal</p>
        </div>
        <div>
          <label>
            <input
              type="radio"
              name="paymentOption"
              value="fiveHundredFixed"
              checked={selectedOption === 'fiveHundredFixed'}
              onChange={() => handleOptionChange('fiveHundredFixed')}
            />
            Option 2: Enterprise
          </label>
          <p>
            Premium placement of deals in our private credit network for $500 a month
            <br />
            + 1% fee per deal
            <br />
            Premium support included
          </p>
        </div>

        <button type="submit">Submit</button>
      </form> */}


    </div>
    </>
  );
};

export default SellerPaymentOptions;
