import React, { useState, useRef, useEffect } from 'react';
import Button from "../../../components/Button";
import { uploadCSVFile } from '../../../api/csv';
import Header from './Header';
import { Container,Row,Col } from 'react-bootstrap';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Footer from './Footer';
import TextField from '../../../components/TextField';
import Label from '../../../components/Typorgraphy/Label';
import MultiSelectDropdown from './MultiSelectDropdown';
import Form from 'react-bootstrap/Form';
import LoaderSpinner from '../../../components/Spinner';
import { toast } from "sonner";
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2'
import Papa from 'papaparse'; // Import PapaParse library
import { useNavigate } from "react-router-dom";
import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';
import deleteIcon from '../../../assets/icons/delete.svg'
import addIcon from '../../../assets/icons/plus.svg'
import { getExistingDeal } from '../../../api/onboarding';
import { downloadPortfolioDealById } from '../../../api/csv';




const SellerQuestionnaireForm = ({ onSubmit, deal }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState(['']);
  const [existingPorfolioId, setExistingPorfolioId] = useState('');
  const [formData, setFormData] = useState({
    websiteUrl: '',
    companyName: '',
    assetTypes: [],
    geographicFocus: [],
    businessDescription: '',
    minAmount: '',
    maxAmount: '',
  });
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);


  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const handleAddEmail = () => {
    if (emails.length < 5) {
      setEmails([...emails, '']);
    }
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleAssetTypesChange = (selectedOptions) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.multiSelectCheckboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: 'Asset Types'
    })
    setFormData((prevState) => ({
      ...prevState,
      assetTypes: selectedOptions,
    }));

    console.log(formData.assetTypes)
  };

  const handleGeographicFocusChange = (selectedOptions) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.multiSelectCheckboxClicked,
      location: "Onboarding Questionnaire",
      checkBoxName: 'Geographic Focus'
    })
    setFormData((prevState) => ({
      ...prevState,
      geographicFocus: selectedOptions,
    }));
  };


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      const updatedValues = checked
        ? [...formData[name], value]
        : formData[name].filter(item => item !== value);

      setFormData({ ...formData, [name]: updatedValues });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e) => {

    console.log(e.target.files)
    const selectedFile = e.target.files;
    setFile(selectedFile);
  };

  const handleUploadButtonClick = () => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.multiSelectCheckboxClicked,
      location: "Onboarding Questionnaire",
      buttonName: 'Upload Loan Tape'
    })
    fileInputRef.current.click();
  };

  function isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null)
        return false;
    else
        return true;
}

  const convertFileToJsonAndSave = async (file, completedQuestionnaireData) => {
    setLoading(true)

    const csvDataArray = [];
    for (let i = 0; i < file.length; i++) {
      const currentFile = file[i];
      const csvData = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const content = event.target.result;
          const jsonData = csvToJson(content);
          if (jsonData !== null) {
            // resolve(trimArrayToMax40(jsonData));
            resolve(jsonData);
          } else {
            reject(new Error('Failed to parse CSV data'));
          }
        };
        reader.readAsText(currentFile);
      }).catch(error => {
        console.error('Error:', error);
        return null;
      });

      if (csvData !== null) {
        csvDataArray.push(csvData);
      }
    }

    const portfolioData = {
      listed: false
    }
    console.log(portfolioData)
    
    const invitesData = emails.map(email => {
      return { 
        email,
        companyName: formData.companyName,
       }
    })
    setLoading(false)
    navigate('/onboarding/match', { state: { formData, invitesData, completedQuestionnaireData: completedQuestionnaireData, csvData: csvDataArray, source: "portfolio", uploadData: { portfolioData, file } }, onComplete: onComplete});

  };

  const csvToJson = (csvContent) => {
    const { data, errors } = Papa.parse(csvContent, { header: true, skipEmptyLines: true }); // Parse CSV with PapaParse
    if (errors.length) {
      console.error("Invalid CSV File/Data !", errors);
      let errorString = errors.map((error, index) => {
        return `Row # ${error.row}   ${error.message}`
      }).join("\n")
      Swal.fire({
        title: 'Invalid CSV File/Data !',
        animation: true,
        text: errorString,
      })
      return null;
    }
    return data;
  };

  function trimArrayToMax40(array) {
    if (array.length > 40) {
      return array.slice(0, 40);
    } else {
      return array;
    }
  }

  const handleSubmit = async (e) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Questionnaire",
      buttonName: 'Continue'
    })
    e.preventDefault();

    const requiredFields = [
      'websiteUrl',
      'companyName',
      'assetTypes',
      'geographicFocus',
      'businessDescription',
      'minAmount',
      'maxAmount'
    ];

    const missingFields = requiredFields.filter(field => {
      const value = formData[field];
      return value === undefined || value === '' || (Array.isArray(value) && value.length === 0);
    });

    if (missingFields.length > 0) {
      // alert('Please fill out all required fields:', missingFields);
      toast.error('Please fill out all required fields:', missingFields);
      return;
    }

    if (!isUrlValid(formData['websiteUrl'])){
      toast.error('Invalid website url.');
      return;
    }
    

    if (!file) {
      // alert('Please upload a file');
      toast.error('Please upload a file')
      return;
    }
    setLoading(true)

    const resultArray = [];
    for (const key in formData) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const value = formData[key];
        let answer;

        if (Array.isArray(value)) {
          answer = value.join(', ');
        } else {
          answer = value.toString();
        }

        resultArray.push({ question: key, answer });
      }
    }

    try {
      await convertFileToJsonAndSave(file, resultArray);

    } catch (error) {
      alert('Error submitting form:', error);
    }
    finally {
      setLoading(false)
    }
  };

  async function onComplete() {
    await onSubmit();
  }

  const assetTypeOptions = [
    { label: "Direct Lending", value: "Direct Lending" },
    { label: "Distressed Debt", value: "Distressed Debt" },
    { label: "Mezzanine Financing", value: "Mezzanine Financing" },
    { label: "Special Situations", value: "Special Situations" },
    { label: "Leveraged Loans", value: "Leveraged Loans" },
    { label: "Real Estate Debt", value: "Real Estate Debt" },
    { label: "Infrastructure Debt", value: "Infrastructure Debt" },
    { label: "Commercial Mortgage-Backed Securities (CMBS)", value: "Commercial Mortgage-Backed Securities (CMBS)" },
    { label: "Asset-Backed Securities (ABS)", value: "Asset-Backed Securities (ABS)" },
    { label: "Trade Finance", value: "Trade Finance" },
    { label: "Royalty Financing", value: "Royalty Financing" },
    { label: "Litigation Finance", value: "Litigation Finance" },
    { label: "Consumer Loans (Personal Loans, Auto Loans, Student Loans, Credit Card Receivables)", value: "Consumer Loans (Personal Loans, Auto Loans, Student Loans, Credit Card Receivables)" },
    { label: "Aircraft and Ship Financing", value: "Aircraft and Ship Financing" },
    { label: "Venture Debt", value: "Venture Debt" },
    { label: "Bridge Loans", value: "Bridge Loans" },
    { label: "Structured Equity", value: "Structured Equity" },
    { label: "Non-Performing Loans (NPLs)", value: "Non-Performing Loans (NPLs)" },
    { label: "Environmental, Social, and Governance (ESG) Loans", value: "Environmental, Social, and Governance (ESG) Loans" },
    { label: "Project Finance", value: "Project Finance" },
    { label: "Energy Financing", value: "Energy Financing" },
    { label: "Senior Secured Loans", value: "Senior Secured Loans" },
    { label: "Middle Market Lending", value: "Middle Market Lending" },
    { label: "Equipment Financing", value: "Equipment Financing" },
    { label: "Syndicated Loans", value: "Syndicated Loans" },
    { label: "Second Lien Loans", value: "Second Lien Loans" },
    { label: "Private Student Loans", value: "Private Student Loans" },
    { label: "Healthcare Financing", value: "Healthcare Financing" },
    { label: "Agricultural Loans", value: "Agricultural Loans" },
    { label: "Sports Financing", value: "Sports Financing" },
    { label: "Buy Now, Pay Later (BNPL)", value: "Buy Now, Pay Later (BNPL)" },
    { label: "Microfinance", value: "Microfinance" },
    { label: "Payday Loans", value: "Payday Loans" },
    { label: "Home Improvement Loans", value: "Home Improvement Loans" },
    { label: "Retail Financing", value: "Retail Financing" },
    { label: "Pawn Shop Loans", value: "Pawn Shop Loans" },
    { label: "Rent-to-Own Financing", value: "Rent-to-Own Financing" },
    { label: "Peer-to-Peer Lending", value: "Peer-to-Peer Lending" },
    { label: "Invoice Financing", value: "Invoice Financing" },
    { label: "Mobile Payment Financing", value: "Mobile Payment Financing" },
    { label: "Catastrophe Bonds", value: "Catastrophe Bonds" },
    { label: "Co-operative Loans", value: "Co-operative Loans" },
    { label: "Municipal Financing", value: "Municipal Financing" },
    { label: "Consumer Installment Loans", value: "Consumer Installment Loans" },
    { label: "Motorcycle and Boat Loans", value: "Motorcycle and Boat Loans" },
    { label: "Subscription Line Financing", value: "Subscription Line Financing" },
    { label: "Fintech Lending", value: "Fintech Lending" },
    { label: "Emerging Market Loans", value: "Emerging Market Loans" },
    { label: "Insurance-Linked Securities", value: "Insurance-Linked Securities" },
    { label: "Land Development Loans", value: "Land Development Loans" }
    ];

  const geographicFocusOptions = [
    { label: "North America", value: "North America" },
    { label: "Europe", value: "Europe" },
    { label: "Asia", value: "Asia" },
    { label: "Australia", value: "Australia" },
    { label: "LATAM", value: "LATAM" },
    { label: "Middle East & Africa", value: "Middle East & Africa" },
    { label: "Global", value: "Global" },
  ];

  async function fetchExistingDeal() {
    try {
      setLoading(true)
      console.log(123)
      const response = await getExistingDeal();

      if (response !== null) {
        console.log(456)
        setExistingPorfolioId(response.portfolio_id);
      }
      console.log(456)
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  async function fetchPortfolioDealById(id) {
    try {
      setLoading(true)
      const response = await downloadPortfolioDealById(id);
      console.log({ response })
      if (response !== null) {
        navigate('/onboarding/deal-match', { state: { csvData: response, source: "deal",portfolioId:id }, onComplete: onComplete});

      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  useEffect(() => {
    // fetchExistingDeal()
    console.log(1234568,{deal})
    if (deal && deal?.portfolio_id) {
      fetchPortfolioDealById(deal.portfolio_id)
    }
  },[]);


  return (
    <div>
<LoaderSpinner show={loading} />
      <Header title="Questionnaire" description='Please fill in the questionnaire below' />
      <form onSubmit={handleSubmit}>

      <Container>
      <div style={{backgroundColor:'white',height:'auto',width:'80%',marginLeft:'auto',marginRight:'auto',border :'1px solid #EAECF0',borderRadius:'12px',marginBottom:'15px',marginTop:'15px',padding:'20px'}} >
       {/* Body */}


       <Row>
       <Col  xs={12} md={6}>
          <Label>Company Name </Label>
          <TextField isRequired type='text' placeholder='Your Company Name ' id="companyName" name="companyName"   value={formData.companyName} onChange={handleInputChange} />
        </Col>


        <Col  xs={12} md={6}>
          <Label>Website URL</Label>
          <TextField isRequired type='text' placeholder='www.yoursite.com' id='websiteUrl' name='websiteUrl' value={formData.websiteUrl} onChange={handleInputChange} />
        </Col>
       </Row>



       <Row style={{marginTop:'30px'}}>
        <Col  xs={12} md={6}>
          <Label>Asset Types : </Label>
          {/* <MultiSelectDropdown
            options={assetTypeOptions}
            selectedOptions={formData.assetTypes}
            setSelectedOptions={handleAssetTypesChange}
            title="Select Asset Types" /> */}
            <MultiSelect
            isRequired
              options={assetTypeOptions}
              value={formData.assetTypes}
              onChange={handleAssetTypesChange}
              labelledBy="Select Asset Types"
            />


        </Col>


        <Col  xs={12} md={6}>
          <Label>Geographic Focus : </Label>
          {/* <MultiSelectDropdown
            options={geographicFocusOptions}
            selectedOptions={formData.geographicFocus}
            setSelectedOptions={handleGeographicFocusChange}
            title="Select Geographic Focus"/> */}
          <MultiSelect
          isRequired
            options={geographicFocusOptions}
            value={formData.geographicFocus}
            onChange={handleGeographicFocusChange}
            labelledBy="Select Geographic Focus"
            />
        </Col>

       </Row>


       <Row style={{marginTop:'30px'}}>
        <Col  xs={12} md={6}>
          <Label>Minimum Amount to Raise (USD)</Label>
          <TextField isRequired type='number' placeholder='e.g 1000' id='minAmount' name='minAmount' value={formData.minAmount} onChange={handleInputChange} />
        </Col>


        <Col  xs={12} md={6}>
          <Label>Maximum Amount to Raise (USD)</Label>
          <TextField isRequired type='number' placeholder='e.g 10000' id='maxAmount' name='maxAmount' value={formData.maxAmount} onChange={handleInputChange} />
        </Col>
       </Row>


       <Row style={{marginTop:'30px'}}>
        <Col  xs={12} md={12}>
          <Label>Describe your business</Label>
          <TextField isRequired type='textbox' placeholder='Describe your business' id='businessDescription' name='businessDescription' value={formData.businessDescription} onChange={handleInputChange} />
        </Col>

       </Row>

      {emails.length < 5 && (emails.length === 0) && (
         <Row style={{ marginTop: '30px' }}>
          <Col xs={3} md={3}>

          <Label>Add Team Member</Label>

        <img src={addIcon} onClick={() => { handleAddEmail() }} alt="delete file" style={{ cursor: 'pointer', marginLeft: '10px' }} />
        </Col>

        </Row>

      )}

      {emails.map((email, index) => (
        <Row style={{ marginTop: '30px' }}>
          <Label>Add Team Member </Label>
          <Col xs={5} md={5}>
            <TextField
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
            />
          </Col>
          <Col xs={1} md={1}>
            {emails.length > 0 && (
              <img src={deleteIcon} onClick={() => { handleRemoveEmail(index) }} alt="delete file" style={{ cursor: 'pointer' }} />
            )}
            {emails.length < 5 && (index === emails.length - 1) && (
              <img src={addIcon} onClick={() => { handleAddEmail() }} alt="delete file" style={{ cursor: 'pointer', marginLeft: '10px' }} />
            )}
          </Col>
        </Row>

      ))}
      {existingPorfolioId === '' && (
        <Row style={{ marginTop: '30px' }}>
          <Col xs={12} md={12}>
            <Label>Please Upload Your Loan Tape & Transaction History So We Can Disperse It To Our Private Credit Fund Network: {existingPorfolioId}</Label>

            {file && (
              <div>
                <Label>Selected Files:</Label>
                <ul>
                  {Array.from(file).map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>

            )}
            <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
            <Button onClick={handleUploadButtonClick} label='Upload Loan Tape ' />
          </Col>

        </Row>
      )}





       {/* Body */}
      </div>
      </Container>

     
     
     {/* Footer */}

     <Container>
          <div style={{backgroundColor:'white',borderRadius:'12px',border :'1px solid #EAECF0', padding :'10px',width:'80%',marginLeft:'auto',marginRight:'auto',paddingTop:'15px' }}>
          
          <Row>
            <Col>
            <Paragraph style={{fontWeight:'500', margin: '10px'}}>© CreditCurve 2024</Paragraph>
            </Col>
            <Col>
            <Button label='Continue ' type='submit' style={{float:'right',}} />
            </Col>
         
         
          </Row>


          
        
          </div>
    </Container>

     {/* Footer */}


     </form>





      
      {/* <h1>Seller Questionnaire</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="websiteUrl">Website URL:</label>
        <input
          type="text"
          id="websiteUrl"
          name="websiteUrl"
          value={formData.websiteUrl}
          onChange={handleInputChange}
        />

<br />

        <label htmlFor="companyName">Company Name:</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
        />

<br />

        <fieldset>
          <legend>Asset Types:</legend>
          {assetTypeOptions.map(option => (
            <label key={option}>
              <input
                type="checkbox"
                name="assetTypes"
                value={option}
                checked={formData.assetTypes.includes(option)}
                onChange={handleInputChange}
              />
              {option}
            </label>
          ))}
        </fieldset>

        <br />

        <fieldset>
          <legend>Geographic Focus:</legend>
          {geographicFocusOptions.map(option => (
            <label key={option}>
              <input
                type="checkbox"
                name="geographicFocus"
                value={option}
                checked={formData.geographicFocus.includes(option)}
                onChange={handleInputChange}
              />
              {option}
            </label>
          ))}
        </fieldset>

        <br />

        <label htmlFor="businessDescription">Describe your business:</label>
        <textarea
          id="businessDescription"
          name="businessDescription"
          value={formData.businessDescription}
          onChange={handleInputChange}
        />

<br />

        <label htmlFor="minAmount">Minimum Amount to Raise (USD):</label>
        <input
          type="number"
          id="minAmount"
          name="minAmount"
          value={formData.minAmount}
          onChange={handleInputChange}
        />

<br />

        <label htmlFor="maxAmount">Maximum Amount to Raise (USD):</label>
        <input
          type="number"
          id="maxAmount"
          name="maxAmount"
          value={formData.maxAmount}
          onChange={handleInputChange}
        />

        <br />
        <br />
        <br />
        <br />
        {file && (
          <div>
            <h3>Selected Files:</h3>
            <ul>
              {Array.from(file).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </div>
        
        )}
        <input ref={fileInputRef} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} className="file-input" multiple />
        <Button onClick={handleUploadButtonClick} label='Upload Loan Tape ' />

        <br />
        <br />

        <button type="submit">Submit</button>
      </form> */}
    </div>
  );
};

export default SellerQuestionnaireForm;
