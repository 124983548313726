import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getOnboardingStatus, storeAccountType, verifyOTP } from '../../../api/onboarding';
import SellerQuestionnaireForm from './SellerQuestionnaire'; 
import BuyerQuestionnaireForm from './BuyerQuestionnaire'; 
import SellerTerms from "./SellerTerms";
import SellerPaymentOptions from "./SellerPaymentOptions";
import AccountType from "./AccountType";
import LoaderSpinner from "../../../components/Spinner";
import ThankyouPage from "./Welcome";
import Cookies from 'js-cookie';

import ForgotPassword from './VerifyOTP'; 

import { eventNames, eventTypes, sendEventToCustomerIO } from '../../../utils/CustomerIOAnalytics';


const OnboardingPage = () => {
  const location = useLocation();
  const dataState = location.state;
  const [onboardingStatus, setOnboardingStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [deal, setDeal] = useState(null);
  const navigate = useNavigate()


  const handleAccountTypeSelect = async (type) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Select Account Type",
      buttonName: 'Continue'
    })
    setLoading(true)
    console.log({ type })

    await storeAccountType({ accountType: type })
    await fetchOnboardingStatus()

    // setTimeout(async () => {
    // }, 1000);

    setLoading(false)
  };

  const handleOTPSubmit = async (otp) => {
    sendEventToCustomerIO(eventNames.onboarding, {
      eventType: eventTypes.buttonClicked,
      location: "Onboarding Verify Email",
      buttonName: 'Verify Email'
    })
    setLoading(true)
    console.log({ otp })
    setOnboardingStatus('')

    await verifyOTP({ otp: otp })
    await fetchOnboardingStatus()


    // setTimeout(async () => {
    // }, 1000);

    setLoading(false)
  };

  const handleTermsAcceptance = () => {

  };
  async function fetchOnboardingStatus() {
    setLoading(true)
    const response = await getOnboardingStatus();
    console.log({onboardingStatus: response})
    setOnboardingStatus(response);

    if (response.onboarding_complete) {
      navigate('/dashboard');
    }
    setLoading(false)
  }

  useEffect(() => {
    console.log({ dataState })
    const deal = Cookies.get('deal')
    console.log({ deal })
    if (deal) {
      setDeal(JSON.stringify(deal))
    }
    

    fetchOnboardingStatus()
  }, [])

  if (loading) {
    return (<LoaderSpinner show={loading} />)
  } else {
  return (
    <div>
      {/* <LoaderSpinner show={loading} /> */}

      {onboardingStatus !== '' && !onboardingStatus?.email_verified && (
        <div>
          <ForgotPassword onSubmitOTP={handleOTPSubmit}/>
        </div>
      )}


      {/* if the user is neither buyer nor seller, we need to first select account type */}
      {onboardingStatus !== '' && onboardingStatus?.email_verified && onboardingStatus?.account_type !== 'buyer' && onboardingStatus?.account_type !== 'seller' && (
        <div>
          {/* <h2>Select Account Type</h2>
          <button onClick={() => handleAccountTypeSelect('buyer')}>Buyer</button>
          <button onClick={() => handleAccountTypeSelect('seller')}>Seller</button> */}

          <AccountType selectAccountType={handleAccountTypeSelect} />
        </div>
      )}


      {/*----------------------------------- buyer onboarding ------------------------------------ */}

      {onboardingStatus !== '' && onboardingStatus?.account_type === 'buyer' && (
        <div>
          {!onboardingStatus?.questionnaire_filled && (
            <div>
              <BuyerQuestionnaireForm onSubmit={fetchOnboardingStatus}/>
            </div>
          )}

          {onboardingStatus?.questionnaire_filled && (
            <div>
               <ThankyouPage />
            </div>
          )}
        </div>
      )}

      {/*----------------------------------- seller onboarding ------------------------------------ */}  
 
      {onboardingStatus !== '' && onboardingStatus?.account_type === 'seller' && deal === null && (
        <div>

          {!onboardingStatus?.payment_options_selected && !onboardingStatus?.questionnaire_filled &&  (
            <div>
              <SellerPaymentOptions onSubmit={fetchOnboardingStatus} />
            </div>
          )}

          {onboardingStatus !== '' && onboardingStatus?.payment_options_selected && !onboardingStatus?.questionnaire_filled && (
            <div>
              <SellerQuestionnaireForm onSubmit={fetchOnboardingStatus} />
            </div>
          )}


          {onboardingStatus !== '' && onboardingStatus?.questionnaire_filled && onboardingStatus?.payment_options_selected && (
            <div>
             <ThankyouPage />
            </div>
          )}
        </div>
      )}

      {onboardingStatus !== '' && onboardingStatus?.account_type === 'seller' && deal !== null && (
        <div>
          {!onboardingStatus?.questionnaire_filled && (
            <div>
              <SellerQuestionnaireForm deal={JSON.parse(JSON.parse(deal))} onSubmit={fetchOnboardingStatus} />
            </div>
          )}

          {onboardingStatus !== '' && !onboardingStatus?.payment_options_selected && onboardingStatus?.questionnaire_filled && (
            <div>
              <SellerPaymentOptions onSubmit={fetchOnboardingStatus} />
            </div>
          )}


          {onboardingStatus !== '' && onboardingStatus?.questionnaire_filled && onboardingStatus?.payment_options_selected && (
            <div>
              <ThankyouPage />
            </div>
          )}
        </div>
      )}




    </div>
  );
}
};

export default OnboardingPage;
