import React from 'react';
import Select from 'react-select';

export default function SearchableSelect({ onChange, selectedValue, children, style, className }) {
  // Map children to options
  const options = [];
  let currentGroup = { label: '', options: [] };

  React.Children.forEach(children, (child) => {
    if (child.type === 'option' && child.props.value) {
      currentGroup.options.push({
        value: child.props.value,
        label: child.props.children,
      });
    } else if (child.type === 'optgroup') {
      if (currentGroup.label) {
        options.push(currentGroup);
      }
      currentGroup = {
        label: child.props.label,
        options: React.Children.toArray(child.props.children).map((option) => ({
          value: option.props.value,
          label: option.props.children,
        })),
      };
    }
  });

  if (currentGroup.label) {
    options.push(currentGroup);
  }

  // Find the selected option
  const selectedOption = options
    .flatMap(group => group.options)
    .find(option => option.value === selectedValue);

  // Custom styles for react-select
  const customStyles = {
    container: (provided) => ({
      ...provided,
      ...style,
      display: 'inline-block',
      minWidth: '200px',
    }),
    control: (provided) => ({
      ...provided,
      minWidth: '200px',
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: '290px',
      zIndex: 9999, // Ensure dropdown menu appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'grey' : provided.backgroundColor,
      color: state.isFocused ? 'white' : provided.color,
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  return (
    <Select
      value={selectedOption}
      isSearchable={true}
   
      onChange={(selectedOption) => onChange({ target: { value: selectedOption.value } })}
      options={options}
      styles={customStyles}
      className={className}
      menuPortalTarget={document.body} // Render the menu in the body to avoid overflow issues
    />
  );
}
