import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress }) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%', // Ensures the container takes up full width
  };

  const progressBarWrapperStyle = {
    width: '100%',
    backgroundColor: '#e0e0df',
    borderRadius: '10px',
    overflow: 'hidden', // Ensures rounded corners for the filled progress bar
  };

  const progressBarStyle = {
    width: `${progress}%`,
    height: '10px',
    backgroundColor: '#7F56D9',
    transition: 'width 0.3s ease',
  };

  const progressTextStyle = {
    marginLeft: '10px',
    fontSize: '14px',
    color: '#333',
  };

  return (
    <div style={containerStyle}>
      <div style={progressBarWrapperStyle}>
        <div style={progressBarStyle}></div>
      </div>
      <span style={progressTextStyle}>{progress}%</span>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ProgressBar;
