import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../../assets/css/App.scss';
import arrowLeft from '../../../assets/icons/arrow-left.svg';
import checkIconSuccess from '../../../assets/icons/check-success.svg';
import checkIcon from '../../../assets/icons/check.svg';
import lockIocn from '../../../assets/icons/lock.svg';
import backgroundImage from '../../../assets/images/bg2.png';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Label from '../../../components/Typorgraphy/Label';
import Paragraph from '../../../components/Typorgraphy/Paragraph';
import Title from '../../../components/Typorgraphy/Title';

const ForgotPasswordPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const paramValueOtp = queryParams.get('otp');
                const response = await axios.get(`http://localhost/auth/user/${paramValueOtp}`);
                setEmail(response.data.data.email);
              } catch (error) {
                console.error('Error fetching email:', error);
            }
        };
        fetchEmail();
    }, [queryParams]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
        try {
            const paramValueOtp = queryParams.get('otp');
            const response = await axios.post('http://localhost/auth/user/reset-password', {
                email: email,
                otp: paramValueOtp,
                newPassword: password,
            });
            navigate('/password-reset');
        } catch (error) {
            console.error('Error resetting password:', error);
        }
    };

    const getCharacter = () => {
        const isPasswordValid = password.length >= 8 && /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return isPasswordValid ? checkIconSuccess : checkIcon;
    };

    const getLength = () => {
        const isPasswordValid = password.length >= 8;
        return isPasswordValid ? checkIconSuccess : checkIcon;
    };

    return (
        <div style={styles.background}>
            <div style={styles.container}>
                <div style={styles.centered}>
                    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh', background: 'transparent', marginTop: '100px' }}>
                        <form onSubmit={handleSubmit} style={{ width: '350px' }}>
                            <div className="text-center mb-3">
                                <img src={lockIocn} alt="Logo" className='icon-border' />
                            </div>
                            <Title style={{ textAlign: 'center' }}>Set new Password</Title>
                            <Paragraph style={{ textAlign: 'center' }}>Your new password must be different to previously used passwords.</Paragraph>
                            <div style={{ marginBottom: '20px', marginTop: '35px' }}>
                                <Label>Password</Label>
                                <TextField isRequired name="password" type='password' placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            <div style={{ marginBottom: '20px', marginTop: '35px' }}>
                                <Label>Confirm Password</Label>
                                <TextField isRequired name="cpassword" type='password' placeholder="Re-enter new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                            </div>
                            <Paragraph style={{ textAlign: 'left', fontSize: '14px' }}>
                                <img src={getLength()} alt="arrow" style={{ marginRight: '5px', width: '18px' }} /> Must be at least 8 characters
                            </Paragraph>
                            <Paragraph style={{ textAlign: 'left', marginTop: '-15px', fontSize: '14px' }}>
                                <img src={getCharacter()} alt="arrow" style={{ marginRight: '10px', width: '18px' }} /> Must contain one special character
                            </Paragraph>
                            <Button type='submit' style={{ width: '100%', marginBottom: '30px' }} label={"Reset password"} />
                            <div className="d-flex align-items-center" style={{ textAlign: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                                <Label style={{ cursor: 'pointer', fontWeight: '600' }} onClick={() => navigate('/login')}>
                                    <img src={arrowLeft} alt="arrow" style={{ marginRight: '5px' }} />
                                    Back to log in
                                </Label>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center top',
        height: '100vh',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    centered: {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
    },
};

export default ForgotPasswordPage;
