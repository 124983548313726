import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {Row,Col} from 'react-bootstrap'
import Button from '../Button'


const handleSave = (props) => {
  const { onSave, onHide, privateKey, createLithicIntegration } = props
  onSave()
  onHide()
  // console.log(privateKey, createLithicIntegration)
  // createLithicIntegration(privateKey)
}

function ModalComp(props) {
  return (
    <Modal
      {...props}
      size="md"
    
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop='static'
    >
      <Modal.Header closeButton style={{border:'none'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
         {props.image ? <img src={props.image} alt='ico' style={{width:'48px',borderRadius:'10px'}} /> : '' } 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
      <div style={{border:'none',margin:'20px'}}>
        
        <Row>
        <Col lg={6}><Button onClick={() => props.onHide} label={"Cancel"} style={{width:'100%',textAlign:'center'}} variant='default'> </Button></Col>
            <Col lg={6}><Button type='submit' onClick={() => handleSave(props)} label={props.customText || "Save"} style={{width:'100%'}}> </Button></Col>
        </Row>
        </div>
    </Modal>
  );
}

export default ModalComp;
