import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoaderSpinner from '../components/Spinner';
const PrivateRoutes = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = Cookies.get('token');
                if (!token) {
                   setAuthenticated(false);
                   setLoading(false);
                    return;
                }
                const randomKey = Math.random().toString(36).substr(2, 9);
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ping?randomKey=${randomKey}`, {
                    method: 'GET',
                    headers: {
                       'Authorization': `Bearer ${token} `
                    }
                });
                if (response.ok) {
                    setAuthenticated(true);
                  
                } else {
                    setAuthenticated(false);
                    Cookies.remove('token')
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                setAuthenticated(false);
                Cookies.remove('token')
            } finally {
                setLoading(false);
            }
        };

        checkAuth();
    }, []);

    if (loading) {
        return  <LoaderSpinner show={loading} />;
    }

    return (
        authenticated ? <Outlet /> : <Navigate to="/login" />
    );
};

export default PrivateRoutes;
