// ReusableTable.js
import React from 'react';
import DataTable from 'react-data-table-component'; 
import './style.css'; // Import your custom CSS file

const ReusableTable = ({noDataComponent,expandedRows,onRowClicked, columns, data,selectableRows,customWidths,progressPending,conditionalRowStyles,expandableRowsComponent }) => {

  if (customWidths) {
    // Apply custom column widths if provided
    columns.forEach((column, index) => {
      const width = customWidths[column.name];
      if (width) {
        columns[index].width = width;
      }
    });
  }
    
  return (
    <div className="custom-table">
      <DataTable
      noDataComponent={noDataComponent}
       expandableRows={true}
       expandableRowExpanded={row => expandedRows.includes(row.id)}
       expandableRowsHideExpander={true}
         expandableRowsComponent={expandableRowsComponent}
       expandOnRowClicked={true}
       highlightOnHover={true}
       onRowClicked={onRowClicked}
      progressPending={progressPending}
      selectableRows={selectableRows}
    
      conditionalRowStyles={conditionalRowStyles}
        columns={columns}
        data={data}
        customStyles={{
          table: {
            borderRadius: '10px',
            border: '1px solid green',
          },
          header: {
            background: 'blue',
          },
          rows: {
            style: {
             
            },
          },
        }}
      />
    </div>
  );
};

export default ReusableTable;
