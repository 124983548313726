import React from "react";
import { Container } from "react-bootstrap";
import logoImage from '../../../assets/images/logoHeader.svg';
import Title from "../../../components/Typorgraphy/Title";
import Paragraph from "../../../components/Typorgraphy/Paragraph";


const Header = ({title="",description=""}) => {
    return (
        <Container>
          <div style={{borderBottomRightRadius:'12px',borderBottomLeftRadius:'12px', backgroundColor:'white', border :'1px solid #EAECF0', padding :'10px',width:'80%',marginLeft:'auto',marginRight:'auto',paddingTop:'15px',paddingLeft:'20px' }}>
            <img src={logoImage} alt='logo' style={{marginTop:'10px',marginBottom:'15px'}} />

            <Title>{title}</Title>
            <Paragraph>{description}</Paragraph>
          </div>
        </Container>
    );
}

export default Header;